import React from 'react';
import { loadMessages } from '@progress/kendo-react-intl';
import { Helmet } from 'react-helmet-async';
import envVars from '../../resources/envVars';
require(`../../custom/${process.env.REACT_APP_CUSTOM_FOLDER_NAME}/assets/scss/global.scss`);

export interface CustomizationProviderProps {
  children: React.ReactNode;
}

const CustomizationProvider = ({ children }: CustomizationProviderProps) => {
  const customFolderName = envVars.CUSTOM_FOLDER_NAME;
  const customerName = envVars.CUSTOMER_NAME;
  const favicon = require(`../../custom/${customFolderName}/assets/media/favicon.ico`);
  const appleTouchIcon = require(`../../custom/${customFolderName}/assets/media/apple-touch-icon.png`);
  const touchIcon = require(`../../custom/${customFolderName}/assets/media/touch-icon.png`);
  const enCustomMessages =
    require(`../../custom/${customFolderName}/messages/custom.en-150`).enCustomMessages;
  const nbCustomMessages =
    require(`../../custom/${customFolderName}/messages/custom.nb`).nbCustomMessages;
  const fiCustomMessages =
    require(`../../custom/${customFolderName}/messages/custom.fi`).fiCustomMessages;
  const svCustomMessages =
    require(`../../custom/${customFolderName}/messages/custom.sv`).svCustomMessages;

  // Load messages for all languages
  loadMessages(enCustomMessages, 'en-150');
  loadMessages(nbCustomMessages, 'nb');
  loadMessages(fiCustomMessages, 'fi');
  loadMessages(svCustomMessages, 'sv');

  return (
    <>
      <Helmet
        defaultTitle={customerName ? customerName : ''}
        titleTemplate={`%s | ${customerName}`}
      >
        <link rel='shortcut icon' href={favicon} />
        <link rel='apple-touch-icon' href={appleTouchIcon} />
        <link rel='icon' href={touchIcon} />
      </Helmet>

      {children}
    </>
  );
};

export default CustomizationProvider;
