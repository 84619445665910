import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from '@progress/kendo-react-form';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Input, TextArea } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
import envVars from '../../../resources/envVars';
import HelmetTitle from '../../common/HelmetTitle';
import { getAxiosConfig } from '../../../helpers/utils';
import authService from '../../api-authorization/AuthorizeService';
import {
  fetchTopics,
  resetConversationList,
  selectTopics,
  selectTopicsFetched,
} from './conversationsSlice';
import { resetAlerts, setAlerts } from '../../../app/slices/alertSlice';
import Layout from '../../layout/Layout';
import { useTranslation } from '../../../hooks/useTranslation';

const NewMessage = () => {
  const dispatch = useAppDispatch();

  const ns = 'construo.inbox';
  const translations = {
    backToInbox: useTranslation(`${ns}.backToInbox`),
    createNewMessage: useTranslation(`${ns}.createNewMessage`),
    messageTopic: useTranslation(`${ns}.messageTopic`),
    messageSubject: useTranslation(`${ns}.messageSubject`),
    messageText: useTranslation(`${ns}.messageText`),
    send: useTranslation(`${ns}.send`),
  };

  const baseUrl = envVars.API_BASE_URI;
  const navigate = useNavigate();

  const handleNewMessageSubmit = async (submit: any) => {
    const subject = submit.messageSubject;
    const message = submit.messageText;
    const topicGuid = submit.messageTopic.topicGuid;

    const url = `${baseUrl}/user/conversations`;
    const data = JSON.stringify({
      conversation: {
        conversationTypeId: 2,
        friendlyName: subject,
        topicGuid: topicGuid,
      },
      activity: {
        content: message,
        activityTypeId: 2,
        contentTypeId: 1,
      },
    });
    const token = await authService.getAccessToken();
    const config = getAxiosConfig(token, 'json');
    try {
      const response = await axios.post(url, data, config);
      dispatch(resetConversationList());
      navigate('/inbox');
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Show error message after Complete Action Event fails
        const errorResponse = error.message;
        dispatch(setAlerts({ message: errorResponse, type: 'error' }));
        // And scroll to top so that alert is visible
        window.scrollTo(0, 0);
      } else {
        dispatch(
          setAlerts({
            message:
              'Something went wrong. Try again and if error persist contact administrator.',
            type: 'error',
          }),
        );
        // And scroll to top so that alert is visible
        window.scrollTo(0, 0);
      }
    }
  };

  const topics = useAppSelector(selectTopics);
  const topicsFetched = useAppSelector(selectTopicsFetched);

  useEffect(() => {
    if (topicsFetched === null) {
      const url = `${baseUrl}/topics`;
      const dataObject = {
        url,
      };
      dispatch(fetchTopics(dataObject));
    }
    return () => {
      dispatch(resetAlerts());
    };
  }, [dispatch, baseUrl, topicsFetched]);

  return (
    <>
      <HelmetTitle title={translations.createNewMessage} />
      <Layout>
        <section className='main-section new-message'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <header>
                  <NavLink className='back-link' to='/inbox'>
                    {translations.backToInbox}
                  </NavLink>
                  <h1>{translations.createNewMessage}</h1>
                </header>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <Form
                  initialValues={{}}
                  onSubmit={handleNewMessageSubmit}
                  render={(formRenderProps: FormRenderProps) => (
                    <FormElement>
                      <Field
                        name={'messageTopic'}
                        data={topics}
                        textField='name'
                        dataItemKey='topicGuid'
                        value='Value'
                        component={DropDownList}
                        label={translations.messageTopic}
                      />
                      <Field
                        name={'messageSubject'}
                        component={Input}
                        label={translations.messageSubject}
                      />
                      <Field
                        name={'messageText'}
                        component={TextArea}
                        label={translations.messageText}
                        placeholder={translations.messageText}
                      />
                      <Button
                        themeColor='primary'
                        onClick={formRenderProps.onSubmit}
                      >
                        {translations.send}
                      </Button>
                    </FormElement>
                  )}
                />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default NewMessage;
