import React from 'react';
import { useTranslation } from '../../hooks/useTranslation';

const FormSealed = () => {
  const ns = 'construo.forms';
  const translations = {
    formSealedHeading: useTranslation(`${ns}.formSealedHeading`),
    formSealedText: useTranslation(`${ns}.formSealedText`),
  };
  return (
    <>
      <h1>{translations.formSealedHeading}</h1>
      <p>{translations.formSealedText}</p>
    </>
  );
};

export default FormSealed;
