import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import axios from 'axios';
import { getAxiosConfig } from '../../helpers/utils';
import authService from '../api-authorization/AuthorizeService';

// Gateway API version - fetching Upload Requests from Gateway API
export const fetchUploadRequests = createAsyncThunk(
  'fetchUploadRequests',
  async (dataObject: any) => {
    const token = await authService.getAccessToken();
    const baseUrl = dataObject.baseUrl;
    const url = baseUrl + '/user/document-requests/';
    const config = getAxiosConfig(token, 'json');
    const response = await axios.get(url, config);
    const data = JSON.parse(response.data.results);
    return data;
  }
);

interface UploadRequestsState {
  data: object[];
  error: string | null;
  uploadsFetched: boolean | null;
}

const initialState: UploadRequestsState = {
  data: [],
  error: null,
  uploadsFetched: null,
};

export const uploadsSlice = createSlice({
  name: 'uploads',
  initialState,
  reducers: {
    setUploadsFetched: (state, action) => {
      state.uploadsFetched = action.payload;
    },
    clearUploadsError: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUploadRequests.pending, (state, action) => {
        // console.log('UPLOADS LOADING');
        state.uploadsFetched = false;
      })
      .addCase(fetchUploadRequests.fulfilled, (state, action) => {
        // console.log('UPLOADS FULFILLED');
        state.data = action.payload;
        state.uploadsFetched = true;
      })
      .addCase(fetchUploadRequests.rejected, (state, action) => {
        // console.log('UPLOADS REJECTED', action);
        state.uploadsFetched = true;
        state.error = !!action.error.message
          ? `Fetch Document Upload Requests: ${action.error.message}`
          : null;
      });
  },
});

export const { setUploadsFetched, clearUploadsError } = uploadsSlice.actions;

export const selectUploads = (state: RootState) => state.uploads.data;
export const selectUploadError = (state: RootState) => state.uploads.error;
export const selectUploadsFetched = (state: RootState) =>
  state.uploads.uploadsFetched;

export default uploadsSlice.reducer;
