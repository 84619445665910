export const nnFormioMessages = {
  unsavedRowsError: '[NN] Please save all rows before proceeding.',
  invalidRowsError: '[NN] Please correct invalid rows before proceeding.',
  invalidRowError: '[NN] Invalid row. Please correct it or delete.',
  alertMessageWithLabel: '[NN] {{label}}: {{message}}',
  alertMessage: '[NN] {{message}}',
  complete: '[NN] Submission Complete',
  error: '[NN] Please fix the following errors before submitting.',
  errorListHotkey: '[NN] Press Ctrl + Alt + X to go back to the error list.',
  errorsListNavigationMessage:
    '[NN] Click to navigate to the field with following error.',
  submitError:
    '[NN] Please check the form and correct all errors before submitting.',
  required: '[NN] {{field}} is required',
  unique: '[NN] {{field}} must be unique',
  array: '[NN] {{field}} must be an array',
  array_nonempty: '[NN] {{field}} must be a non-empty array',
  nonarray: '[NN] {{field}} must not be an array',
  select: '[NN] {{field}} contains an invalid selection',
  pattern: '[NN] {{field}} does not match the pattern {{pattern}}',
  minLength: '[NN] {{field}} must have at least {{length}} characters.',
  maxLength: '[NN] {{field}} must have no more than {{length}} characters.',
  minWords: '[NN] {{field}} must have at least {{length}} words.',
  maxWords: '[NN] {{field}} must have no more than {{length}} words.',
  min: '[NN] {{field}} cannot be less than {{min}}.',
  max: '[NN] {{field}} cannot be greater than {{max}}.',
  maxDate: '[NN] {{field}} should not contain date after {{- maxDate}}',
  minDate: '[NN] {{field}} should not contain date before {{- minDate}}',
  maxYear: '[NN] {{field}} should not contain year greater than {{maxYear}}',
  minYear: '[NN] {{field}} should not contain year less than {{minYear}}',
  invalid_email: '[NN] {{field}} must be a valid email.',
  invalid_url: '[NN] {{field}} must be a valid url.',
  invalid_regex: '[NN] {{field}} does not match the pattern {{regex}}.',
  invalid_date: '[NN] {{field}} is not a valid date.',
  invalid_day: '[NN] {{field}} is not a valid day.',
  mask: '[NN] {{field}} does not match the mask.',
  valueIsNotAvailable: '[NN] {{ field }} is an invalid value.',
  stripe: '[NN] {{stripe}}',
  month: '[NN] Month',
  Month: '[NN] Month',
  day: '[NN] Day',
  Day: '[NN] Day',
  year: '[NN] Year',
  Year: '[NN] Year',
  january: '[NN] January',
  January: '[NN] January',
  february: '[NN] February',
  February: '[NN] February',
  march: '[NN] March',
  March: '[NN] March',
  april: '[NN] April',
  April: '[NN] April',
  may: '[NN] May',
  May: '[NN] May',
  june: '[NN] June',
  June: '[NN] June',
  july: '[NN] July',
  July: '[NN] July',
  august: '[NN] August',
  August: '[NN] August',
  september: '[NN] September',
  September: '[NN] September',
  october: '[NN] October',
  October: '[NN] October',
  november: '[NN] November',
  November: '[NN] November',
  december: '[NN] December',
  December: '[NN] December',
  next: '[NN] Next',
  previous: '[NN] Previous',
  cancel: '[NN] Cancel',
  submit: '[NN] Submit Form',
  submitForRequiredAuthentication: '[NN] Identify and submit',
  confirmCancel: '[NN] Are you sure you want to cancel?',
  saveDraftInstanceError:
    '[NN] Cannot save draft because there is no formio instance.',
  saveDraftAuthError: '[NN] Cannot save draft unless a user is authenticated.',
  restoreDraftInstanceError:
    '[NN] Cannot restore draft because there is no formio instance.',
  time: '[NN] Invalid time',
  'Type to search': '[NN] Type to search',
  'No results found': '[NN] No results found',
};
