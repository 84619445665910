import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { selectUser, UserState } from '../api-authorization/userSlice';
import { GlobalMenuLabelTypes } from './GlobalMenu';
import {
  removeSelectedRepresentation,
  SelectedAccountProps,
  selectHasRepresentations,
} from '../api-authorization/representationSlice';

export interface AccountMenuProps {
  onHideMenu: () => void;
  userProps: UserState;
  labels: GlobalMenuLabelTypes;
  hasRepresentations?: boolean;
  selectedAccount?: SelectedAccountProps | null;
}

const AccountMenu = ({
  onHideMenu,
  userProps,
  labels,
  hasRepresentations: hasRepresentationsProp,
  selectedAccount,
}: AccountMenuProps) => {
  const dispatch = useAppDispatch();
  const userStateSelect = useAppSelector(selectUser);

  const userState = userProps === undefined ? userStateSelect : userProps;

  const hasRepresentationsSelect = useAppSelector(selectHasRepresentations);

  const hasRepresentations =
    hasRepresentationsProp === undefined
      ? hasRepresentationsSelect
      : hasRepresentationsProp;
  const selectedAccountGuid = selectedAccount?.contactGuid;
  const isLoggedInUser = userState.contactGuid === selectedAccountGuid;

  return (
    <>
      {userState.isLoggedIn && (
        <div className='user-info'>
          {hasRepresentations && !isLoggedInUser && (
            <div className='user-name'>{labels.accountNameTagline}</div>
          )}

          {hasRepresentations ? (
            <h5>{selectedAccount?.name}</h5>
          ) : (
            <h5>{userState.username}</h5>
          )}

          {hasRepresentations && (
            <NavLink
              className='switch-account'
              to={'/'}
              onClick={() => {
                onHideMenu();
                dispatch(removeSelectedRepresentation());
              }}
            >
              {labels.switchAccount}
            </NavLink>
          )}
        </div>
      )}
    </>
  );
};

export default AccountMenu;
