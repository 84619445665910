import React, { useState } from 'react';
import { Holding } from '../../portfolios/types';
import {
  ExpansionPanel,
  ExpansionPanelActionEvent,
  ExpansionPanelContent,
} from '@progress/kendo-react-layout';
import { Link } from 'react-router-dom';
import PortfoliosLastUpdatedInfo from '../../../common/PortfoliosLastUpdatedInfo';
import { useInternationalization } from '@progress/kendo-react-intl';
import { Reveal } from '@progress/kendo-react-animation';
import PortfoliosAccordionSkeleton from './PortfoliosAccordionSkeleton';
import { useConfig } from '../../../../hooks/useConfig';
import { useTranslation } from '../../../../hooks/useTranslation';

export interface PortfoliosAccordionProps {
  isLoading: boolean;
  portfolios: Holding[] | undefined;
  expandedPortfolioId?: string;
  portfoliosLastUpdatedInfo?: JSX.Element;
}

const PortfoliosAccordion = ({
  isLoading,
  portfolios,
  expandedPortfolioId,
  portfoliosLastUpdatedInfo,
}: PortfoliosAccordionProps) => {
  const [expanded, setExpanded] = useState(expandedPortfolioId);
  const intl = useInternationalization();
  const config = useConfig();

  const ns = 'construo.homepage.portfoliosSection';
  const translations = {
    periodEndMarketValue: useTranslation(`${ns}.periodEndMarketValue`),
    mtdTotalGain: useTranslation(`${ns}.mtdTotalGain`),
    ytdTotalGain: useTranslation(`${ns}.ytdTotalGain`),
    seePortfolio: useTranslation(`${ns}.seePortfolio`),
  };

  const LastUpdatedInfo = ({ portfolio }: { portfolio: Holding }) => {
    return portfoliosLastUpdatedInfo === undefined ? (
      <PortfoliosLastUpdatedInfo portfolio={portfolio} />
    ) : (
      portfoliosLastUpdatedInfo
    );
  };

  const SinceInceptionTranslation = ({
    formattedDate,
  }: {
    formattedDate: string;
  }) => {
    return (
      <span>
        {useTranslation(`${ns}.sinceInceptionTotalGain`, [formattedDate])}
      </span>
    );
  };

  const Accordion = () => {
    return (
      <>
        {portfolios?.map((item: any) => {
          const marketValue = intl.formatNumber(
            item.periodEndMarketValue,
            'n0',
          );
          const month = intl.formatNumber(item.mtdTotalGain, 'n0');
          const year = intl.formatNumber(item.ytdTotalGain, 'n0');
          const beginning = intl.formatNumber(
            item.sinceInceptionTotalGain,
            'n0',
          );
          const beginningDate = intl.formatDate(
            new Date(item.sinceInceptionDate),
            {
              date: 'short',
            },
          );
          return (
            <ExpansionPanel
              title={item.title}
              subtitle={`${marketValue} ${config.currencyAbbreviation}`}
              expanded={expanded === item.portfolioId}
              tabIndex={0}
              key={item.portfolioId}
              className='portfolios-accordion-item'
              onAction={(event: ExpansionPanelActionEvent) => {
                setExpanded(event.expanded ? '' : item.portfolioId);
              }}
            >
              <Reveal>
                {expanded === item.portfolioId && (
                  <ExpansionPanelContent>
                    <div className='portfolio-details'>
                      <div className='market-value'>
                        <dl>
                          <dt>{translations.periodEndMarketValue}</dt>
                          <dd>
                            {marketValue} {config.currencyAbbreviation}
                          </dd>
                        </dl>
                      </div>
                      <div className='total-gain'>
                        <ul className='list-group list-group-flush'>
                          <li className='list-group-item'>
                            <span>{translations.mtdTotalGain}</span>
                            <span>
                              {month} {config.currencyAbbreviation}
                            </span>
                          </li>
                          <li className='list-group-item'>
                            <span>{translations.ytdTotalGain}</span>
                            <span>
                              {year} {config.currencyAbbreviation}
                            </span>
                          </li>
                          <li className='list-group-item'>
                            <SinceInceptionTranslation
                              formattedDate={beginningDate}
                            />
                            <span>
                              {beginning} {config.currencyAbbreviation}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <p className='portfolio-link'>
                      <Link
                        to={`portfolios/${item.portfolioId}`}
                        className='btn btn-outline-primary'
                      >
                        {translations.seePortfolio}
                      </Link>
                    </p>
                    <LastUpdatedInfo portfolio={item} />
                  </ExpansionPanelContent>
                )}
              </Reveal>
            </ExpansionPanel>
          );
        })}
      </>
    );
  };

  return (
    <div className='portfolios-accordion'>
      {isLoading ? <PortfoliosAccordionSkeleton /> : <Accordion />}
    </div>
  );
};

export default PortfoliosAccordion;
