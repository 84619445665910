import React, { useEffect } from 'react';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { Button } from '@progress/kendo-react-buttons';
import { selectPreviewProps } from '../documents/documentsSlice';
import Layout from '../../layout/Layout';
import { useTranslation } from '../../../hooks/useTranslation';

interface DocumentPreviewProps {
  guid: string;
}

const isSafari = () => {
  const is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return is_safari;
};

const DocumentPreview = ({ guid }: DocumentPreviewProps) => {
  const previewProps = useAppSelector(selectPreviewProps);
  const contentType = previewProps.docType;
  const documentSrc = previewProps.url;
  const documentName = `document_${guid}`;
  const docLoading = false;

  const isSafariBrowser = isSafari();

  const translations = {
    download: useTranslation('construo.global.download'),
    previewNotSupported: useTranslation('construo.global.previewNotSupported'),
  };

  useEffect(() => {
    if (!docLoading && contentType !== '') {
      const object: HTMLElement | null = document.getElementById('object');
      const img: HTMLElement | null = document.getElementById('image');

      if (contentType === 'application/pdf') {
        object?.setAttribute('data', documentSrc);
      } else {
        img?.setAttribute('src', documentSrc);
      }
    }
  }, [documentSrc, contentType, docLoading]);

  const downloadDocument = () => {
    const link = document.createElement('a');
    link.href = documentSrc;
    link.download = `document_${guid}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Layout>
        <div className='main-section document-preview'>
          <div className='container'>
            {contentType === 'application/pdf' ? (
              <>
                <object
                  id='object'
                  title=''
                  type={isSafariBrowser ? '' : 'application/pdf'}
                  style={{
                    height: '100vh',
                    width: '100%',
                  }}
                >
                  <div id='append-container'>
                    {translations.previewNotSupported}
                    <br />
                  </div>
                </object>
              </>
            ) : (
              <>
                <img
                  id='image'
                  alt={documentName}
                  title={documentName}
                  style={{
                    height: '100vh',
                    maxHeight: '100%',
                    maxWidth: '100%',
                  }}
                />
              </>
            )}
            <div className='download-button'>
              <Button themeColor='primary' onClick={downloadDocument}>
                <i className='fal fa-download' />
              </Button>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default DocumentPreview;
