import { Skeleton } from '@progress/kendo-react-indicators';
import React from 'react';
import envVars from '../../../../resources/envVars';
import { getPublicationCategories } from '../../../../helpers/utils';
import { categoryProps } from './PublicationsSectionProvider';

const PublicationsSectionSkeleton = () => {
  const categories: categoryProps[] = getPublicationCategories();
  const numberOfCategories: number = categories?.length;
  const numberOfPublications: number = envVars.RECENT_PUBLICATIONS_NO;

  let publicationList = [];
  for (let i = 0; i < numberOfPublications; i++) {
    publicationList.push(
      <li key={i} className='list-group-item'>
        <a className='h6 link' href='/'>
          <span className='item-text'>
            <Skeleton shape={'text'} style={{ height: 35, width: 200 }} />
            <Skeleton shape={'rectangle'} style={{ height: 15, width: 100 }} />
          </span>

          <div className='icon'>
            <Skeleton shape='rectangle' style={{ height: 40, width: 30 }} />
          </div>
        </a>
      </li>
    );
  }

  let publicationCategoryList = [];
  for (let i = 0; i < numberOfCategories; i++) {
    publicationCategoryList.push(
      <div className='col-md-6' key={i}>
        <Skeleton shape={'text'} style={{ height: 50, width: '30%' }} />
        <ul className='list-group list-group-flush'>{publicationList}</ul>
      </div>
    );
  }

  return (
    <>
      <div className='row'>{publicationCategoryList}</div>
      <div className='row'>
        <p className='publications-link' style={{ marginBottom: 0 }}>
          <span style={{ display: 'inline-block', width: '25%' }}>
            <Skeleton
              shape={'text'}
              style={{ height: 60, width: '100%', borderRadius: '2px' }}
            />
          </span>
        </p>
      </div>
    </>
  );
};

export default PublicationsSectionSkeleton;
