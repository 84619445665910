import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './hooks/reduxHooks';
import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { jwtDecode } from 'jwt-decode';

import authService from './components/api-authorization/AuthorizeService';
import { AuthorizeRoute } from './components/api-authorization/AuthorizeRoute';
import {
  ApplicationName,
  ApplicationPaths,
  LoginActions,
  LogoutActions,
} from './components/api-authorization/ApiAuthorizationConstants';

import Home from './components/pages/home/Home';

import Documents from './components/pages/documents/Documents';
import Document from './components/pages/document/Document';

import {
  selectUser,
  setUser,
  removeUser,
  populateUser,
} from './components/api-authorization/userSlice';
import { selectLanguage } from './components/common/languageSwitcherSlice';
import OverallLoader from './components/common/OverallLoader';
import { selectLoading, Loading } from './app/slices/loadingSlice';
import { setDigitalIdentity } from './components/signatures/signaturesSlice';

import IdleTimerContainer from './components/common/IdleTimerContainer';

import {
  IntlProvider,
  load,
  LocalizationProvider,
} from '@progress/kendo-react-intl';

import PublicForm from './components/pages/public-forms/PublicForm';
import SubmissionForm from './components/pages/submission-forms/SubmissionForm';
import UserSubmissionForm from './components/submissions/UserSubmissionForm';
import WorkflowProvider from './components/workflows/WorkflowProvider';
import TaskDetailsProvider from './components/workflows/taskDetails/TaskDetailsProvider';
import Conversation from './components/pages/inbox/Conversation';
import NewMessage from './components/pages/inbox/NewMessage';
import ReportPage from './components/pages/ReportPage';
import ResourceNotFound from './components/pages/resource-not-found/ResourceNotFound';
import NotFound from './components/pages/not-found/NotFound';
import envVars from './resources/envVars';
import {
  fetchRepresentations,
  selectRepresentationRights,
  selectRepresentationsFetched,
  selectSelectedAccount,
} from './components/api-authorization/representationSlice';
import SelectAccountProvider from './components/pages/select-account/SelectAccountProvider';
import Inbox from './components/pages/inbox/Inbox';
import UploadActionDetailsProvider from './components/pages/inbox/UploadActionDetailsProvider';
import PortfoliosProvider from './components/pages/portfolios/PortfoliosProvider';

// Import locale data
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';
// EU English language
import enLocalCurrency from 'cldr-numbers-full/main/en-150/currencies.json';
import enNumbers from 'cldr-numbers-full/main/en-150/numbers.json';
import enCaGregorian from 'cldr-dates-full/main/en-150/ca-gregorian.json';
import enDateFields from 'cldr-dates-full/main/en-150/dateFields.json';
// Norwegian language
import nbLocalCurrency from 'cldr-numbers-full/main/nb/currencies.json';
import nbNumbers from 'cldr-numbers-full/main/nb/numbers.json';
import nbCaGregorian from 'cldr-dates-full/main/nb/ca-gregorian.json';
import nbDateFields from 'cldr-dates-full/main/nb/dateFields.json';
// Finnish language
import fiLocalCurrency from 'cldr-numbers-full/main/fi/currencies.json';
import fiNumbers from 'cldr-numbers-full/main/fi/numbers.json';
import fiCaGregorian from 'cldr-dates-full/main/fi/ca-gregorian.json';
import fiDateFields from 'cldr-dates-full/main/fi/dateFields.json';
// Swedish language
import svLocalCurrency from 'cldr-numbers-full/main/sv/currencies.json';
import svNumbers from 'cldr-numbers-full/main/sv/numbers.json';
import svCaGregorian from 'cldr-dates-full/main/sv/ca-gregorian.json';
import svDateFields from 'cldr-dates-full/main/sv/dateFields.json';

import LogoutAction from './components/api-authorization/LogoutAction';
import LoginAction from './components/api-authorization/LoginAction';
import { useTranslation } from './hooks/useTranslation';
import InformationPage from './components/pages/public-forms/InformationPage';

// Load locale data
load(
  likelySubtags,
  currencyData,
  weekData,
  // EU English
  enNumbers,
  enLocalCurrency,
  enCaGregorian,
  enDateFields,
  // Norwegian
  nbNumbers,
  nbLocalCurrency,
  nbCaGregorian,
  nbDateFields,
  // Finnish
  fiNumbers,
  fiLocalCurrency,
  fiCaGregorian,
  fiDateFields,
  // Swedish
  svNumbers,
  svLocalCurrency,
  svCaGregorian,
  svDateFields,
);

const App = () => {
  const loading = useAppSelector(selectLoading);
  const isLoading = loading === Loading.Show ? true : false;

  const dispatch = useAppDispatch();

  let languageId = 'en-150'; // Default Language
  languageId = useAppSelector(selectLanguage);

  const userState = useAppSelector(selectUser);
  const isPopulated = userState.isPopulated;
  const isUserLogged = userState.isLoggedIn;

  const baseUrl = envVars.API_BASE_URI;

  const representationRights = useAppSelector(selectRepresentationRights);
  const isRepresentationsFetched = useAppSelector(selectRepresentationsFetched);

  const selectedAccount = useAppSelector(selectSelectedAccount);
  const isRepresentations =
    !!representationRights && representationRights.length > 1;
  const isSelectedAccount = selectedAccount !== null;

  const isSelectAccountRequired =
    isRepresentations && !isSelectedAccount && isUserLogged;

  const isPortfoliosFeature = envVars.FEATURES?.includes('portfolios');
  const isDocumentListFeature = envVars.FEATURES?.includes('documentList');
  const isPublicationListFeature =
    envVars.FEATURES?.includes('publicationList');

  const isFormSharingFeature = envVars.FEATURES?.includes('formRequests');
  const isWorkflowsFeature = envVars.FEATURES?.includes('workflows');
  const isConversationsFeature = envVars.FEATURES?.includes('conversations');

  useEffect(() => {
    const checkDigitalIdentity = async () => {
      const accessToken = await authService.getAccessToken();
      let tokenDecoded: any;
      // Check user NIN and idp presence by decoding access token, IF user is set/logged!
      if (!!userState.isLoggedIn) {
        tokenDecoded =
          typeof accessToken === 'string' && jwtDecode(accessToken);
        dispatch(
          setDigitalIdentity({
            nin: tokenDecoded?.nin,
            idp: tokenDecoded?.idp,
          }),
        );
      }
    };
    checkDigitalIdentity();
  }, [dispatch, userState.isLoggedIn]);

  const translations = {
    settingsError: useTranslation('construo.errors.settingsError', [
      ApplicationName,
    ]),
  };

  useEffect(() => {
    async function fetchUser() {
      const userProfile = await authService.getUser();
      const user = await authService.getUserContactGuid();
      if (userProfile && userProfile.name) {
        const name = `${userProfile.given_name} ${userProfile.family_name}`;
        const userSub = userProfile.sub;
        const accessToken = user.access_token;
        const tokenDecoded: any = !!accessToken && jwtDecode(accessToken);
        const contactGuid = tokenDecoded?.contact_guid;
        dispatch(setUser({ username: name, userSub, contactGuid }));
      }
      dispatch(populateUser());
    }

    let subscriptionId = 0;

    const populateAuthenticationState = async (): Promise<void> => {
      const userProfile = await authService.getUser();
      if (userProfile && userProfile.name) {
        const name = `${userProfile.given_name} ${userProfile.family_name}`;
        dispatch(setUser({ username: name }));
      } else {
        dispatch(removeUser());
      }
      dispatch(populateUser());
    };

    subscriptionId = authService.subscribe(() => populateAuthenticationState());

    if (!isPopulated) {
      fetchUser();
    }

    return () => {
      authService.unsubscribe(subscriptionId);
    };
  }, [dispatch, isPopulated, translations.settingsError]);

  useEffect(() => {
    /**
     * The tricky behavior of useEffect hook in React 18
     * https://medium.com/geekculture/the-tricky-behavior-of-useeffect-hook-in-react-18-282ef4fb570a
     * let ignore
     */
    let ignore = false;
    if (isRepresentationsFetched === null && isUserLogged) {
      const url = `${baseUrl}/user/representationrights`;
      const dataObject = {
        url,
      };
      setTimeout(() => {
        if (!ignore) {
          dispatch(fetchRepresentations(dataObject));
        }
      }, 0);
    }
    return () => {
      ignore = true;
    };
  }, [dispatch, isUserLogged, baseUrl, isRepresentationsFetched]);

  // RENDERING PART
  if (!isPopulated || (!isRepresentationsFetched && isUserLogged)) {
    return <OverallLoader message={false} />;
  } else {
    return (
      <>
        <Helmet>
          <html lang={languageId} />
        </Helmet>

        <LocalizationProvider language={languageId}>
          <IntlProvider locale={languageId}>
            {isUserLogged && <IdleTimerContainer />}

            <Routes>
              {isSelectAccountRequired ? (
                <Route
                  path='/'
                  element={
                    <AuthorizeRoute path='/'>
                      <SelectAccountProvider />
                    </AuthorizeRoute>
                  }
                />
              ) : (
                <Route path='/' element={<Home />} />
              )}
              {isPortfoliosFeature && (
                <Route
                  path='portfolios'
                  element={
                    <AuthorizeRoute>
                      <PortfoliosProvider />
                    </AuthorizeRoute>
                  }
                >
                  <Route path=':id' element={<PortfoliosProvider />} />
                </Route>
              )}
              {isDocumentListFeature && (
                <Route
                  path='documents'
                  element={
                    <AuthorizeRoute>
                      <Documents />
                    </AuthorizeRoute>
                  }
                />
              )}
              {isPublicationListFeature && (
                <Route
                  path='publications'
                  element={
                    <AuthorizeRoute>
                      <Documents isPublicationsOnly={true} />
                    </AuthorizeRoute>
                  }
                />
              )}
              <Route path='report-page' element={<ReportPage />} />

              <Route path='/forms/:guid' element={<PublicForm />} />

              <Route path='/submissions/:token' element={<SubmissionForm />} />

              {isFormSharingFeature && (
                <Route
                  path='user/submissions/:guid'
                  element={<UserSubmissionForm />}
                />
              )}
              {isWorkflowsFeature && (
                <Route
                  path='user/workflows/:id'
                  element={
                    <AuthorizeRoute>
                      <WorkflowProvider />
                    </AuthorizeRoute>
                  }
                />
              )}
              {isWorkflowsFeature && (
                <Route
                  path='user/tasks/:taskId'
                  element={
                    <AuthorizeRoute>
                      <TaskDetailsProvider />
                    </AuthorizeRoute>
                  }
                />
              )}
              <Route
                path='inbox'
                element={
                  <AuthorizeRoute>
                    <Inbox />
                  </AuthorizeRoute>
                }
              />
              <Route
                path='upload-action-details'
                element={
                  <AuthorizeRoute>
                    <UploadActionDetailsProvider />
                  </AuthorizeRoute>
                }
              />
              {isConversationsFeature && (
                <Route
                  path='conversations/new-message'
                  element={
                    <AuthorizeRoute>
                      <NewMessage />
                    </AuthorizeRoute>
                  }
                />
              )}
              {isConversationsFeature && (
                <Route
                  path='conversations/:conversationId'
                  element={
                    <AuthorizeRoute>
                      <Conversation />
                    </AuthorizeRoute>
                  }
                />
              )}
              <Route
                path='documents/download/:documentGuid'
                element={
                  <AuthorizeRoute>
                    <Document />
                  </AuthorizeRoute>
                }
              />
              {/* ApiAuthorizationRoutes */}
              <Route
                path={ApplicationPaths.Login}
                element={<LoginAction name={LoginActions.Login} />}
              />
              <Route
                path={ApplicationPaths.LoginFailed}
                element={<LoginAction name={LoginActions.LoginFailed} />}
              />
              <Route
                path={ApplicationPaths.LoginCallback}
                element={<LoginAction name={LoginActions.LoginCallback} />}
              />
              <Route
                path={ApplicationPaths.FormAuthentication}
                element={<LoginAction name={LoginActions.FormAuthentication} />}
              />
              <Route
                path={ApplicationPaths.FormAuthenticationCallback}
                element={
                  <LoginAction name={LoginActions.FormAuthenticationCallback} />
                }
              />
              <Route
                path={ApplicationPaths.FormAuthenticationFailed}
                element={
                  <LoginAction name={LoginActions.FormAuthenticationFailed} />
                }
              />
              <Route
                path={ApplicationPaths.LogOut}
                element={<LogoutAction name={LogoutActions.Logout} />}
              />
              <Route
                path={ApplicationPaths.LogOutCallback}
                element={<LogoutAction name={LogoutActions.LogoutCallback} />}
              />
              <Route
                path={ApplicationPaths.LoggedOut}
                element={<LogoutAction name={LogoutActions.LoggedOut} />}
              />

              <Route
                path='forms/information-page'
                element={<InformationPage />}
              />

              <Route path='resource-not-found' element={<ResourceNotFound />} />

              <Route path='*' element={<NotFound />} />
            </Routes>

            {isLoading && <OverallLoader transparent={true} />}
          </IntlProvider>
        </LocalizationProvider>
      </>
    );
  }
};

export default App;
