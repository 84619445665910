import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useTranslation } from '../../hooks/useTranslation';

type TempResponseProps = {
  chooseTemp: () => void;
  chooseLatest: () => void;
};

const TempResponse = ({ chooseTemp, chooseLatest }: TempResponseProps) => {
  const ns = 'construo.forms';
  const translations = {
    chooseFormSubmissionTitle: useTranslation(
      `${ns}.chooseFormSubmissionTitle`,
    ),
    chooseFormSubmissionP1Text: useTranslation(
      `${ns}.chooseFormSubmissionP1Text`,
    ),
    chooseFormSubmissionP2Text: useTranslation(
      `${ns}.chooseFormSubmissionP2Text`,
    ),
    tempButtonLabel: useTranslation(`${ns}.tempButtonLabel`),
    savedButtonLabel: useTranslation(`${ns}.savedButtonLabel`),
  };

  return (
    <div className='temp-response'>
      <h1>{translations.chooseFormSubmissionTitle}</h1>
      <p>{translations.chooseFormSubmissionP1Text}</p>
      <p>{translations.chooseFormSubmissionP2Text}</p>
      <div className='action-btns'>
        <Button themeColor='secondary' onClick={chooseTemp}>
          {translations.tempButtonLabel}
        </Button>
        <Button themeColor='primary' onClick={chooseLatest}>
          {translations.savedButtonLabel}
        </Button>
      </div>
    </div>
  );
};

export default TempResponse;
