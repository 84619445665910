import React from 'react';
import { ExpansionPanel } from '@progress/kendo-react-layout';
import { Skeleton } from '@progress/kendo-react-indicators';

const PortfoliosAccordionSkeleton = () => {
  const Title = () => {
    return <Skeleton shape={'text'} style={{ width: 200 }} />;
  };

  const SubTitle = () => {
    return <Skeleton shape={'text'} style={{ width: 50 }} />;
  };

  return (
    <>
      <ExpansionPanel title={<Title />} subtitle={<SubTitle />} />
      <ExpansionPanel title={<Title />} subtitle={<SubTitle />} />
      <ExpansionPanel title={<Title />} subtitle={<SubTitle />} />
    </>
  );
};

export default PortfoliosAccordionSkeleton;
