import {
  useLocalization,
  useInternationalization,
} from '@progress/kendo-react-intl';

export const useTranslation = (key: string, placeholders?: any[]) => {
  const localizationService = useLocalization();
  const intlService = useInternationalization();
  return intlService.format(
    localizationService.toLanguageString(key, key),
    placeholders,
  );
};
