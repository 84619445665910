import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getSearchParam, navigateToReturnUrl } from '../../../helpers/utils';
import LanguageSwitcher from '../../common/LanguageSwitcher';
import { Card, CardBody, CardSubtitle } from '@progress/kendo-react-layout';
import { useTranslation } from '../../../hooks/useTranslation';

export interface HomePublicProps {
  loginPath: string;
}

const HomePublic = ({ loginPath }: HomePublicProps) => {
  const ns = 'construo.homepage';
  const translations = {
    publicHeading: useTranslation(`${ns}.publicHeading`),
    publicSubHeading: useTranslation(`${ns}.publicSubHeading`),
    login: useTranslation(`${ns}.login`),
  };

  const selectedAccount = sessionStorage.getItem('selectedAccount');
  useEffect(() => {
    if (!!selectedAccount) {
      // Removing selectedAccount from session storage on LoggedOut action
      sessionStorage.removeItem('selectedAccount');
      localStorage.removeItem('selectedAccountContactGuid');
    }
  }, [selectedAccount]);

  const eSignStatus = getSearchParam('esign');
  if (!!eSignStatus) {
    navigateToReturnUrl(`/report-page?esign=${eSignStatus}`);
  }

  return !eSignStatus ? (
    <div className='main-section public'>
      <div className='container'>
        <Card>
          <CardBody>
            <h1 className='k-card-title'>{translations.publicHeading}</h1>
            <CardSubtitle>{translations.publicSubHeading}</CardSubtitle>
            <Link className='btn btn-primary login-link' to={loginPath}>
              {translations.login}
            </Link>
          </CardBody>
        </Card>
        <LanguageSwitcher />
      </div>
    </div>
  ) : null;
};

export default HomePublic;
