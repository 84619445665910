import React, { useEffect, useState } from 'react';
import ActionRequest from '../common/ActionRequest';
import { useTranslation } from '../../hooks/useTranslation';

interface WorkflowRequestProps {
  workflow: any;
  conversations: any[];
}

const WorkflowRequest = ({ workflow, conversations }: WorkflowRequestProps) => {
  const ns = 'construo.workflows';
  const translations = {
    noWorkflowName: useTranslation(`${ns}.noWorkflowName`),
    unreadMessages: useTranslation(`${ns}.unreadMessages`),
  };
  const workflowId = workflow.id;
  const workflowName = workflow.displayName;
  const heading = !!workflowName ? workflowName : translations.noWorkflowName;
  const tasksInWorkflow = workflow.tasks;

  const [unreadMessagesNumber, setUnreadMessageNumber] = useState(0);

  useEffect(() => {
    const findNumberOfUnreadMessagesPerWorkflow = () => {
      const conversationsWithUnreadMessages = tasksInWorkflow.map(
        (task: any) => {
          const conversationsWithUnreadMessagesInTasks = conversations?.filter(
            (c: any) => c.resourceName === task.id,
          );
          return conversationsWithUnreadMessagesInTasks;
        },
      );

      const amountsOfUnreadMessagesPerConversation =
        conversationsWithUnreadMessages.map((c: any) => {
          const unreadMessagesNo = !!c && c[0]?.numberOfUnreadActivities;
          return !!unreadMessagesNo ? unreadMessagesNo : 0;
        });

      const totalUnreadMessagesPerWorkflow =
        amountsOfUnreadMessagesPerConversation.reduce(
          (partialSum: number, a: number) => partialSum + a,
          0,
        );

      return totalUnreadMessagesPerWorkflow;
    };

    const numberOfUnreadMessagesPerWorkflow =
      findNumberOfUnreadMessagesPerWorkflow();

    setUnreadMessageNumber(numberOfUnreadMessagesPerWorkflow);
  }, [conversations, tasksInWorkflow]);

  return (
    <ActionRequest
      type='workflow'
      href={`/user/workflows/${workflowId}`}
      heading={heading}
      notification={!!unreadMessagesNumber ? translations.unreadMessages : null}
    />
  );
};

export default WorkflowRequest;
