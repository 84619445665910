import React from 'react';
import { Skeleton } from '@progress/kendo-react-indicators';
import ActionExternalDocuments from './actions/ActionExternalDocuments';
import ActionExternalForm from './actions/ActionExternalForm';
import { useTranslation } from '../../../hooks/useTranslation';

export interface IWorkflowTaskAction {
  id: string;
  actionType: string;
  displayName: string;
  status: string;
  inputDataBody: string;
  outputData: string | null;
}

export interface ActionProps {
  workflowId: string | null;
  taskId: string;
  action: IWorkflowTaskAction;
}

export interface ActionsProps {
  isLoaded: boolean;
  workflowId: string | null;
  taskId: string;
  actions: IWorkflowTaskAction[];
}

const Actions = ({ isLoaded, workflowId, taskId, actions }: ActionsProps) => {
  const translations = {
    noActionAdded: useTranslation(`construo.workflows.noActionAdded`),
  };

  return (
    <>
      {isLoaded ? (
        actions?.length > 0 ? (
          actions.map(action => {
            return action.status !== 'Skipped' &&
              action.actionType === 'EXTERNAL_DOCUMENTS' ? (
              <section key={action.id} className='main-section task-action'>
                <ActionExternalDocuments
                  workflowId={workflowId}
                  taskId={taskId}
                  action={action}
                />
              </section>
            ) : action.status !== 'Skipped' &&
              action.actionType === 'EXTERNAL_FORM_IO_FORM' ? (
              <section key={action.id} className='main-section task-action'>
                <ActionExternalForm
                  workflowId={workflowId}
                  taskId={taskId}
                  action={action}
                />
              </section>
            ) : (
              ''
            );
          })
        ) : (
          <div className='main-section task-action'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-8 col-xl-9'>
                  <p className='system-text'>{translations.noActionAdded}</p>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <section className='main-section task-action'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-8 col-xl-9'>
                <h2>
                  <Skeleton shape={'text'} style={{ maxWidth: 300 }} />
                </h2>
                <p>
                  <Skeleton shape={'text'} style={{ maxWidth: 400 }} />
                  <Skeleton shape={'text'} style={{ maxWidth: 450 }} />
                  <Skeleton shape={'text'} style={{ maxWidth: 350 }} />
                </p>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Actions;
