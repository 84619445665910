import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import ConversationList from './ConversationList';
import envVars from '../../../resources/envVars';
import {
  fetchConversationList,
  resetUnreadConversations,
  selectConversationList,
  selectConversationListFetched,
} from './conversationsSlice';
import { useTranslation } from '../../../hooks/useTranslation';

const ConversationListProvider = () => {
  const dispatch = useAppDispatch();

  const ns = 'construo.inbox';
  const translations = {
    yourMessages: useTranslation(`${ns}.yourMessages`),
    noMessages: useTranslation(`${ns}.noMessages`),
  };

  const API_BASE_URI = envVars.API_BASE_URI;

  const conversationList = useAppSelector(selectConversationList);
  const conversationListFetched = useAppSelector(selectConversationListFetched);

  useEffect(() => {
    if (conversationListFetched === null) {
      const url = `${API_BASE_URI}/user/conversations/list?sortby=lastActivityDate&SortDescending=true`;
      const dataObject = {
        url,
      };
      dispatch(resetUnreadConversations()); // reset number of unread conversations so app can refresh and see any changes should be made to number inside navigation item
      dispatch(fetchConversationList(dataObject));
    }
  }, [dispatch, API_BASE_URI, conversationListFetched]);

  return (
    <ConversationList
      heading={translations.yourMessages}
      noMessages={translations.noMessages}
      isConversationListFetched={conversationListFetched}
      conversationList={conversationList}
    />
  );
};

export default ConversationListProvider;
