import { svProductMessages } from '../../../messages/product.sv';

export const svCustomMessages = {
  ...svProductMessages,
  construo: {
    ...svProductMessages.construo,
    footer: {
      ...svProductMessages.construo.footer,
      link1Label: 'Personuppgifter',
      link1URL: 'https://www.okq8.se/personuppgifter/',
      link2Label: 'OKQ8',
      link2URL: 'https://okq8.se/',
    },
    forms: {
      ...svProductMessages.construo.forms,
      formSubmittedHeading: 'Tack, din ansökan har skickats in',
      formSubmittedText:
        'Inom kort kommer du att få ett mail med mer information.',
      returnHome: 'Tillbaka till hemsidan',
      formSubmitted: 'Vi har tagit emot din ansökan.',
    },
  },
};
