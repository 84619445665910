import { useEffect, useState } from 'react';
import { useAppSelector } from './reduxHooks';
import {
  selectHasRepresentations,
  selectRepresentationRights,
  selectSelectedAccount,
} from '../components/api-authorization/representationSlice';
import { selectUser } from '../components/api-authorization/userSlice';
import { IAccount } from '../components/pages/select-account/SelectAccount';
import {
  selectSubmissions,
  selectSubmissionsFetched,
} from '../components/submissions/submissionsSlice';

import { getSubmissionsFilteredByAccountContactGuid } from '../helpers/utils';

const useAccountBasedSubmissions = () => {
  const submissionsFetched = useAppSelector(selectSubmissionsFetched);
  const accounts = useAppSelector(selectRepresentationRights);
  const selectedAccount = useAppSelector(selectSelectedAccount);
  const userState = useAppSelector(selectUser);
  const hasRepresentations = useAppSelector(selectHasRepresentations);
  const submissions = useAppSelector(selectSubmissions);

  const [accountBasedSubmissions, setAccountBasedSubmissions] = useState<
    object[]
  >([]);

  useEffect(() => {
    const selectedContactGuid = selectedAccount?.contactGuid;
    const isLoggedInUser = userState.contactGuid === selectedContactGuid;

    const allButLoggedInUserAccounts = accounts?.filter(
      (representation: IAccount) =>
        representation.contactGuid !== userState?.contactGuid,
    );
    const allButLoggedInUserAccountGUIDs: any = allButLoggedInUserAccounts?.map(
      (account: IAccount) => account.contactGuid,
    );
    if (
      submissionsFetched &&
      hasRepresentations &&
      submissions.length > 0 &&
      !!selectedContactGuid
    ) {
      // NEED TO FILTER Documents list by account contact GUID
      const filteredByAccount = getSubmissionsFilteredByAccountContactGuid(
        submissions,
        allButLoggedInUserAccountGUIDs,
        selectedContactGuid,
        isLoggedInUser,
      );
      setAccountBasedSubmissions(filteredByAccount);
    } else if (!hasRepresentations && submissions.length > 0) {
      // NO NEED TO FILTER Documents list by account contact GUID
      setAccountBasedSubmissions(submissions);
    }
  }, [
    submissions,
    hasRepresentations,
    accounts,
    selectedAccount?.contactGuid,
    submissionsFetched,
    userState.contactGuid,
  ]);

  return accountBasedSubmissions;
};

export default useAccountBasedSubmissions;
