import React from 'react';
import HelmetTitle from '../common/HelmetTitle';
import { Logout } from './Logout';
import { useTranslation } from '../../hooks/useTranslation';

interface LogoutActionProps {
  name: string;
}

const LogoutAction = ({ name }: LogoutActionProps) => {
  const ns = 'construo.global';
  const translations = {
    logout: useTranslation(`${ns}.logoutTitle`),
  };
  return (
    <>
      <HelmetTitle title={translations.logout} />
      <Logout action={name}></Logout>
    </>
  );
};

export default LogoutAction;
