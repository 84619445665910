import { useEffect, useState } from 'react';
import { useAppSelector } from './reduxHooks';
import {
  selectHasRepresentations,
  selectRepresentationRights,
  selectSelectedAccount,
} from '../components/api-authorization/representationSlice';
import { selectUser } from '../components/api-authorization/userSlice';
import {
  selectUploads,
  selectUploadsFetched,
} from '../components/upload-requests/uploadSlice';
import { getItemsFilteredByAccountContactGuid } from '../helpers/utils';

const useAccountBasedUploads = () => {
  const uploadsFetched = useAppSelector(selectUploadsFetched);
  const accounts = useAppSelector(selectRepresentationRights);
  const selectedAccount = useAppSelector(selectSelectedAccount);
  const userState = useAppSelector(selectUser);
  const hasRepresentations = useAppSelector(selectHasRepresentations);
  const uploads = useAppSelector(selectUploads);

  const [accountBasedUploads, setAccountBasedUploads] = useState<object[]>([]);

  useEffect(() => {
    const selectedContactGuid = selectedAccount?.contactGuid;
    const isLoggedInUser = userState.contactGuid === selectedContactGuid;
    if (
      uploadsFetched &&
      hasRepresentations &&
      uploads.length > 0 &&
      !!selectedContactGuid
    ) {
      // NEED TO FILTER Documents list by account contact GUID
      const filteredByAccount = getItemsFilteredByAccountContactGuid(
        uploads,
        accounts,
        selectedContactGuid,
        isLoggedInUser,
      );
      setAccountBasedUploads(filteredByAccount);
    } else if (!hasRepresentations && uploads.length > 0) {
      // NO NEED TO FILTER Documents list by account contact GUID
      setAccountBasedUploads(uploads);
    }
  }, [
    uploads,
    hasRepresentations,
    accounts,
    selectedAccount?.contactGuid,
    uploadsFetched,
    userState.contactGuid,
  ]);

  return accountBasedUploads;
};

export default useAccountBasedUploads;
