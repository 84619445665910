import React from 'react';
import envVars from '../../resources/envVars';
import { useTranslation } from '../../hooks/useTranslation';

const Logo = () => {
  const customerName = envVars.CUSTOMER_NAME;
  const customFolderName = envVars.CUSTOM_FOLDER_NAME;
  const logoAltText = useTranslation('construo.header.logoAlt', [customerName]);
  const logo = require(
    `../../custom/${customFolderName}/assets/media/logo.svg`,
  );

  return <img src={logo} alt={logoAltText} />;
};

export default Logo;
