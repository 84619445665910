import React, { useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import axios from 'axios';
import envVars from '../../../../resources/envVars';
import { resetTaskDetailsData } from '../taskDetailsSlice';
import { setAlerts } from '../../../../app/slices/alertSlice';
import { resetWorkflowData } from '../../workflowSlice';
import { getAxiosConfig } from '../../../../helpers/utils';
import authService from '../../../api-authorization/AuthorizeService';
import { selectLanguage } from '../../../common/languageSwitcherSlice';
import { Loading, setLoading } from '../../../../app/slices/loadingSlice';
import { setLoadingMessage } from '../../../../app/slices/loadingMessageSlice';
import { ActionProps } from '../Actions';
import FormioForm from '../../../common/FormioForm';
import { useTranslation } from '../../../../hooks/useTranslation';

const ActionExternalForm = ({ workflowId, taskId, action }: ActionProps) => {
  const dispatch = useAppDispatch();

  const translations = {
    reviewingSubmission: useTranslation(
      `construo.workflows.reviewingSubmission`,
    ),
    completeActionSuccess: useTranslation(
      `construo.workflows.completeActionSuccess`,
    ),
    completeActionError: useTranslation(
      `construo.workflows.completeActionError`,
    ),
    loaderSubmittingForm: useTranslation(`construo.forms.loaderSubmittingForm`),
    noFormData: useTranslation(`construo.forms.noFormData`),
    showSubmittedData: useTranslation(`construo.forms.showSubmittedData`),
    hideSubmittedData: useTranslation(`construo.forms.hideSubmittedData`),
    // saveForLater: useTranslation(`construo.forms.saveForLater`),
    // saveForLaterNote: useTranslation(`construo.forms.saveForLaterNote`),
  };

  const actionName = action.displayName;
  const actionStatus = action.status;

  const inputData = !!action.inputDataBody && JSON.parse(action.inputDataBody);
  const outputData = !!action.outputData && JSON.parse(action.outputData);

  const isReadOnly = actionStatus !== 'PendingForCustomer';

  const messageToClient = inputData?.ExternalFormData?.MessageToClient;
  const formData = inputData?.ExternalFormData?.FormData
    ? inputData?.ExternalFormData?.FormData
    : null;
  const formResponse = outputData?.formData
    ? outputData.formData
    : inputData?.ExternalFormData?.FormData
      ? inputData?.ExternalFormData?.FormData.CustomerResponse
      : {};
  const formTemplate =
    formData &&
    formData?.FormTemplateJsonValue &&
    formData?.FormTemplateJsonValue?.length
      ? JSON.parse(formData.FormTemplateJsonValue)
      : null;
  const saveSubmission: any = useRef(null);
  const currentLaguageCode = useAppSelector(selectLanguage);
  const formLanguageCode = formData?.FormLanguageCode
    ? formData.FormLanguageCode
    : currentLaguageCode;

  const splitToParagraphs = (message: string) => {
    const result = message.split(/\r?\n/);
    return result;
  };

  const messageToClientResults =
    !!messageToClient && splitToParagraphs(messageToClient); // \n is a separator (line-break)

  const [showForm, setShowForm] = useState(false);

  const sendData = async (customerResponse: any) => {
    dispatch(setLoading(Loading.Show));
    dispatch(setLoadingMessage(translations.loaderSubmittingForm));
    const token = await authService.getAccessToken();
    const GATEWAY_API_URL = envVars.GATEWAY_API_URL;
    const url = `${GATEWAY_API_URL}/api/v1/user/workflows/form`;
    const data = {
      TaskId: taskId,
      ActionId: action.id,
      WorkflowId: workflowId,
      Data: {
        formData: { data: customerResponse },
      },
    };
    const config = getAxiosConfig(token, 'json');
    await axios
      .post(url, data, config)
      .then(response => response.data)
      .then(response => {
        // Reset task details data so that relevant section of the page is reloaded/updated
        dispatch(resetTaskDetailsData());
        // Reset workflows so that task statuses are updated in redux store
        dispatch(resetWorkflowData());
        dispatch(setLoading(Loading.Hide));
        return response;
      })
      .catch(error => {
        const errorData = error.toJSON();
        // Show error message after Complete Action Event fails
        const errorMessage = errorData.message
          ? errorData.message
          : errorData.status
            ? errorData.status
            : '';
        dispatch(
          setAlerts({
            message: `${translations.completeActionError} ${errorMessage}`,
            type: 'error',
          }),
        );
        dispatch(setLoading(Loading.Hide));
        // And scroll to top so that alert is visible
        window.scrollTo(0, 0);
      });
  };

  const handleShowForm = () => {
    setShowForm(!showForm);
  };

  const onSubmit = async (submission: any) => {
    sendData(submission.data);
  };

  const onChange = (submission: any) => {
    saveSubmission.current = submission.data;
  };

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 col-xl-9'>
            <div className='action-title'>
              <h2>{actionName}</h2>

              {!!formData && !!formTemplate && isReadOnly && showForm && (
                <>
                  <div className='title-link'>
                    <span className='text-link' onClick={handleShowForm}>
                      {translations.hideSubmittedData}
                    </span>
                  </div>
                </>
              )}
            </div>

            {!!messageToClient && (
              <div className='message-to-client'>
                {Array.isArray(messageToClientResults) &&
                messageToClientResults.length > 1 ? (
                  messageToClientResults.map(
                    (messageToClientResult: string, index) => {
                      return <p key={index}>{messageToClientResult}</p>;
                    },
                  )
                ) : (
                  <p>{messageToClientResults}</p>
                )}
              </div>
            )}

            {!!formData && !!formTemplate ? (
              <div
                className={
                  isReadOnly
                    ? 'workflow-action-form no-submit'
                    : 'workflow-action-form'
                }
              >
                {/* {!isReadOnly && (
                  <div className='support-btns sticky'>
                    <div className='container'>
                      <div className='note'>
                        {translations.saveForLaterNote}
                      </div>
                      <button
                        className='btn btn-secondary btn-sm'
                        onClick={onSaveForLater}
                      >
                        {translations.saveForLater}
                      </button>
                    </div>
                  </div>
                )} */}
                {!isReadOnly || showForm ? (
                  <FormioForm
                    form={formTemplate}
                    submission={formResponse}
                    onSubmit={onSubmit}
                    onChange={onChange}
                    readOnly={isReadOnly}
                    languageCode={formLanguageCode}
                  />
                ) : (
                  <p className='m-3 text-center'>
                    <span className='text-link' onClick={handleShowForm}>
                      {translations.showSubmittedData}
                    </span>
                  </p>
                )}
              </div>
            ) : (
              <p className='mt-3 text-muted'>{translations.noFormData}</p>
            )}
            {actionStatus === 'Done' && (
              <p>
                <span className='system-text'>
                  {translations.reviewingSubmission}
                </span>
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ActionExternalForm;
