import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import axios from 'axios';
import { ListView } from '@progress/kendo-react-listview';
import { Button } from '@progress/kendo-react-buttons';
import {
  orderBy,
  filterBy,
  CompositeFilterDescriptor,
  SortDescriptor,
} from '@progress/kendo-data-query';
import { saveAs } from '@progress/kendo-file-saver';
import {
  selectBulkDownloadItems,
  selectPageNumber,
  setBulkDownloadItems,
  setCurrentSortOption,
  setPageNumber,
  setStateSort,
} from './documentsSlice';
import ListItem from '../../common/ListItem';
import envVars from '../../../resources/envVars';
import { getAxiosConfig, getOnlyPublicDocuments } from '../../../helpers/utils';
import DocumentListSkeleton from './DocumentListSkeleton';
import { setAlerts } from '../../../app/slices/alertSlice';
import { Loading, setLoading } from '../../../app/slices/loadingSlice';
import { setLoadingMessage } from '../../../app/slices/loadingMessageSlice';
import authService from '../../api-authorization/AuthorizeService';
import { useTranslation } from '../../../hooks/useTranslation';

export interface PublicationListProps {
  documents: object[];
  filter: CompositeFilterDescriptor;
  sort: SortDescriptor[];
  docFetched: boolean | null;
  homePage?: boolean;
}

const PublicationList = ({
  documents,
  filter,
  sort,
  docFetched,
}: PublicationListProps) => {
  const [filteredDocuments, setFilteredDocuments] = useState<any[]>([{}]);

  useEffect(() => {
    const publicDocuments = getOnlyPublicDocuments(documents);
    setFilteredDocuments(publicDocuments);
  }, [documents]);

  const dispatch = useAppDispatch();

  // Translations
  const ns = 'construo.documents';
  const translations = {
    goToDocuments: useTranslation(`construo.homepage.goToDocuments`),
    loadMore: useTranslation(`${ns}.loadMore`),
    noResultsMessage: useTranslation(`${ns}.noResultsMessage`),
    noDocumentsMessage: useTranslation(`${ns}.noDocumentsMessage`),
    preparingBulkDownload: useTranslation(`${ns}.preparingBulkDownload`),
    bulkDownloadZipFileName: useTranslation(`${ns}.bulkDownloadZipFileName`),
    selectedToDownload: useTranslation(`${ns}.selectedToDownload`),
  };

  const ITEMS_PER_DOCS_PAGE = envVars.ITEMS_PER_DOCS_PAGE || 10;
  const itemsPerPage: number = ITEMS_PER_DOCS_PAGE;

  const pageNumber = useAppSelector(selectPageNumber);
  const [count, setCount] = useState(itemsPerPage * pageNumber);

  const [isLoadMore, setIsLoadMore] = useState(
    filteredDocuments.length > count,
  );

  const [dataSlice, setDataSlice] = useState(
    filteredDocuments.slice(0, itemsPerPage),
  );

  const [isAllDocsShown, setIsAllDocsShown] = useState(
    dataSlice.length === filteredDocuments.length,
  );

  useEffect(() => {
    return () => {
      dispatch(setPageNumber(1));
      dispatch(setStateSort([{ field: 'DocumentDate', dir: 'desc' }]));
      dispatch(setCurrentSortOption(1));
    };
  }, [dispatch]);

  useEffect(() => {
    if (pageNumber === 1) setDataSlice([]);
    if (isLoadMore) {
      const start = (pageNumber - 1) * itemsPerPage;
      setCount(itemsPerPage * pageNumber);
      setDataSlice(prevSlice => {
        return [...prevSlice, ...filteredDocuments.slice(start, count)];
      });
    } else {
      setCount(itemsPerPage * pageNumber);
      setDataSlice(filteredDocuments);
    }
  }, [pageNumber, count, filteredDocuments, isLoadMore, itemsPerPage]);

  useEffect(() => {
    setIsAllDocsShown(dataSlice.length === filteredDocuments.length);
    setIsLoadMore(filteredDocuments.length > count);
  }, [dataSlice, filteredDocuments, count]);

  /*********************************************************************
   * Bulk Download
   *********************************************************************/
  const bulkDownloadItems = useAppSelector(selectBulkDownloadItems);
  const bulkDownloadBtnVisibility =
    bulkDownloadItems?.length > 0 ? true : false;

  const API_BASE_URI = envVars.API_BASE_URI;

  const handleBulkDownloadClick = async () => {
    dispatch(setLoading(Loading.Show));
    dispatch(setLoadingMessage(translations.preparingBulkDownload));

    const DOCUMENTS_BULK_DOWNLOAD_URI =
      API_BASE_URI + '/user/documents/bulk-download';
    const url = DOCUMENTS_BULK_DOWNLOAD_URI;
    const bodyData = {
      Items: bulkDownloadItems,
      Sender: 'CustomerPortal',
    };
    const data = JSON.stringify(bodyData);
    const token = await authService.getAccessToken();
    const config = getAxiosConfig(token, 'blob');
    await axios
      .post(url, data, config)
      .then(response => response.data)
      .then((blob: any) => {
        saveAs(blob, `${translations.bulkDownloadZipFileName}.zip`);
        dispatch(setLoading(Loading.Hide));
        dispatch(setLoadingMessage(null));
        // CLEAR checked documents and REMOVE Bulk Download Button
        dispatch(setBulkDownloadItems([]));
      })
      .catch((error: any) => {
        dispatch(setLoading(Loading.Hide));
        dispatch(setLoadingMessage(null));
        // Show error message if saving download fails
        dispatch(setAlerts({ message: error.message, type: 'error' }));
        // And scroll to top so that alert is visible
        window.scrollTo(0, 0);
      });
  };

  /*************************************** */

  const data = orderBy(filterBy(dataSlice, filter), sort);

  const ListItemContainer = (props: any) => {
    return <ListItem props={props} checkedArray={bulkDownloadItems} />;
  };

  if (!docFetched) {
    return <DocumentListSkeleton />;
  }

  if (filteredDocuments.length > 0) {
    return (
      <>
        <ListView
          className='documents-list'
          data={data}
          item={ListItemContainer}
        />
        {bulkDownloadBtnVisibility && (
          <div className='bulk-download-btn-plus'>
            <div>
              {bulkDownloadItems.length} {translations.selectedToDownload}
            </div>
            <Button themeColor='primary' onClick={handleBulkDownloadClick}>
              <i className='fal fa-download' />
            </Button>
          </div>
        )}
        {isLoadMore && !isAllDocsShown && (
          <div className='load-more'>
            <Button
              themeColor='primary'
              onClick={() => {
                dispatch(setPageNumber(pageNumber + 1));
              }}
            >
              {translations.loadMore}
            </Button>
          </div>
        )}
      </>
    );
  } else {
    return (
      <div className='documents-message'>{translations.noResultsMessage}</div>
    );
  }
};

export default PublicationList;
