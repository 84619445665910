import React from 'react';
import { useAppSelector } from '../../hooks/reduxHooks';
import { Loader } from '@progress/kendo-react-indicators';
import { selectLoadingMessage } from '../../app/slices/loadingMessageSlice';
import { useTranslation } from '../../hooks/useTranslation';

export interface OverallLoaderProps {
  /**
   * Optional message string in OverallLoader
   * false value is used if you want to hide a message
   */
  message?: string | false;
  transparent?: boolean;
}

const OverallLoader = ({
  message,
  transparent = false,
}: OverallLoaderProps) => {
  const messageFromRedux = useAppSelector(selectLoadingMessage);
  const loading = useTranslation('construo.global.loading');

  const _message =
    message === false
      ? null
      : message === undefined
      ? !!messageFromRedux
        ? messageFromRedux
        : loading
      : message;

  return (
    <div className={`custom-loader ${!!transparent ? 'transparent' : ''}`}>
      <div className='center'>
        <Loader size='large' type='pulsing' />
        {_message !== null ? <span className='text'>{_message}</span> : null}
      </div>
    </div>
  );
};

export default OverallLoader;
