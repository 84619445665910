import React from 'react';
import { Skeleton } from '@progress/kendo-react-indicators';

const PieChartSkeleton = () => {
  return (
    <>
      <Skeleton
        shape={'circle'}
        style={{ height: 250, width: 250, margin: '1rem auto' }}
      />
    </>
  );
};

export default PieChartSkeleton;
