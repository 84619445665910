import React, { ReactElement, useState } from 'react';
import {
  extendDataItem,
  mapTree,
  TreeList,
  TreeListCellProps,
  TreeListColumnProps,
  TreeListExpandChangeEvent,
  TreeListRowProps,
} from '@progress/kendo-react-treelist';
import { Holding, IndexRecord } from './types';
import TableSkeleton from './TableSkeleton';
import { Icon } from '@progress/kendo-react-common';
import { useInternationalization } from '@progress/kendo-react-intl';
import { useTranslation } from '../../../hooks/useTranslation';

type HoldingAdjusted = Omit<Holding, 'accountId' | 'contactGuid'> & {
  children?: HoldingAdjusted[];
};

type IndexRecordAdjusted = IndexRecord & {
  children?: IndexRecordAdjusted[];
};

type TableData = HoldingAdjusted[] | IndexRecordAdjusted[];

export interface PortfolioTableProps {
  title: string;
  columns: TreeListColumnProps[];
  data: TableData;
  markLastAsFooter?: boolean;
  isLoading?: boolean;
}

export const LinkCell = (props: TreeListCellProps) => {
  const field = props.field || '';
  const cellData = props.dataItem[field];

  const ns = 'construo.portfolios';
  const translations = {
    tableLinkLabel: useTranslation(`${ns}.tableLinkLabel`),
  };

  return (
    <td className={`k-table-td link-cell ${props.className}`}>
      {cellData ? (
        <a
          href={String(cellData)}
          target='_blank'
          rel='noopener noreferrer'
          aria-label={translations.tableLinkLabel}
        >
          <Icon className='far fa-external-link fa-fw' />
        </a>
      ) : (
        ''
      )}
    </td>
  );
};

export const DateCell = (props: TreeListCellProps) => {
  const field = props.field || '';
  const cellData = props.dataItem[field];
  const intl = useInternationalization();
  const date =
    !!cellData &&
    intl.formatDate(new Date(cellData), {
      date: 'short',
    });

  return <td className={`k-table-td ${props.className}`}>{date}</td>;
};

const Table = ({
  title,
  columns,
  data,
  markLastAsFooter,
  isLoading,
}: PortfolioTableProps) => {
  const expandField: string = 'expanded';
  const subItemsField: string = 'children';

  const [expanded, setExpanded] = useState<number[]>([]);

  const onExpandChange = (e: TreeListExpandChangeEvent) => {
    setExpanded(
      e.value
        ? expanded.filter(id => id !== e.dataItem.recordId)
        : [...expanded, e.dataItem.recordId],
    );
  };

  const callback = (item: any) => {
    const value = expanded.includes(item.recordId)
      ? extendDataItem(item, subItemsField, { [expandField]: true })
      : item;
    return value;
  };

  const rowRender = (
    row: ReactElement<HTMLTableRowElement>,
    rowProperties: TreeListRowProps,
  ) => {
    const isLast = data.length === rowProperties.level[0] + 1;

    if (markLastAsFooter && isLast) {
      return <row.type {...row.props} className='k-group-footer' />;
    } else {
      return row;
    }
  };

  return (
    <section className='portfolio-table'>
      {title !== '' && <h4>{title}</h4>}
      {isLoading ? (
        <TableSkeleton numberOfColumns={columns?.length} numberOfRows={4} />
      ) : (
        <TreeList
          data={mapTree(data, subItemsField, callback)}
          expandField={expandField}
          subItemsField={subItemsField}
          onExpandChange={onExpandChange}
          columns={columns}
          rowRender={rowRender}
        />
      )}
    </section>
  );
};

export default Table;
