import React from 'react';
import HelmetTitle from '../../common/HelmetTitle';
import { Card, CardBody, CardSubtitle } from '@progress/kendo-react-layout';
import Layout from '../../layout/Layout';
import LanguageSwitcher from '../../common/LanguageSwitcher';
import { useLocation } from 'react-router-dom';
import { useTranslation } from '../../../hooks/useTranslation';

const NotFound = () => {
  const location = useLocation();
  const pathName = `${location?.pathname}`;
  const ns = 'construo.global';
  const translations = {
    page404Title: useTranslation(`${ns}.page404Title`),
    page404Text: useTranslation(`${ns}.page404Text`, [pathName]),
  };
  return (
    <>
      <HelmetTitle title={translations.page404Title} />

      <Layout isSplashLayout={true} layoutClassName='not-found-layout'>
        <div className='main-section not-found'>
          <div className='container'>
            <Card>
              <CardBody>
                <h1 className='k-card-title'>{translations.page404Title}</h1>
                <CardSubtitle>{translations.page404Text}</CardSubtitle>
              </CardBody>
            </Card>
            <LanguageSwitcher />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default NotFound;
