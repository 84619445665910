import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import HelmetTitle from '../../common/HelmetTitle';
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout';
import Table from './Table';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import PieChart, { PieChartDataItem } from './PieChart';
import Graph, { PerformanceGraphData } from './Graph';
import { Holding, IndexRecord } from './types';
import {
  TreeListColumnProps,
  createDataTree,
} from '@progress/kendo-react-treelist';
import PortfoliosLastUpdatedInfo from '../../common/PortfoliosLastUpdatedInfo';
import { useInternationalization } from '@progress/kendo-react-intl';
import { getSumTableRow } from '../../../helpers/utils';
import PortfoliosSkeleton from './PortfoliosSkeleton';
import { resetAlerts, setAlerts } from '../../../app/slices/alertSlice';
import { useConfig } from '../../../hooks/useConfig';
import Layout from '../../layout/Layout';
import { useTranslation } from '../../../hooks/useTranslation';

interface PortfoliosProps {
  isLoading: boolean;
  hasUserPortfolios: boolean;
  hasAccountPortfolios: boolean;
  accountPortfolios?: Holding[];
  initialTab?: number;
  selectedPortfolio?: Holding;
  selectedRecordId?: number;

  holdingsData: Holding[];
  timeSeriesData: PerformanceGraphData[];
  indicesData: IndexRecord[];

  isHoldingsFetched: boolean | undefined;
  isTimeSeriesFetched: boolean | undefined;
  isIndicesFetched: boolean | undefined;

  userPortfoliosError: string | null;
  holdingsError: string | false | undefined;
  timeSeriesError: string | false | undefined;
  indicesError: string | undefined;

  lastUpdatedInfo?: JSX.Element;

  allocationsPieChart?: JSX.Element;
  allocationsTable?: JSX.Element;
  allocationTableColumns: TreeListColumnProps[];
  allocationTableReportLevel: number;

  holdingsTable?: JSX.Element;
  holdingsTableColumns: TreeListColumnProps[];
  holdingsTableReportLevel: number;

  performanceGraph?: JSX.Element;
  performanceTable?: JSX.Element;
  performanceTableColumns: TreeListColumnProps[];
  performanceTableReportLevel: number;
  performanceTableTwo?: JSX.Element;
  performanceTableTwoColumns: TreeListColumnProps[];
  performanceTableTwoReportLevel: number;

  indexTable?: JSX.Element;
  indexTableColumns: TreeListColumnProps[];
  indexTableReportLevel: number;

  onChange: (e: any) => void;
}

const Portfolios = ({
  isLoading,
  accountPortfolios,
  initialTab: initialTabProp,
  selectedPortfolio,
  selectedRecordId,
  hasUserPortfolios,
  hasAccountPortfolios,

  holdingsData,
  timeSeriesData,
  indicesData,

  isHoldingsFetched,
  isTimeSeriesFetched,
  isIndicesFetched,

  userPortfoliosError,
  holdingsError,
  timeSeriesError,
  indicesError,

  lastUpdatedInfo,

  allocationsPieChart,
  allocationsTable,
  allocationTableColumns,
  allocationTableReportLevel,

  holdingsTable,
  holdingsTableColumns,
  holdingsTableReportLevel,

  performanceGraph,
  performanceTable,
  performanceTableColumns,
  performanceTableReportLevel,
  performanceTableTwo,
  performanceTableTwoColumns,
  performanceTableTwoReportLevel,

  indexTable,
  indexTableColumns,
  indexTableReportLevel,

  onChange,
}: PortfoliosProps) => {
  const ns = 'construo.portfolios';
  const translations = {
    title: useTranslation(`${ns}.title`),
    tableAllocationTitle: useTranslation(`${ns}.tableAllocationTitle`),
    tableHoldingsTitle: useTranslation(`${ns}.tableHoldingsTitle`),
    tablePerformanceTitle: useTranslation(`${ns}.tablePerformanceTitle`),
    tablePerformanceTwoTitle: useTranslation(`${ns}.tablePerformanceTwoTitle`),
    tableIndexTitle: useTranslation(`${ns}.tableIndexTitle`),
    tabAllocationTitle: useTranslation(`${ns}.tabAllocationTitle`),
    tabHoldingsTitle: useTranslation(`${ns}.tabHoldingsTitle`),
    tabPerformanceTitle: useTranslation(`${ns}.tabPerformanceTitle`),
    tabIndexTitle: useTranslation(`${ns}.tabIndexTitle`),
    disclaimerText: useTranslation(`${ns}.disclaimerText`),
    tableAllocationFooterRowTitle: useTranslation(
      `${ns}.tableAllocationFooterRowTitle`,
    ),
    tableHoldingsFooterRowTitle: useTranslation(
      `${ns}.tableHoldingsFooterRowTitle`,
    ),
    tablePerformanceFooterRowTitle: useTranslation(
      `${ns}.tablePerformanceFooterRowTitle`,
    ),
    tablePerformanceTwoFooterRowTitle: useTranslation(
      `${ns}.tablePerformanceTwoFooterRowTitle`,
    ),

    userWithoutPortfoliosText: useTranslation(
      `${ns}.userWithoutPortfoliosText`,
    ),
    accountWithoutPortfoliosText: useTranslation(
      `${ns}.accountWithoutPortfoliosText`,
    ),
    userPortfoliosError: useTranslation(`${ns}.userPortfoliosError`),
    holdingsError: useTranslation(`${ns}.holdingsError`),
    timeSeriesError: useTranslation(`${ns}.timeSeriesError`),
    indicesError: useTranslation(`${ns}.indicesError`),
  };

  const dispatch = useAppDispatch();

  const [hasErrors, setHasErrors] = useState<boolean>(false);

  useEffect(() => {
    if (!!userPortfoliosError) {
      setHasErrors(true);
      dispatch(
        setAlerts({
          message: `${translations.userPortfoliosError} ${userPortfoliosError}`,
          type: 'error',
        }),
      );
    }
  }, [dispatch, userPortfoliosError, translations.userPortfoliosError]);

  useEffect(() => {
    if (!!holdingsError) {
      setHasErrors(true);
      dispatch(
        setAlerts({
          message: `${translations.holdingsError} ${holdingsError}`,
          type: 'error',
        }),
      );
    }
  }, [dispatch, holdingsError, translations.holdingsError]);

  useEffect(() => {
    if (!!timeSeriesError) {
      setHasErrors(true);
      dispatch(
        setAlerts({
          message: `${translations.timeSeriesError} ${timeSeriesError}`,
          type: 'error',
        }),
      );
    }
  }, [dispatch, timeSeriesError, translations.timeSeriesError]);

  useEffect(() => {
    if (!!indicesError) {
      setHasErrors(true);
      dispatch(
        setAlerts({
          message: `${translations.indicesError} ${indicesError}`,
          type: 'error',
        }),
      );
    }
  }, [dispatch, indicesError, translations.indicesError]);

  useEffect(() => {
    return () => {
      dispatch(resetAlerts());
    };
  }, [dispatch]);

  const initialTab = initialTabProp === undefined ? 0 : initialTabProp;
  const [selectedTab, setSelectedTab] = useState<number>(initialTab);

  const intl = useInternationalization();

  const handleSelectTab = (e: TabStripSelectEventArguments) => {
    setSelectedTab(e.selected);
  };

  const marketValue: number = !!selectedPortfolio?.periodEndMarketValue
    ? selectedPortfolio.periodEndMarketValue
    : 0;

  const LastUpdatedInfo = () => {
    return lastUpdatedInfo === undefined ? (
      <PortfoliosLastUpdatedInfo portfolio={selectedPortfolio} />
    ) : (
      lastUpdatedInfo
    );
  };

  const config = useConfig();
  const pieChartColors = config.portfolios.allocationsPieChart.colors;

  const AllocationsPieChart = () => {
    let data = holdingsData ? holdingsData : [];
    data = data.filter((item: any) => item.parentId === selectedRecordId);
    const pieChartData: PieChartDataItem[] = data.map(
      (item: any, index: number) => {
        return {
          title: item.title,
          periodEndMarketValue: item.periodEndMarketValue,
          color: !!pieChartColors && pieChartColors[index],
        };
      },
    );
    const isLoading =
      !isHoldingsFetched || !isTimeSeriesFetched || !isIndicesFetched;
    return allocationsPieChart === undefined ? (
      <>
        <PieChart data={pieChartData} isLoading={isLoading} />
      </>
    ) : (
      allocationsPieChart
    );
  };

  const AllocationsTable = () => {
    let flatData = holdingsData ? holdingsData : [];
    flatData = flatData.filter(
      (item: any) =>
        item.portfolioId === selectedPortfolio?.portfolioId &&
        item.reportLevel < allocationTableReportLevel + 1,
    );
    const subItemsField = 'children';
    const data = createDataTree(
      flatData,
      i => i.recordId,
      i => i.parentId,
      subItemsField,
    );
    const lastRow = getSumTableRow(accountPortfolios, selectedRecordId);
    if (!!lastRow) {
      const lastRowUpdated = {
        ...lastRow,
        title: translations.tableAllocationFooterRowTitle,
      };
      data.push(lastRowUpdated);
    }
    return allocationsTable === undefined ? (
      <>
        <Table
          title={translations.tableAllocationTitle}
          columns={allocationTableColumns}
          data={data}
          markLastAsFooter={true}
          isLoading={!data.length || !allocationTableColumns}
        />
      </>
    ) : (
      allocationsTable
    );
  };

  const HoldingsTable = () => {
    let flatData = holdingsData ? holdingsData : [];
    flatData = flatData.filter(
      (item: any) =>
        item.portfolioId === selectedPortfolio?.portfolioId &&
        item.reportLevel < holdingsTableReportLevel + 1,
    );
    const subItemsField = 'children';
    const data = createDataTree(
      flatData,
      i => i.recordId,
      i => i.parentId,
      subItemsField,
    );
    const lastRow = getSumTableRow(accountPortfolios, selectedRecordId);
    if (!!lastRow) {
      const lastRowUpdated = {
        ...lastRow,
        title: translations.tableHoldingsFooterRowTitle,
      };
      data.push(lastRowUpdated);
    }
    return holdingsTable === undefined ? (
      <Table
        title={translations.tableHoldingsTitle}
        columns={holdingsTableColumns}
        data={data}
        markLastAsFooter={true}
        isLoading={!data.length || !holdingsTableColumns}
      />
    ) : (
      holdingsTable
    );
  };

  const PerformanceGraph = () => {
    return performanceGraph === undefined ? (
      <Graph data={timeSeriesData} isLoading={!timeSeriesData.length} />
    ) : (
      performanceGraph
    );
  };

  const PerformanceTable = () => {
    let flatData = holdingsData ? holdingsData : [];
    flatData = flatData.filter(
      (item: any) =>
        item.portfolioId === selectedPortfolio?.portfolioId &&
        item.reportLevel < performanceTableReportLevel + 1,
    );
    const subItemsField = 'children';
    const data = createDataTree(
      flatData,
      i => i.recordId,
      i => i.parentId,
      subItemsField,
    );
    const lastRow = getSumTableRow(accountPortfolios, selectedRecordId);
    if (!!lastRow) {
      const lastRowUpdated = {
        ...lastRow,
        title: translations.tablePerformanceFooterRowTitle,
      };
      data.push(lastRowUpdated);
    }
    return performanceTable === undefined ? (
      <Table
        title={translations.tablePerformanceTitle}
        columns={performanceTableColumns}
        data={data}
        markLastAsFooter={true}
        isLoading={!data.length || !performanceTableColumns}
      />
    ) : (
      performanceTable
    );
  };

  const PerformanceTableTwo = () => {
    let flatData = holdingsData ? holdingsData : [];
    flatData = flatData.filter(
      (item: any) =>
        item.portfolioId === selectedPortfolio?.portfolioId &&
        item.reportLevel < performanceTableTwoReportLevel + 1,
    );
    const subItemsField = 'children';
    const data = createDataTree(
      flatData,
      i => i.recordId,
      i => i.parentId,
      subItemsField,
    );
    const lastRow = getSumTableRow(accountPortfolios, selectedRecordId);
    if (!!lastRow) {
      const lastRowUpdated = {
        ...lastRow,
        title: translations.tablePerformanceTwoFooterRowTitle,
      };
      data.push(lastRowUpdated);
    }
    return performanceTableTwo === undefined ? (
      <Table
        title={translations.tablePerformanceTwoTitle}
        columns={performanceTableTwoColumns}
        data={data}
        markLastAsFooter={true}
        isLoading={!data.length || !performanceTableTwoColumns}
      />
    ) : (
      performanceTableTwo
    );
  };

  const IndexTable = () => {
    let flatData = indicesData ? indicesData : [];
    flatData = flatData.filter(
      (item: any) => item.reportLevel < indexTableReportLevel + 1,
    );
    const subItemsField = 'children';
    const data = createDataTree(
      flatData,
      i => i.recordId,
      i => i.parentId,
      subItemsField,
    );
    return indexTable === undefined ? (
      <Table
        title={translations.tableIndexTitle}
        columns={indexTableColumns}
        data={data}
        isLoading={!data.length || !indexTableColumns}
      />
    ) : (
      indexTable
    );
  };

  return (
    <>
      <HelmetTitle title={translations.title} />
      <Layout>
        <div className='main-section portfolios'>
          <div className='container'>
            <h1>{translations.title}</h1>
            {isLoading || hasErrors ? (
              <PortfoliosSkeleton />
            ) : !hasUserPortfolios ? (
              <>
                <p>{translations.userWithoutPortfoliosText}</p>
              </>
            ) : !hasAccountPortfolios ? (
              <>
                <p>{translations.accountWithoutPortfoliosText}</p>
              </>
            ) : (
              <>
                <DropDownList
                  className='portfolio-drop-down h2'
                  data={accountPortfolios}
                  textField='title'
                  dataItemKey='portfolioId'
                  value={selectedPortfolio}
                  fillMode={null}
                  size='large'
                  onChange={(e: any) => onChange(e)}
                />

                <h3 className='portfolio-value'>
                  {intl.formatNumber(marketValue, 'n0')}{' '}
                  {config.currencyAbbreviation}
                </h3>

                <TabStrip selected={selectedTab} onSelect={handleSelectTab}>
                  <TabStripTab
                    title={translations.tabAllocationTitle}
                    contentClassName='allocation-tab'
                  >
                    <LastUpdatedInfo />
                    <AllocationsPieChart />
                    <AllocationsTable />
                  </TabStripTab>
                  <TabStripTab
                    title={translations.tabHoldingsTitle}
                    contentClassName='holdings-tab'
                  >
                    <LastUpdatedInfo />
                    <HoldingsTable />
                  </TabStripTab>
                  <TabStripTab
                    title={translations.tabPerformanceTitle}
                    contentClassName='performance-tab'
                  >
                    <LastUpdatedInfo />
                    <PerformanceGraph />
                    <PerformanceTable />
                    <PerformanceTableTwo />
                  </TabStripTab>
                  <TabStripTab
                    title={translations.tabIndexTitle}
                    contentClassName='index-tab'
                  >
                    <LastUpdatedInfo />
                    <IndexTable />
                  </TabStripTab>
                </TabStrip>

                <p className='disclaimer-text'>{translations.disclaimerText}</p>
              </>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Portfolios;
