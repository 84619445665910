import React from 'react';

import envVars from '../../resources/envVars';

export interface ConfigCheckerProps {
  children?: React.ReactNode;
}

const ConfigChecker = ({ children }: ConfigCheckerProps) => {
  const configData: any = envVars;

  let propsMissing: string[] = [];
  let isConfigMissing = false;

  if (configData !== null) {
    const settingsDataKeys = Object.keys(configData);

    propsMissing = settingsDataKeys.filter(
      (key: any) => configData[key] === null
    );

    if (propsMissing.length > 0) isConfigMissing = true;
  }

  const configMissingItems = propsMissing.map(prop => {
    return <li key={prop}>REACT_APP_{prop}</li>;
  });

  if (!isConfigMissing) {
    return <>{children}</>;
  } else {
    return (
      <section className='config-checker main-section'>
        <div className='container'>
          {/* We can't use translations here since <CustomizationProvider> is a child component of this one */}
          <h1>Configuration Missing</h1>
          <p>
            Configuration values are missing. Report this error to Customer
            Portal Administrator.
          </p>
          <ul>{configMissingItems}</ul>
        </div>
      </section>
    );
  }
};

export default ConfigChecker;
