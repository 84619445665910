import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { Button } from '@progress/kendo-react-buttons';
import { ComboBox, DropDownList } from '@progress/kendo-react-dropdowns';

import {
  setStateSort,
  setStateFilters,
  setFiltersStatus,
  selectDocumentsFilter,
  selectFiltersStatus,
  setCurrentSortOption,
  selectDocumentsCurrentSortOption,
  setPageNumber,
} from './documentsSlice';

import {
  CompositeFilterDescriptor,
  SortDescriptor,
} from '@progress/kendo-data-query';
import { Label } from '@progress/kendo-react-labels';
import {
  selectHasRepresentations,
  selectRepresentationRights,
  selectSelectedAccount,
} from '../../api-authorization/representationSlice';
import { selectUser } from '../../api-authorization/userSlice';
import {
  getFilteredContactsByOwnContactGuid,
  getItemsFilteredByAccountContactGuid,
  getOnlyAssignedDocuments,
  getOnlyPublicDocuments,
} from '../../../helpers/utils';
import { useTranslation } from '../../../hooks/useTranslation';

type FilterLabelTypes = {
  nameAsc: string;
  nameDesc: string;
  dateNewestFirst: string;
  dateOldestFirst: string;
  filterByContacts: string;
  filterByCategory: string;
  filterByType: string;
  filterByYear: string;
  sortBy: string;
  closeFilters: string;
  applyFilters: string;
  clearFilters: string;
};
export interface FiltersProps {
  documents: object[];
  filtersStatus?: boolean;
  labels?: FilterLabelTypes;
  isPublicationsOnly?: boolean;
  quickSearchKeyword?: string;
}

const Filters = ({
  documents,
  filtersStatus,
  labels,
  isPublicationsOnly,
  quickSearchKeyword,
}: FiltersProps) => {
  const dispatch = useAppDispatch();
  const ns = 'construo.documents';
  const translations = {
    nameAsc: useTranslation(`${ns}.nameAsc`),
    nameDesc: useTranslation(`${ns}.nameDesc`),
    dateNewestFirst: useTranslation(`${ns}.dateNewestFirst`),
    dateOldestFirst: useTranslation(`${ns}.dateOldestFirst`),
    filterByContacts: useTranslation(`${ns}.filterByContacts`),
    filterByCategory: useTranslation(`${ns}.filterByCategory`),
    filterByType: useTranslation(`${ns}.filterByType`),
    filterByYear: useTranslation(`${ns}.filterByYear`),
    sortBy: useTranslation(`${ns}.sortBy`),
    closeFilters: useTranslation(`${ns}.closeFilters`),
    applyFilters: useTranslation(`${ns}.applyFilters`),
    clearFilters: useTranslation(`${ns}.clearFilters`),
  };
  labels = labels === undefined ? translations : labels;

  const hasRepresentations = useAppSelector(selectHasRepresentations);
  const accounts = useAppSelector(selectRepresentationRights);
  const selectedAccount = useAppSelector(selectSelectedAccount);
  const selectedAccountGuid = selectedAccount?.contactGuid;
  const userState = useAppSelector(selectUser);
  const isLoggedInUser = userState.contactGuid === selectedAccountGuid;

  const reduxSortOption = useAppSelector(selectDocumentsCurrentSortOption);
  const filter = useAppSelector(selectDocumentsFilter);
  const reduxFiltersStatus = useAppSelector(selectFiltersStatus);
  filtersStatus =
    filtersStatus === undefined ? reduxFiltersStatus : filtersStatus;

  const initialFilterValue = {
    logic: 'and',
    filters: [],
  } as CompositeFilterDescriptor;

  const [accountBasedDocuments, setAccountBasedDocuments] = useState<object[]>(
    [],
  );

  const publicDocumentsOnly = getOnlyPublicDocuments(documents);
  const assignedDocumentsOnly = getOnlyAssignedDocuments(accountBasedDocuments);

  const documentsResult = isPublicationsOnly
    ? publicDocumentsOnly
    : assignedDocumentsOnly;

  useEffect(() => {
    if (hasRepresentations && documents.length > 0 && !!selectedAccountGuid) {
      // NEED TO FILTER Documents list by account contact GUID
      const filteredByAccount = getItemsFilteredByAccountContactGuid(
        documents,
        accounts,
        selectedAccountGuid,
        isLoggedInUser,
      );
      setAccountBasedDocuments(filteredByAccount);
    } else if (!hasRepresentations && documents.length > 0) {
      // NO NEED TO FILTER Documents list by account contact GUID
      setAccountBasedDocuments(documents);
    }
  }, [
    documents,
    accounts,
    hasRepresentations,
    selectedAccountGuid,
    isLoggedInUser,
  ]);

  // Set Refs for FilterComboboxes
  const [contactComboboxInput, setContactComboboxInput] = useState<any>(null);
  const contactComboboxRef = useCallback((node: string) => {
    setContactComboboxInput(node);
  }, []);
  const [categoryComboboxInput, setCategoryComboboxInput] = useState<any>(null);
  const categoryComboboxRef = useCallback((node: string) => {
    setCategoryComboboxInput(node);
  }, []);
  const [typeComboboxInput, setTypeComboboxInput] = useState<any>(null);
  const typeComboboxRef = useCallback((node: string) => {
    setTypeComboboxInput(node);
  }, []);
  const [yearComboboxInput, setYearComboboxInput] = useState<any>(null);
  const yearComboboxRef = useCallback((node: string) => {
    setYearComboboxInput(node);
  }, []);

  // Clear filters
  const clearFilters = (e: React.MouseEvent) => {
    dispatch(setPageNumber(1));

    if (dataContacts.length > 0) contactComboboxInput.state.value = null;
    if (dataCategories.length > 0) categoryComboboxInput.state.value = null;
    if (dataTypes.length > 0) typeComboboxInput.state.value = null;
    yearComboboxInput.state.value = null;

    if (!quickSearchKeyword) {
      dispatch(setStateFilters(initialFilterValue));
    } else {
      dispatch(
        setStateFilters({
          ...initialFilterValue,
          filters: [
            ...initialFilterValue.filters,
            {
              field: 'DocumentName',
              operator: 'contains',
              value: quickSearchKeyword,
            },
          ],
        }),
      );
    }
  };

  // Unique dataCategories
  const dataCategories = documentsResult
    .map((document: any) => document.Category && document.Category)
    .filter(
      (value, index: number, self) => !!value && self.indexOf(value) === index,
    );

  // Unique dataTypes
  const dataTypes = documentsResult
    .map((document: any) => document.DocumentType && document.DocumentType)
    .filter(
      (value, index: number, self) => !!value && self.indexOf(value) === index,
    );

  // Unique Contacts
  const documentsWithContacts = documentsResult.filter(
    (document: any) =>
      document.Contacts !== undefined && document.Contacts.length > 0,
  );
  const contacts = documentsWithContacts.map(
    (document: any) => document.Contacts,
  );
  let concatContacts: any = [];
  contacts.map((contactsArray: any) => {
    if (contactsArray.length > 1) {
      contactsArray.map((contact: any) => {
        concatContacts.push(contact);
        return false;
      });
    } else if (contactsArray.length === 1) {
      concatContacts.push(contactsArray[0]);
    }
    return false;
  });
  const uniqueContacts = Array.from(
    new Set(concatContacts.map((c: any) => c.ContactGuid)),
  ).map(guid => {
    return {
      ContactGuid: guid,
      ContactType: concatContacts.find((c: any) => c.ContactGuid === guid)
        .ContactType,
      FullName: concatContacts.find((c: any) => c.ContactGuid === guid)
        .FullName,
      Links: concatContacts.find((c: any) => c.ContactGuid === guid).Links,
    };
  });

  // Unique dataYears
  const dataYears = documentsResult
    .map(
      (document: any) =>
        document.DocumentDate &&
        new Date(document.DocumentDate).getFullYear().toString(),
    )
    .filter(
      (value, index: number, self) => !!value && self.indexOf(value) === index,
    );

  const uniqueContactsFiltered = getFilteredContactsByOwnContactGuid(
    uniqueContacts,
    accounts,
    selectedAccountGuid,
  );

  const dataContacts = !hasRepresentations
    ? uniqueContacts
    : isLoggedInUser
      ? uniqueContactsFiltered
      : [];

  // Filter by Contact
  const handleFilterByContact = (value: string | null) => {
    dispatch(setPageNumber(1));
    const contactObject = dataContacts.find(
      (contact: any) => contact.ContactGuid === value,
    );
    const filtersReduced = filter.filters.filter((item: any) => {
      return item.field !== 'Contacts';
    });
    const newFilters = {
      ...filter,
      filters: !!value
        ? [
            ...filtersReduced,
            {
              field: 'Contacts',
              operator: (itemValue: any, value: any) => {
                return (
                  itemValue &&
                  itemValue.find(function (item: any) {
                    return item.ContactGuid === value;
                  })
                );
              },
              value: contactObject?.ContactGuid,
            },
          ]
        : [...filtersReduced],
    };
    dispatch(setStateFilters(newFilters));
  };

  // Filter by document category
  const handleFilterByDocumentCategory = (value: string | null) => {
    dispatch(setPageNumber(1));
    const filtersReduced = filter.filters.filter((item: any) => {
      return item.field !== 'Category';
    });
    const newFilters = {
      ...filter,
      filters: !!value
        ? [
            ...filtersReduced,
            { field: 'Category', operator: 'eq', value: value },
          ]
        : [...filtersReduced],
    };
    dispatch(setStateFilters(newFilters));
  };

  // Filter by document type
  const handleFilterByDocumentType = (value: string | null) => {
    dispatch(setPageNumber(1));
    const filtersReduced = filter.filters.filter((item: any) => {
      return item.field !== 'DocumentType';
    });
    const newFilters = {
      ...filter,
      filters: !!value
        ? [
            ...filtersReduced,
            { field: 'DocumentType', operator: 'eq', value: value },
          ]
        : [...filtersReduced],
    };
    dispatch(setStateFilters(newFilters));
  };

  // Filter by document year
  const handleFilterByDocumentYear = (value: string | null) => {
    dispatch(setPageNumber(1));
    const filtersReduced = filter.filters.filter((item: any) => {
      return item.field !== 'DocumentDate';
    });

    const newFilters = {
      ...filter,
      filters: !!value
        ? [
            ...filtersReduced,
            {
              field: 'DocumentDate',
              operator: (itemValue: any, value: any) => {
                const dateString = itemValue?.toUpperCase();
                const date = new Date(dateString);
                const getYear = date.getFullYear();
                return itemValue && getYear.toString() === value;
              },
              value: value,
            },
          ]
        : [...filtersReduced],
    };
    dispatch(setStateFilters(newFilters));
  };

  // Sorting options
  const sortOptions = [
    {
      sortId: 1,
      sortCriteria: labels.dateNewestFirst,
    },
    {
      sortId: 2,
      sortCriteria: labels.dateOldestFirst,
    },
    {
      sortId: 3,
      sortCriteria: labels.nameAsc,
    },
    {
      sortId: 4,
      sortCriteria: labels.nameDesc,
    },
  ];

  const getCurrentSort = () => {
    const sortValue = sortOptions.filter(
      option => option.sortId === reduxSortOption,
    );
    return sortValue;
  };

  const currentSortOption = getCurrentSort();

  const documentNameAsc = [
    { field: 'DocumentName', dir: 'asc' } as SortDescriptor,
  ];
  const documentNameDesc = [
    { field: 'DocumentName', dir: 'desc' } as SortDescriptor,
  ];
  const dateAsc = [{ field: 'DocumentDate', dir: 'asc' } as SortDescriptor];
  const dateDesc = [{ field: 'DocumentDate', dir: 'desc' } as SortDescriptor];

  type sortTypes = {
    sortId?: number;
    sortCriteria?: string;
  } | null;

  // Sorting list
  const handleSortChange = (value: sortTypes) => {
    const sortId = value?.sortId;
    switch (sortId) {
      case 1:
        dispatch(setStateSort(dateDesc));
        dispatch(setCurrentSortOption(1));
        break;
      case 2:
        dispatch(setStateSort(dateAsc));
        dispatch(setCurrentSortOption(2));
        break;
      case 3:
        dispatch(setStateSort(documentNameAsc));
        dispatch(setCurrentSortOption(3));
        break;
      case 4:
        dispatch(setStateSort(documentNameDesc));
        dispatch(setCurrentSortOption(4));
        break;
      default:
      // code
    }
  };

  // Set Refs for Filters
  const filterByContactsRef: any = useRef(null);
  const filterByCategoryRef: any = useRef(null);
  const filterByTypeRef: any = useRef(null);
  const filterByYearRef: any = useRef(null);
  const sortByRef: any = useRef(null);

  return (
    <div className={filtersStatus ? 'filters on' : 'filters'}>
      <Button
        className='close-btn'
        iconClass='fal fa-times'
        onClick={() => {
          dispatch(setFiltersStatus(false));
        }}
        aria-label={labels.closeFilters}
      />

      <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3'>
        {dataContacts.length > 0 && (
          <div className='col'>
            <div ref={filterByContactsRef}>
              <Label editorId='filterByContacts'>
                {labels.filterByContacts}
              </Label>
              <ComboBox
                id='filterByContacts'
                ref={contactComboboxRef}
                data={dataContacts}
                textField='FullName'
                dataItemKey='ContactGuid'
                suggest={true}
                onChange={e =>
                  handleFilterByContact(e.value ? e.value.ContactGuid : '')
                }
                popupSettings={{
                  appendTo: filterByContactsRef.current,
                }}
              />
            </div>
          </div>
        )}

        {dataCategories.length > 0 && (
          <div className='col'>
            <div ref={filterByCategoryRef}>
              <Label editorId='filterByCategory'>
                {labels.filterByCategory}
              </Label>
              <ComboBox
                id='filterByCategory'
                ref={categoryComboboxRef}
                data={dataCategories}
                suggest={true}
                onChange={e => handleFilterByDocumentCategory(e.value)}
                popupSettings={{
                  appendTo: filterByCategoryRef.current,
                }}
              />
            </div>
          </div>
        )}

        {dataTypes.length > 0 && (
          <div className='col'>
            <div ref={filterByTypeRef}>
              <Label editorId='filterByType'>{labels.filterByType}</Label>
              <ComboBox
                id='filterByType'
                ref={typeComboboxRef}
                data={dataTypes}
                suggest={true}
                onChange={e => handleFilterByDocumentType(e.value)}
                popupSettings={{
                  appendTo: filterByTypeRef.current,
                }}
              />
            </div>
          </div>
        )}

        <div className='col'>
          <div ref={filterByYearRef}>
            <Label editorId='filterByYear'>{labels.filterByYear}</Label>
            <ComboBox
              id='filterByYear'
              ref={yearComboboxRef}
              data={dataYears}
              suggest={true}
              onChange={e => handleFilterByDocumentYear(e.value)}
              popupSettings={{
                appendTo: filterByYearRef.current,
              }}
            />
          </div>
        </div>

        <div className='col'>
          <div ref={sortByRef}>
            <Label editorId='sortBy'>{labels.sortBy}</Label>
            <DropDownList
              id='sortBy'
              data={sortOptions}
              textField='sortCriteria'
              dataItemKey='sortId'
              defaultValue={currentSortOption[0]}
              onChange={e => handleSortChange(e.value)}
              popupSettings={{
                appendTo: sortByRef.current,
              }}
            />
          </div>
        </div>
      </div>

      <div className='filter-buttons'>
        <div className='row row-cols-1 row-cols-sm-2'>
          <div className='col'>
            <div className='apply-btn'>
              <Button
                themeColor='primary'
                onClick={() => {
                  dispatch(setFiltersStatus(false));
                }}
              >
                {labels.applyFilters}
              </Button>
            </div>
          </div>
          <div className='col'>
            <div className='clear-btn'>
              <Button
                themeColor='secondary'
                fillMode='outline'
                onClick={event => {
                  clearFilters(event);
                }}
              >
                {labels.clearFilters}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
