import React from 'react';
import AccountMenu from './AccountMenu';
import Navigation, { NavigationPropTypes } from './Navigation';
import LanguageSwitcher from '../common/LanguageSwitcher';
import { UserState } from '../api-authorization/userSlice';
import { SelectedAccountProps } from '../api-authorization/representationSlice';
import { useTranslation } from '../../hooks/useTranslation';

export type GlobalMenuLabelTypes = {
  close?: string;
  login?: string;
  logout?: string;
  home?: string;
  portfolios?: string;
  documents?: string;
  publications?: string;
  messages?: string;
  inbox?: string;
  accountNameTagline?: string;
  switchAccount?: string;
};

export interface GlobalMenuProps {
  isMultiLanguage?: boolean;
  userProps: UserState;
  labels: GlobalMenuLabelTypes;
  navigationProps: NavigationPropTypes;
  onHideMenu: () => void;
  hasRepresentations?: boolean;
  selectedAccount: SelectedAccountProps | null;
}

const GlobalMenu = ({
  isMultiLanguage,
  userProps,
  labels,
  navigationProps,
  onHideMenu,
  hasRepresentations,
  selectedAccount,
}: GlobalMenuProps) => {
  const translation = {
    close: useTranslation('construo.menu.close'),
  };

  const closeString = labels === undefined ? translation.close : labels.close;

  return (
    <>
      <div className='global-menu'>
        <button type='button' className='btn-close' onClick={onHideMenu}>
          <span className='sr-only'>{closeString}</span>
        </button>

        <AccountMenu
          onHideMenu={onHideMenu}
          userProps={userProps}
          labels={labels}
          hasRepresentations={hasRepresentations}
          selectedAccount={selectedAccount}
        />

        <Navigation
          onHideMenu={onHideMenu}
          labels={labels}
          navigationProps={navigationProps}
          userProps={userProps}
        />

        <LanguageSwitcher isMultiLanguage={isMultiLanguage} />
      </div>
    </>
  );
};

export default GlobalMenu;
