import { useEffect, useState } from 'react';
import { useAppSelector } from './reduxHooks';
import { selectUnreadConversations } from '../components/pages/inbox/conversationsSlice';
import { selectSignatures } from '../components/signatures/signaturesSlice';
import { selectSubmissions } from '../components/submissions/submissionsSlice';
import { selectUploads } from '../components/upload-requests/uploadSlice';
import { selectWorkflows } from '../components/workflows/workflowSlice';

const useNotifications = (): number => {
  const unreadConversationsNumber = useAppSelector(selectUnreadConversations);

  const uploads = useAppSelector(selectUploads);
  const submissions = useAppSelector(selectSubmissions);
  const signatures = useAppSelector(selectSignatures);
  const workflows = useAppSelector(selectWorkflows);

  const [openActionsNumber, setOpenActionNumber] = useState<number>(0);
  const [notificationsNumber, setNotificationNumber] = useState<number>(0);

  useEffect(() => {
    setOpenActionNumber(
      signatures.length +
        uploads.length +
        submissions.length +
        workflows.length,
    );
  }, [signatures, uploads, submissions, workflows]);

  useEffect(() => {
    setNotificationNumber(openActionsNumber + unreadConversationsNumber);
  }, [openActionsNumber, unreadConversationsNumber]);

  return notificationsNumber;
};

export default useNotifications;
