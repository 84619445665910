import React from 'react';
import { Helmet } from 'react-helmet-async';

interface HelmetTitleProps {
  title: string | null;
}

const HelmetTitle = ({ title }: HelmetTitleProps) => {
  return !!title ? (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  ) : null;
};

export default HelmetTitle;
