import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import useAccountBasedWorkflows from '../../hooks/useAccountBasedWorkflows';
import envVars from '../../resources/envVars';
import WorkflowRequest from './WorkflowRequest';
import {
  fetchConversationsWithUnreadMessages,
  selectConversationsWithUnreadMessages,
  selectConversationsWithUnreadMessagesFetched,
} from './taskDetails/messagesSlice';
import {
  fetchWorkflows,
  selectWorkflows,
  selectWorkflowsFetched,
} from './workflowSlice';

export interface WorkflowListProps {
  workflows?: object[] | null;
}

const WorkflowList = ({ workflows = null }: WorkflowListProps) => {
  const dispatch = useAppDispatch();

  const API_BASE_URI = envVars.API_BASE_URI || '';
  const unreadMessagesUrl = `${API_BASE_URI}/user/conversations/unreadactivities?page=1&pageSize=99`;

  workflows = useAppSelector(selectWorkflows);

  const workflowsFetched = useAppSelector(selectWorkflowsFetched);

  useEffect(() => {
    /**
     * The tricky behavior of useEffect hook in React 18
     * https://medium.com/geekculture/the-tricky-behavior-of-useeffect-hook-in-react-18-282ef4fb570a
     * let ignore
     */
    let ignore = false;
    if (workflowsFetched === null) {
      const dataObject = {
        baseUrl: API_BASE_URI,
      };
      setTimeout(() => {
        if (!ignore) {
          dispatch(fetchWorkflows(dataObject));
        }
      }, 0);
    }
    return () => {
      ignore = true;
    };
  }, [dispatch, workflowsFetched, API_BASE_URI]);

  const conversations = useAppSelector(selectConversationsWithUnreadMessages);
  const conversationsFetched = useAppSelector(
    selectConversationsWithUnreadMessagesFetched
  );
  useEffect(() => {
    /**
     * The tricky behavior of useEffect hook in React 18
     * https://medium.com/geekculture/the-tricky-behavior-of-useeffect-hook-in-react-18-282ef4fb570a
     * let ignore
     */
    let ignore = false;
    if (conversationsFetched === null) {
      const dataObject = {
        url: unreadMessagesUrl,
      };
      setTimeout(() => {
        if (!ignore) {
          dispatch(fetchConversationsWithUnreadMessages(dataObject));
        }
      }, 0);
    }
    return () => {
      ignore = true;
    };
  }, [dispatch, conversationsFetched, unreadMessagesUrl]);

  /**
   * Account Based filtering of Workflows
   */
  const accountBasedData = useAccountBasedWorkflows();

  return (
    <>
      {accountBasedData !== null &&
        accountBasedData.map((workflow: any) => {
          return (
            <WorkflowRequest
              key={workflow.id}
              workflow={workflow}
              conversations={conversations}
            />
          );
        })}
    </>
  );
};

export default WorkflowList;
