import { useEffect, useState } from 'react';
import { useAppSelector } from './reduxHooks';
import {
  selectHasRepresentations,
  selectRepresentationRights,
  selectSelectedAccount,
} from '../components/api-authorization/representationSlice';
import { selectUser } from '../components/api-authorization/userSlice';
import { IAccount } from '../components/pages/select-account/SelectAccount';
import {
  selectWorkflows,
  selectWorkflowsFetched,
} from '../components/workflows/workflowSlice';

import { getWorkflowsFilteredByAccountContactGuid } from '../helpers/utils';

const useAccountBasedWorkflows = () => {
  const workflowsFetched = useAppSelector(selectWorkflowsFetched);
  const accounts = useAppSelector(selectRepresentationRights);
  const selectedAccount = useAppSelector(selectSelectedAccount);
  const userState = useAppSelector(selectUser);
  const hasRepresentations = useAppSelector(selectHasRepresentations);
  const workflows = useAppSelector(selectWorkflows);

  const [accountBasedWorkflows, setAccountBasedWorkflows] = useState<object[]>(
    [],
  );

  useEffect(() => {
    const selectedContactGuid = selectedAccount?.contactGuid;
    const isLoggedInUser = userState.contactGuid === selectedContactGuid;

    const allButLoggedInUserAccounts = accounts?.filter(
      (representation: IAccount) =>
        representation.contactGuid !== userState?.contactGuid,
    );
    const allButLoggedInUserAccountGUIDs: any = allButLoggedInUserAccounts?.map(
      (account: IAccount) => account.contactGuid,
    );
    if (
      workflowsFetched &&
      hasRepresentations &&
      workflows !== null &&
      workflows.length > 0 &&
      !!selectedContactGuid
    ) {
      // NEED TO FILTER Documents list by account contact GUID
      const filteredByAccount = getWorkflowsFilteredByAccountContactGuid(
        workflows,
        allButLoggedInUserAccountGUIDs,
        selectedContactGuid,
        isLoggedInUser,
      );
      setAccountBasedWorkflows(filteredByAccount);
    } else if (
      !hasRepresentations &&
      workflows !== null &&
      workflows.length > 0
    ) {
      // NO NEED TO FILTER Documents list by account contact GUID
      setAccountBasedWorkflows(workflows);
    }
  }, [
    workflows,
    hasRepresentations,
    accounts,
    selectedAccount?.contactGuid,
    workflowsFetched,
    userState.contactGuid,
  ]);

  return accountBasedWorkflows;
};

export default useAccountBasedWorkflows;
