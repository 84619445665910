import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';

import representationsSlice from '../components/api-authorization/representationSlice';
import userReducer from '../components/api-authorization/userSlice';

import portfoliosReducer from '../components/pages/portfolios/portfoliosSlice';

import documentsReducer from '../components/pages/documents/documentsSlice';
import signaturesReducer from '../components/signatures/signaturesSlice';
import submissionsReducer from '../components/submissions/submissionsSlice';
import workflowsReducer from '../components/workflows/workflowSlice';
import taskDetailsReducer from '../components/workflows/taskDetails/taskDetailsSlice';
import messagesReducer from '../components/workflows/taskDetails/messagesSlice';
import conversationsReducer from '../components/pages/inbox/conversationsSlice';
import uploadsReducer from '../components/upload-requests/uploadSlice';
import publicFormReducer from '../components/pages/public-forms/publicFormSlice';
import submissionReducer from '../components/pages/submission-forms/submissionSlice';
import language from '../components/common/languageSwitcherSlice';
import visibilityMenu from './slices/visibilityMenuSlice';
import loading from './slices/loadingSlice';
import loadingMessage from './slices/loadingMessageSlice';
import alert from './slices/alertSlice';
import modal from './slices/modalSlice';

export const rootReducer = combineReducers({
  representations: representationsSlice,
  user: userReducer,
  portfolios: portfoliosReducer,
  signatures: signaturesReducer,
  uploads: uploadsReducer,
  documents: documentsReducer,
  submissions: submissionsReducer,
  publicForm: publicFormReducer,
  submission: submissionReducer,
  workflows: workflowsReducer,
  taskDetails: taskDetailsReducer,
  messages: messagesReducer,
  conversations: conversationsReducer,
  visibilityMenu,
  language,
  loading,
  loadingMessage,
  alert: alert,
  modal,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        // ignoredActions: ['fetchConversation/rejected'],

        // Ignore these field paths in all actions
        ignoredActionPaths: ['payload'],

        // Ignore these paths in the state
        // ignoredPaths: ['items.dates'],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
