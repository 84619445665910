import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export enum VisibilityMenu {
  Show = 'SHOW',
  Hide = 'HIDE',
}

const initialState = VisibilityMenu.Hide;

const visibilityMenuSlice = createSlice({
  name: 'visibilityMenu',
  initialState,
  reducers: {
    setVisibilityMenu(state, action: PayloadAction<VisibilityMenu>) {
      return action.payload;
    },
  },
});

export const { setVisibilityMenu } = visibilityMenuSlice.actions;

export const selectVisibilityMenu = (state: RootState) => state.visibilityMenu;

export default visibilityMenuSlice.reducer;
