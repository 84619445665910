import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getSearchParam, navigateToReturnUrl } from '../../../helpers/utils';
import {
  Avatar,
  Card,
  CardBody,
  CardSubtitle,
} from '@progress/kendo-react-layout';
import HelmetTitle from '../../common/HelmetTitle';
import { ListView, ListViewItemProps } from '@progress/kendo-react-listview';
import { Icon } from '@progress/kendo-react-common';
import LanguageSwitcher from '../../common/LanguageSwitcher';
import Layout from '../../layout/Layout';

enum ContactType {
  Company = 2,
  Person = 1,
}

export interface IAccount {
  type: ContactType | null;
  name: string | null;
  contactGuid: string | null;
  scope: string[] | null;
}

export interface SelectAccountProps {
  title: string;
  heading?: string;
  subheading?: string;
  accountListData: IAccount[];
  onAccountClick(account: IAccount | null): void;
}

const SelectAccount = ({
  title,
  heading,
  subheading,
  accountListData,
  onAccountClick,
}: SelectAccountProps) => {
  // Make sure that user is scrolled to top after SelectAccount view is unloaded even though path stays the same
  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  });

  const eSignStatus = getSearchParam('esign');
  if (!!eSignStatus) {
    navigateToReturnUrl(`/report-page?esign=${eSignStatus}`);
  }

  const AccountListItem = (props: ListViewItemProps) => {
    let item = props.dataItem;
    const icon = item.type === 2 ? 'building' : 'user';

    return (
      <Link
        className='account-list-item'
        key={item.contactGuid}
        to={'/'}
        onClick={() => onAccountClick(item)}
      >
        <Card orientation='horizontal'>
          <CardBody className='account-list-icon'>
            <Avatar type={'icon'} themeColor={'light'}>
              <Icon className={`far fa-${icon} fa-fw`} />
            </Avatar>
          </CardBody>
          <CardBody className='account-list-text'>
            <p>{item.name}</p>
          </CardBody>
        </Card>
      </Link>
    );
  };

  return !eSignStatus ? (
    <>
      <HelmetTitle title={title} />
      <Layout isSplashLayout={true} layoutClassName='select-account-layout'>
        <section className='main-section select-account'>
          <div className='container'>
            <Card>
              <CardBody>
                {heading && <h1 className='k-card-title'>{heading}</h1>}
                {subheading && <CardSubtitle>{subheading}</CardSubtitle>}
                <ListView data={accountListData} item={AccountListItem} />
              </CardBody>
            </Card>
            <LanguageSwitcher />
          </div>
        </section>
      </Layout>
    </>
  ) : null;
};

export default SelectAccount;
