import { enProductMessages } from '../../../messages/product.en-150';

/**
 * Specify Customer translations
 */
export const enCustomMessages = {
  ...enProductMessages,
  construo: {
    ...enProductMessages.construo,
    homepage: {
      ...enProductMessages.construo.homepage,
      publicHeading: 'Welcome to the Customer Portal',
    },
    footer: {
      ...enProductMessages.construo.footer,
      link1Label: '',
      link1URL: '',
      link2Label: '',
      link2URL: '',
    },
  },
};
