import React from 'react';
import { Skeleton } from '@progress/kendo-react-indicators';

interface TableSkeletonProps {
  numberOfColumns: number;
  numberOfRows: number;
}

const TableSkeleton = ({
  numberOfColumns,
  numberOfRows,
}: TableSkeletonProps) => {
  let headerCells = [];
  for (let i = 0; i < numberOfColumns; i++) {
    headerCells.push(
      <th className='k-table-th k-header' key={i}>
        <Skeleton shape={'text'} style={{ width: 50 }} />
      </th>
    );
  }

  let dataCells = [];
  for (let i = 0; i < numberOfColumns; i++) {
    dataCells.push(
      <td className='k-table-td text-nowrap k-text-nowrap' key={i}>
        <Skeleton shape={'text'} style={{ width: 50 }} />
      </td>
    );
  }

  let dataRows = [];
  for (let i = 0; i < numberOfRows; i++) {
    dataRows.push(
      <tr className='k-table-row k-master-row' key={i}>
        {dataCells}
      </tr>
    );
  }

  return (
    <div
      className='k-grid k-grid-md k-treelist-scrollable'
      style={{ overflow: 'auto' }}
    >
      <table className='k-table k-table-md k-grid-table'>
        <thead className='k-table-thead k-grid-header'>
          <tr className='k-table-row'>{headerCells}</tr>
        </thead>
        <tbody className='k-table-tbody'>{dataRows}</tbody>
      </table>
    </div>
  );
};

export default TableSkeleton;
