import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import useAccountBasedUploads from '../../hooks/useAccountBasedUploads';
import { fetchUploadRequests, selectUploadsFetched } from './uploadSlice';
import UploadRequest from './UploadRequest';
import envVars from '../../resources/envVars';

const UploadRequestList = () => {
  const uploadsFetched = useAppSelector(selectUploadsFetched);

  const dispatch = useAppDispatch();

  const API_BASE_URI = envVars.API_BASE_URI || '';

  useEffect(() => {
    /**
     * The tricky behavior of useEffect hook in React 18
     * https://medium.com/geekculture/the-tricky-behavior-of-useeffect-hook-in-react-18-282ef4fb570a
     * let ignore
     */
    let ignore = false;
    if (uploadsFetched === null) {
      const dataObject = {
        baseUrl: API_BASE_URI,
      };
      setTimeout(() => {
        if (!ignore) {
          dispatch(fetchUploadRequests(dataObject));
        }
      }, 0);
    }
    return () => {
      ignore = true;
    };
  }, [dispatch, uploadsFetched, API_BASE_URI]);

  /**
   * Account Based filtering of Upload Requests
   */
  const accountBasedData = useAccountBasedUploads();

  return (
    <>
      {accountBasedData.map((upload: any) => {
        return <UploadRequest key={upload._id} upload={upload} />;
      })}
    </>
  );
};

export default UploadRequestList;
