import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import useNotifications from '../../hooks/useNotifications';
import envVars from '../../resources/envVars';
import { selectUser, UserState } from '../api-authorization/userSlice';
import {
  fetchUnreadConversations,
  selectUnreadConversationsFetched,
} from '../pages/inbox/conversationsSlice';
import GlobalMenu from './GlobalMenu';
import { NavigationPropTypes } from './Navigation';
import {
  SelectedAccountProps,
  selectSelectedAccount,
} from '../api-authorization/representationSlice';
import { useTranslation } from '../../hooks/useTranslation';

export interface GlobalMenuDataProviderProps {
  isMultiLanguage?: boolean;
  onHideMenu: () => void;
  userState?: UserState;
  navigationProps?: NavigationPropTypes;
  unreadConversationsFetched?: boolean;
  hasRepresentations?: boolean;
  selectedAccount?: SelectedAccountProps | null;
}

const GlobalMenuDataProvider = ({
  isMultiLanguage,
  onHideMenu,
  userState,
  navigationProps,
  unreadConversationsFetched: unreadConversationsFetchedProp,
  hasRepresentations,
  selectedAccount: selectedAccountProp,
}: GlobalMenuDataProviderProps) => {
  const dispatch = useAppDispatch();

  const isPortfoliosFeature = envVars.FEATURES?.includes('portfolios');
  const isDocumentListFeature = envVars.FEATURES?.includes('documentList');
  const isPublicationListFeature =
    envVars.FEATURES?.includes('publicationList');
  const isConversationsFeature = envVars.FEATURES?.includes('conversations');

  const userStateSelect: UserState = useAppSelector(selectUser);

  userState = userState === undefined ? userStateSelect : userState;

  const ns = 'construo.menu';
  const translations = {
    home: useTranslation(`${ns}.home`),
    portfolios: useTranslation(`${ns}.portfolios`),
    documents: useTranslation(`${ns}.documents`),
    publications: useTranslation(`${ns}.publications`),
    inbox: useTranslation(`${ns}.inbox`),
    login: useTranslation(`${ns}.login`),
    logout: useTranslation(`${ns}.logout`),
    accountNameTagline: useTranslation(`${ns}.accountNameTagline`, [
      userState.username,
    ]),
    switchAccount: useTranslation(`${ns}.switchAccount`),
  };

  const selectedAccountSelect = useAppSelector(selectSelectedAccount);
  const selectedAccount =
    selectedAccountProp === undefined
      ? selectedAccountSelect
      : selectedAccountProp;

  const isUserLogged = userState?.isLoggedIn;

  const unreadConversationsFetchedSelect = useAppSelector(
    selectUnreadConversationsFetched,
  );

  const unreadConversationsFetched =
    unreadConversationsFetchedProp === undefined
      ? unreadConversationsFetchedSelect
      : unreadConversationsFetchedProp;

  const baseUrl = envVars.API_BASE_URI;

  useEffect(() => {
    /**
     * The tricky behavior of useEffect hook in React 18
     * https://medium.com/geekculture/the-tricky-behavior-of-useeffect-hook-in-react-18-282ef4fb570a
     * let ignore
     */
    let ignore = false;
    if (
      unreadConversationsFetched === null &&
      isUserLogged &&
      isConversationsFeature
    ) {
      const url = `${baseUrl}/user/conversations/count`;
      const dataObject = {
        url,
      };
      setTimeout(() => {
        if (!ignore) {
          dispatch(fetchUnreadConversations(dataObject));
        }
      }, 0);
    }
    return () => {
      ignore = true;
    };
  }, [
    dispatch,
    isUserLogged,
    isConversationsFeature,
    unreadConversationsFetched,
    baseUrl,
  ]);

  let notifications: number = useNotifications();
  const hasNotifications = notifications > 0;
  notifications = !!notifications && notifications < 100 ? notifications : 99;

  navigationProps =
    navigationProps === undefined
      ? {
          isUserLogged,
          isPortfoliosFeature,
          isDocumentListFeature,
          isPublicationListFeature,
          hasNotifications,
          notifications,
        }
      : navigationProps;

  return (
    <GlobalMenu
      isMultiLanguage={isMultiLanguage}
      userProps={userState}
      labels={translations}
      navigationProps={navigationProps}
      onHideMenu={onHideMenu}
      hasRepresentations={hasRepresentations}
      selectedAccount={selectedAccount}
    />
  );
};

export default GlobalMenuDataProvider;
