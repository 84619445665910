import enKendoMessages from './kendo/en-150.json';

export const enProductMessages = {
  // Kendo React components translations
  // https://www.telerik.com/kendo-react-ui/components/intl/l10n/
  // https://www.telerik.com/kendo-react-ui/components/intl/globalization-support/
  // https://github.com/telerik/kendo-react-messages
  // https://kendo.git.progress.com/kendo-react-private.git (use Telerik login credentials for access)
  // Bellow should be only translations for components used in Customer Portal
  ...enKendoMessages,

  // Custom Construo messages
  construo: {
    global: {
      loginTitle: 'Login',
      logoutTitle: 'Logout',
      loading: 'Loading...',
      successfullyLoggedOut: 'You successfully logged out',
      loggedOutHeading: 'Thank you for your visit',
      loggedOutSubHeading:
        "You've logged out successfully. If you are using a public computer, we recommend you close the browser window.",
      logBackIn: 'Log back in',
      download: 'Download',
      previewNotSupported:
        'Preview is not supported on this device. Instead, use following link to open document in new tab.',
      processingLogin:
        'We are checking your browser to log you in securely. This may take a moment.',
      processingLoginCallback: 'We are logging you in securely. Please wait.',
      processingLogout:
        'We are logging you out securely. Please do not close this window.',
      processingLogoutCallback: 'You are being logged out.',
      gotoLoginPage: 'Click here to log in',
      page404Title: '404 Error - Page Not Found',
      page404Text:
        'The page with path: "{0}" couldn\'t be found. Please return to the homepage.',
      resource404Title: '{0} not found',
      resource404Text:
        "The {0} you are trying to access couldn't be found. It may not exist, or you may no longer have access to it. If you accessed this page by entering a URL in your browser, please make sure that you have entered it correctly.",
      resourceType: 'Page',
      submissionType: 'Form',
      workflowType: 'Workflow',
      taskType: 'Task',
      conversationType: 'Message',
      documentType: 'Document',
      id: 'ID',
      resource: 'Resource',
      type: 'Type',
      invalid: 'Invalid ID format',
    },
    header: {
      logoAlt: 'Logo of {0}',
      menuBtnLabel: 'Menu',
    },
    desktopNavigation: {
      link1Label: '',
      link1URL: '',
      link2Label: '',
      link2URL: '',
      link3Label: '',
      link3URL: '',
      link4Label: '',
      link4URL: '',
      link5Label: '',
      link5URL: '',
    },
    footer: {
      text: '© {0} {1}',
      link1Label: 'Link 1',
      link1URL: 'https://google.com/',
      link2Label: 'Link 2',
      link2URL: 'https://google.com/',
      link3Label: '',
      link3URL: '',
    },
    menu: {
      close: 'Close',
      home: 'Home',
      portfolios: 'Portfolios',
      documents: 'Documents',
      publications: 'Publications',
      messages: 'Messages',
      inbox: 'Inbox',
      login: 'Login',
      logout: 'Logout',
      languageSwitcherLabel: 'Language',
      english: 'English',
      norwegian: 'Norsk',
      finnish: 'Suomi',
      swedish: 'Svenska',
      accountNameTagline: '{0} for',
      switchAccount: 'Switch entity',
    },
    selectAccount: {
      title: 'Select entity',
      heading: 'Welcome, {0}',
      subheading:
        'Select the company / client for which you would like to view information', // Subheading inside SelectAccount
    },
    homepage: {
      publicHeading: 'Welcome to our portal',
      publicSubHeading: 'Please log in to continue',
      login: 'Login',
      actionRequired: 'Your action is required:',
      latestDocuments: 'Latest documents',
      goToDocuments: 'Go to all documents',
      signingCompleted: 'Signature complete',
      signingCompletedText: 'Thank you! You have successfully signed.',
      signingUncompleted: 'Signature unsuccessful',
      signingUncompletedText:
        "The document wasn't signed successfully. Please try again later.",
      portfoliosSection: {
        title: 'Portfolios',
        totalMarketValue: 'Market Value',
        periodEndMarketValue: 'Market Value',
        mtdTotalGain: 'Returns this month',
        ytdTotalGain: 'Returns year to date',
        sinceInceptionTotalGain: 'Returns since inception {0}',
        seePortfolio: 'Open the portfolio',
      },
      publicationsSection: {
        title: 'Publications',
        goToPublications: 'Go to all Publications',
        loadingPublicationPreview: 'Loading publication...',
        noPublicationsMessage:
          'There are no publications to show at this time.',
        categoryName_1: 'Category 1',
        categoryName_2: 'Category 2',
        categoryName_3: 'Category 3',
        categoryName_4: 'Category 4',
      },
    },
    signatureRequests: {
      noSignatureName: 'Your signing request',
      signBefore: 'Please sign before',
      redirectToESignLoadingText: 'We are redirecting you to the document...',
      userMissingNinAndBankId:
        'We could not retrieve your identity information from BankId at this time. Please try to log in again later. If the problem continues, please contact us for assistance.',
      userMissingNinAndLocal:
        'eSignature requests are only accessible after login with a digital identity. Please log in with BankId to view your signing request. If you received an email from us notifying you of a pending eSignature request but you do not have BankId, please contact us for assistance.',
    },
    uploadRequests: {
      uploadBefore: 'Upload before',
      upload: 'Upload',
      docUpload: 'Document Upload',
      uploadingDoc: 'Your document is being uploaded',
      requireDoc: 'We require the following document from you:',
      requireAttachments:
        'Please upload the requested documentation here. You can upload 15 documents at a time.',
      fileRestriction:
        'On mobile, tap to select a file or take a picture. Only PDF documents and images (.jpg, .jpeg, .png) are allowed. The maximum file size for upload is 10MB.',
      noDocumentName: 'Your document request',
      noCommentToClient: '',
      uploadSuccessful: 'Thank you, the document was successfully uploaded',
      uploadFailed:
        'Something went wrong and the document could not be uploaded:',
    },
    documents: {
      title: 'Documents',
      publications: 'Publications',
      documentName: 'Document Name',
      contacts: 'Contacts',
      category: 'Category',
      documentType: 'Document Type',
      documentDate: 'Date',
      nameAsc: 'Document Name (A-Z)',
      nameDesc: 'Document Name (Z-A)',
      dateNewestFirst: 'Date (newest first)',
      dateOldestFirst: 'Date (oldest first)',
      noDocumentsMessage: 'There are no documents to show at this time.',
      noResultsMessage:
        'There are no results that match your search criteria or filter settings.',
      selectedToDownload: 'selected for download',
      bulkDownloadZipFileName: 'Documents',
      preparingBulkDownload: 'We are preparing your documents for download',
      filters: 'Filters',
      filterByContacts: 'Filter by contact',
      filterByCategory: 'Filter by category',
      filterByType: 'Filter by type',
      filterByYear: 'Filter by year',
      filterByDocumentName: 'Filter by document name',
      closeFilters: 'Close filters',
      clearFilters: 'Reset filters',
      applyFilters: 'Apply filters',
      sortBy: 'Sort by',
      loadMore: 'Load More',
      loadingDocumentPreview: 'Loading document...',
    },
    idleTimer: {
      modalTitle: 'Logout du to inactivity',
      modalMessage:
        'You have been idle for a while and will be logged out in a moment.',
      logMeOut: 'Log me out',
      keepMeSignedIn: 'Keep me signed in',
    },
    forms: {
      alertTest: 'This is a test form and cannot be submitted.',
      alertErrorCreatingSigningRequest:
        'Sorry, an error occurred during processing. We will contact you with further information.',
      loaderCreatingSigningRequest: "You'll be redirected momentarily.",
      loaderSubmittingForm: 'Your answers are being submitted.',
      loaderGeneratingPdf: "One moment, we're processing your data.",
      processingFormAuthentication: 'Please wait, the page is loading.',
      processingFormAuthenticationCallback:
        'We are identifying you, thank you for waiting.',
      formSubmittedHeading: 'Form completed',
      formSubmittedText:
        'Thank you, you have successfully submitted your answers.',
      formInaccessibleHeading: 'Form inaccessible',
      formInaccessibleText:
        'Sorry, this form is no longer available online. Please contact us for assistance.',
      reload: 'Reload',
      smsSecurityCode: 'SMS Security Code',
      inOrderToAccess:
        'In order to access this form, you need an SMS security code which will be sent to your mobile phone number.',
      sendCode: 'Send code',
      sentSixDigitCode: 'We have sent you a six digit SMS security code.',
      enterBelow: 'Please enter it below:',
      accessBtnLabel: 'Access',
      didNotReceiveCode: 'Did you not receive the code?',
      requestCode: 'Request a new code',
      waitToRequestCode: 'Wait 60 seconds to request a new code.',
      inputValidatorText: 'Please enter the SMS security code',
      fieldHint: 'Enter your SMS code here',
      bankIdLoginWarningHeading: 'Log in to continue',
      bankIdLoginWarningText:
        'In order to access this content, please log in with an e-identity such as BankID.',
      tokenExpiredHeading: 'Link expired',
      tokenExpiredText:
        'Your access link to this form expired on {0}. Please contact us to receive a new link.',
      formSealedHeading: 'Locked',
      formSealedText:
        'This form has already been submitted and can no longer be changed.',
      pleaseCompleteForm: 'Please complete the form',
      chooseFormSubmissionTitle: 'How you want to proceed?',
      chooseFormSubmissionP1Text:
        'A version of this form was automatically saved.',
      chooseFormSubmissionP2Text:
        'Do you want to view the AutoSaved or a previously saved version?',
      tempButtonLabel: 'AutoSaved',
      savedButtonLabel: 'Previous version',
      noSubmissionName: 'Your form request',
      submissionCouldNotBeLoaded:
        'Something went wrong. The form could not be loaded.',
      otherUserWorkingOnSubmission:
        'The form is in use by someone else and is not available.',
      submissionSavedTitle: 'Form is saved',
      submissionSavedText:
        'The form submission is saved for later work. You can safely leave the page and navigate back when you want to continue.',
      saveForLater: 'Save',
      saveForLaterNote: 'If you want to continue later, you can press',
      returnHome: 'Return to the home page',
      formLockedByUser:
        'You cannot fill in this form at the moment because another user is currently working on it. Please try opening the form again in a couple of minutes.',
      formSaved:
        'Your changes have been saved successfully. You can close the form and continue editing it later.',
      formNotSaved:
        "We're sorry, something went wrong while saving your changes. Please try again.",
      formSubmitted: 'Thank you for submitting this form.',
      formSubmissionNot:
        "We're sorry, something went wrong and the form could not be",
      locked: 'locked',
      unlocked: 'unlocked',
      submitError: 'Something went wrong and the form could not be submitted:',
      autoSaveError:
        'Something went wrong and the form could not be saved automatically:',
      noFormData:
        'Due to missing data, the form cannot be displayed. Please contact us for support.',
      showSubmittedData: 'Show submitted data',
      hideSubmittedData: 'Hide submitted data',
      requiredAuthenticationInfoPageHeader: "We'll get back to you",
      requiredAuthenticationInfoPageText:
        "We'll get back to you in the next few days.",
    },
    workflows: {
      noWorkflowName: 'Your application process',
      workflowDescription:
        'In order to finalize this process, we need some more information from you. Please complete the following tasks.',
      accessedBy: 'This task can be accessed by:',
      noAttachmentsAdded: 'No attachments have been added yet.',
      addFirstDocument: 'Add the first document',
      addDocuments: 'Add more documents',
      reviewingSubmission:
        'We are reviewing your documentation and will contact you if further information is needed.',
      completedSubmission: 'Completed',
      completeBtnLabel: 'Complete this step',
      noActionAdded: 'No further action required.',
      completeActionSuccess: 'Thank you, the step was completed.',
      completeActionError: 'The step could not be completed:',
      unreadMessages: 'Unread message',
      readReceiptsError: 'The system could not mark the message as read:',
      messagesTitle: 'Messages',
      initializationText: 'Write your message…',
      placeholderText: 'Write your message…',
      anonymousAuthorName: 'Customer Service',
      workflowIdNotFound: 'The Workflow ID was not found in the API response',
    },
    inbox: {
      title: 'Inbox',
      newMessage: 'New Message',
      yourPendingActivities: 'Your pending activities',
      noPendingActivities: 'You have no pending activities.',
      yourMessages: 'Your messages',
      noMessages: 'You have no messages.',
      unreadMessage: 'Unread message',
      signBy: 'Please sign by',
      uploadBy: 'Please upload by',
      signatureRequired: 'Your signature is required',
      documentRequired: 'We require a document from you',
      completeForm: 'Please complete this form',
      ongoingApplicationProcess: 'Ongoing application process',
      uploadActionDetailsTitle: 'Document Upload',
      uploadActionDetailsText: 'We require the following document from you:',
      uploadActionDetailsNote:
        'On mobile, tap to select a file or take a picture. Only PDF documents and images (.jpg, .jpeg, .png) are allowed. The maximum file size for upload is 10MB.',
      uploadActionDetailsButtonLabel: 'Upload the document',
      backToInbox: 'Back to inbox',
      createNewMessage: 'New Message',
      send: 'Send',
      messageTopic: 'Choose a topic…',
      messageSubject: 'Write a subject for your message…',
      messageText: 'Write your message…',
      couldNotFoundContent: 'We could not find the content for this page.',
      taskDoesNotExist:
        'This task does not exist or is no longer available to you.',
      canSeeHistory: 'You can see a history of the messages on this task',
      conversationLinkLabel: 'here',
      warningUnavailableTask:
        'You can no longer actively participate in this conversation. If you want to write to us, please start a new conversation from the inbox.',
      warningConversationStatus:
        'This conversation has been archived and you can no longer add new messages. If you want to write to us, please start a new conversation from the inbox.',
    },
    portfolios: {
      title: 'Portfolios',
      tabAllocationTitle: 'Allocation',
      tabHoldingsTitle: 'Holdings',
      tabPerformanceTitle: 'Performance',
      tabIndexTitle: 'Index',
      tableLinkLabel: 'Open link',
      tableAllocationTitle: 'Allocation',
      tableHoldingsTitle: 'Holdings',
      tablePerformanceTitle: 'Performance',
      tablePerformanceTwoTitle: 'Performance (%)',
      tableIndexTitle: 'Index',
      tableAllocationFooterRowTitle: 'Total',
      tableHoldingsFooterRowTitle: 'Total',
      tablePerformanceFooterRowTitle: 'Total',
      tablePerformanceTwoFooterRowTitle: 'Total',
      graphPerformanceDatepickerStartLabel: 'Start',
      graphPerformanceDatepickerEndLabel: 'End',
      graphPerformanceValueAxisTitle: 'Return (Indexed)',
      lastUpdatedTxt:
        'Last updated on {0:d} with last available prices at {1:d}.',
      lackOfUpdatesTxt:
        'The lack of updates is due to transactions in process.',
      disclaimerText: 'We reserve the right to any errors in the report.',
      userWithoutPortfoliosText:
        'We cannot find any active portfolios linked to your account at this time. Portfolio reports may be temporarily unavailable while data is being updated. Please try again later or contact us for further assistance.',
      accountWithoutPortfoliosText:
        'We cannot find any active portfolios linked to this account at this time. Portfolio reports may be temporarily unavailable while data is being updated. Please try again later or contact us for further assistance.',
      userPortfoliosError:
        'This portfolio is temporarily unavailable. Please try again later.',
      holdingsError: 'The data could not be loaded. Please try again later.',
      timeSeriesError:
        'The visualisation could not be loaded. Please try again later.',
      indicesError: 'The data could not be loaded. Please try again later.',
      allocationTableColumns: {
        recordId: 'Record Id',
        parentId: 'Parent Id',
        reportLevel: 'Report Level',
        sortOrder: 'Sort Order',
        isIndex: 'Is Index',
        currentHolding: 'Current Holding',
        contactGuid: 'Contact Guid',
        accountId: 'Account Id',
        portfolioId: 'Portfolio Id',
        portfolioName: 'Portfolio Name',
        title: 'Asset class',
        securitySymbol: 'Symbol',
        isin: 'ISIN',
        sfdr: 'SFDR',
        portfolioBaseTypeCode: 'Base Code',
        securityTypeCode: 'Security Code',
        allocation: 'Allocation',
        costBasis: 'Cost Basis',
        fifoCost: 'FIFO Cost',
        indexWeight: 'Strategic weight',
        lowerLimit: 'Lower Limit',
        upperLimit: 'Upper Limit',
        movement: 'Movement',
        quantity: 'Quantity',
        unrealizedGainLoss: 'Unrealized Gain/Loss',
        itdAnnualizedTwr: 'TWR since Start',
        itdFromDate: 'Start Date',
        oneYearTwr: '1yr TWR',
        oneYearTotalGain: '1yr Total Gain',
        oneYearFromDate: '1yr Date',
        threeYearTotalGain: '3yr TWR',
        threeYearAnnualizedTwr: '3yr Total Gain',
        threeYearFromDate: '3yr Date',
        fiveYearAnnualizedTwr: '5yr TWR',
        fiveYearTotalGain: '5yr Total Gain',
        fiveYearFromDate: '5yr Date',
        ytdTotalGain: 'Total Gain YTD',
        ytdTwr: 'TWR YTD',
        ytdFromDate: 'YTD Date',
        mtdTotalGain: 'Performance this month',
        mtdTwr: 'TWR MTD',
        mtdFromDate: 'MTD Date',
        periodEndMarketValue: 'Market value',
        periodEndRelativeMarketValue: 'Relative MKV',
        periodEndTotalGain: 'Total Gain',
        periodEndWeight: 'Portfolio weight',
        periodEndDate: 'Period End Date',
        sinceInceptionTotalGain: 'Total Gain',
        sinceInceptionAnnualizedTwr: 'Annualized Twr',
        sinceInceptionDate: 'Start Date',
        thruDate: 'Thru Date',
        date1: 'Date 1',
        date2: 'Date 2',
        date3: 'Date 3',
        date4: 'Date 4',
        date5: 'Date 5',
        double1: 'Double 1',
        double2: 'Double 2',
        double3: 'Double 3',
        double4: 'double 4',
        double5: 'Double 5',
        int1: 'Int 1',
        int2: 'Int 2',
        int3: 'Int 3',
        int4: 'Int 4',
        int5: 'Int 5',
        string1: 'Degrees of freedom',
        string2: 'String 2',
        string3: 'String 3',
        string4: 'String 4',
        string5: 'Link',
      },
      holdingsTableColumns: {
        recordId: 'Record Id',
        parentId: 'Parent Id',
        reportLevel: 'Report Level',
        sortOrder: 'Sort Order',
        isIndex: 'Is Index',
        currentHolding: 'Current Holding',
        contactGuid: 'Contact Guid',
        accountId: 'Account Id',
        portfolioId: 'Portfolio Id',
        portfolioName: 'Portfolio Name',
        title: 'Holdings',
        securitySymbol: 'Symbol',
        isin: 'ISIN',
        sfdr: 'SFDR',
        portfolioBaseTypeCode: 'Base Code',
        securityTypeCode: 'Security Code',
        allocation: 'Allocation',
        costBasis: 'Cost basis',
        fifoCost: 'Cost price',
        indexWeight: 'Strategic weight',
        lowerLimit: 'Lower Limit',
        upperLimit: 'Upper Limit',
        movement: 'Movement',
        quantity: 'Quantity',
        unrealizedGainLoss: 'Unrealized gain/loss',
        itdAnnualizedTwr: 'TWR since Start',
        itdFromDate: 'Start Date',
        oneYearTwr: '1yr TWR',
        oneYearTotalGain: '1yr Total Gain',
        oneYearFromDate: '1yr Date',
        threeYearTotalGain: '3yr TWR',
        threeYearAnnualizedTwr: '3yr Total Gain',
        threeYearFromDate: '3yr Date',
        fiveYearAnnualizedTwr: '5yr TWR',
        fiveYearTotalGain: '5yr Total Gain',
        fiveYearFromDate: '5yr Date',
        ytdTotalGain: 'Total Gain YTD',
        ytdTwr: 'TWR YTD',
        ytdFromDate: 'YTD Date',
        mtdTotalGain: 'Total Gain MTD',
        mtdTwr: 'TWR MTD',
        mtdFromDate: 'MTD Date',
        periodEndMarketValue: 'Market value',
        periodEndRelativeMarketValue: 'Relative MKV',
        periodEndTotalGain: 'Total Gain',
        periodEndWeight: 'Allocation',
        periodEndDate: 'Period End Date',
        sinceInceptionTotalGain: 'Total Gain',
        sinceInceptionAnnualizedTwr: 'Annualized Twr',
        sinceInceptionDate: 'Start Date',
        thruDate: 'Thru Date',
        date1: 'Last price date',
        date2: 'Invested date',
        date3: 'Date 3',
        date4: 'Date 4',
        date5: 'Date 5',
        double1: 'Market price',
        double2: 'Double 2',
        double3: 'Double 3',
        double4: 'double 4',
        double5: 'Double 5',
        int1: 'Int 1',
        int2: 'Int 2',
        int3: 'Int 3',
        int4: 'Int 4',
        int5: 'Int 5',
        string1: 'String 1',
        string2: 'String 2',
        string3: 'String 3',
        string4: 'String 4',
        string5: 'Link',
      },
      performanceTableColumns: {
        recordId: 'Record Id',
        parentId: 'Parent Id',
        reportLevel: 'Report Level',
        sortOrder: 'Sort Order',
        isIndex: 'Is Index',
        currentHolding: 'Current Holding',
        contactGuid: 'Contact Guid',
        accountId: 'Account Id',
        portfolioId: 'Portfolio Id',
        portfolioName: 'Portfolio Name',
        title: 'Performance (NOK)',
        securitySymbol: 'Symbol',
        isin: 'ISIN',
        sfdr: 'SFDR',
        portfolioBaseTypeCode: 'Base Code',
        securityTypeCode: 'Security Code',
        allocation: 'Allocation',
        costBasis: 'Cost Basis',
        fifoCost: 'FIFO Cost',
        indexWeight: 'Strategic weight',
        lowerLimit: 'Lower Limit',
        upperLimit: 'Upper Limit',
        movement: 'Movement',
        quantity: 'Quantity',
        unrealizedGainLoss: 'Unrealized. Gain/Loss',
        itdAnnualizedTwr: 'TWR since Start',
        itdFromDate: 'Start Date',
        oneYearTwr: '1yr TWR',
        oneYearTotalGain: '1yr Total Gain',
        oneYearFromDate: '1yr Date',
        threeYearTotalGain: 'Last 3 years',
        threeYearAnnualizedTwr: '3yr TWR',
        threeYearFromDate: '3yr Date',
        fiveYearAnnualizedTwr: '5yr TWR',
        fiveYearTotalGain: 'Last 5 years',
        fiveYearFromDate: '5yr Date',
        ytdTotalGain: 'So far this year',
        ytdTwr: 'TWR YTD',
        ytdFromDate: 'YTD Date',
        mtdTotalGain: 'This month',
        mtdTwr: 'TWR MTD',
        mtdFromDate: 'This month',
        periodEndMarketValue: 'Market value',
        periodEndRelativeMarketValue: 'Relative MKV',
        periodEndTotalGain: 'Total Gain',
        periodEndWeight: 'Portfolio Weight',
        periodEndDate: 'Period End Date',
        sinceInceptionTotalGain: 'Since inception',
        sinceInceptionAnnualizedTwr: 'Annualized TWR',
        sinceInceptionDate: 'Start Date',
        thruDate: 'Thru Date',
        date1: 'Date 1',
        date2: 'Date 2',
        date3: 'Date 3',
        date4: 'Date 4',
        date5: 'Date 5',
        double1: 'Double 1',
        double2: 'Double 2',
        double3: 'Double 3',
        double4: 'double 4',
        double5: 'Double 5',
        int1: 'Int 1',
        int2: 'Int 2',
        int3: 'Int 3',
        int4: 'Int 4',
        int5: 'Int 5',
        string1: 'String 1',
        string2: 'String 2',
        string3: 'String 3',
        string4: 'String 4',
        string5: 'Doc.',
      },
      performanceTableTwoColumns: {
        recordId: 'Record Id',
        parentId: 'Parent Id',
        reportLevel: 'Report Level',
        sortOrder: 'Sort Order',
        isIndex: 'Is Index',
        currentHolding: 'Current Holding',
        contactGuid: 'Contact Guid',
        accountId: 'Account Id',
        portfolioId: 'Portfolio Id',
        portfolioName: 'Portfolio Name',
        title: 'Performance (%)',
        securitySymbol: 'Symbol',
        isin: 'ISIN',
        sfdr: 'SFDR',
        portfolioBaseTypeCode: 'Base Code',
        securityTypeCode: 'Security Code',
        allocation: 'Allocation',
        costBasis: 'Cost Basis',
        fifoCost: 'FIFO Cost',
        indexWeight: 'Strategic weight',
        lowerLimit: 'Lower Limit',
        upperLimit: 'Upper Limit',
        movement: 'Movement',
        quantity: 'Quantity',
        unrealizedGainLoss: 'Unrealized. Gain/Loss',
        itdAnnualizedTwr: 'TWR since Start',
        itdFromDate: 'Start Date',
        oneYearTwr: '1yr TWR',
        oneYearTotalGain: '1yr Total Gain',
        oneYearFromDate: '1yr Date',
        threeYearTotalGain: '3yr Total Gain',
        threeYearAnnualizedTwr: 'Last 3 years',
        threeYearFromDate: '3yr Date',
        fiveYearAnnualizedTwr: 'Last 5 years',
        fiveYearTotalGain: '5yr Total Gain',
        fiveYearFromDate: '5yr Date',
        ytdTotalGain: 'Total Gain YTD',
        ytdTwr: 'So far this year',
        ytdFromDate: 'YTD Date',
        mtdTotalGain: 'Total Gain MTD',
        mtdTwr: 'This month',
        mtdFromDate: 'MTD Date',
        periodEndMarketValue: 'Market value',
        periodEndRelativeMarketValue: 'Relative MKV',
        periodEndTotalGain: 'Total Gain',
        periodEndWeight: 'Portfolio Weight',
        periodEndDate: 'Period End Date',
        sinceInceptionTotalGain: 'Total Gain',
        sinceInceptionAnnualizedTwr: 'Since inception ann.',
        sinceInceptionDate: 'Start Date',
        thruDate: 'Thru Date',
        date1: 'Date 1',
        date2: 'Date 2',
        date3: 'Date 3',
        date4: 'Date 4',
        date5: 'Date 5',
        double1: 'Double 1',
        double2: 'Double 2',
        double3: 'Double 3',
        double4: 'double 4',
        double5: 'Double 5',
        int1: 'Int 1',
        int2: 'Int 2',
        int3: 'Int 3',
        int4: 'Int 4',
        int5: 'Int 5',
        string1: 'String 1',
        string2: 'String 2',
        string3: 'String 3',
        string4: 'String 4',
        string5: 'Link',
      },
      indexTableColumns: {
        recordId: 'Record Id',
        parentId: 'Parent Id',
        reportLevel: 'Report Level',
        sortOrder: 'Sort Order',
        indexGroup: 'Index Group',
        title: 'Indices, performance in NOK',
        annualTwr: 'So far this year',
        oneYearTwr: 'Last year',
        threeYearTwr: 'Last 3 years',
        fiveYearTwr: 'Last 5 years',
        yield: 'Yield',
        lastPrice: 'Price',
        lastPriceDate: 'Last price date',
        date1: 'Date 1',
        date2: 'Date 2',
        double1: 'Double 1',
        double2: 'Double 2',
        double3: 'Double 3',
        double4: 'Double 4',
        double5: 'Double 5',
        int1: 'Int 1',
        int2: 'Int 2',
        string1: 'String 1',
        string2: 'String 2',
      },
    },
    errors: {
      settingsError:
        'There was an error. Please return to the homepage and try again.',
      invalidStatus:
        'There was an error. Please return to the homepage and try again.',
      shouldNotRedirect:
        'Sorry, we could not authenticate you. Please return to the homepage and try to log in again.',
      invalidAuth:
        'Sorry, we could not authenticate you. Please return to the homepage and try to log in again.',
      invalidAction:
        'Sorry, this action is not permitted. Please return to the homepage and try again.',
      invalidReturnUrl:
        'Sorry, we could not authenticate you. Please return to the homepage and try to log in again.',
      getAccessTokenError:
        'Sorry, we could not authenticate you. Please return to the homepage and try to log in again.',
      silentAuthError:
        'Sorry, we could not authenticate you. Please return to the homepage and try to log in again.',
      silentTokenError:
        "We're sorry, the page could not be loaded correctly. Please try refreshing the page or logging in again.",
    },
  },
};
