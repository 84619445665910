import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import envVars from '../../resources/envVars';
import useAccountBasedSignatures from '../../hooks/useAccountBasedSignatures';
import SignatureRequest from './SignatureRequest';
import { setAlerts } from '../../app/slices/alertSlice';
import {
  fetchSignatureRequests,
  selectDigitalIdentity,
  selectSignaturesFetched,
  DigitalIdentityProps,
} from './signaturesSlice';
import { useTranslation } from '../../hooks/useTranslation';

type SignatureRequestListProps = {};

const SignatureRequestList = (props: SignatureRequestListProps) => {
  const dispatch = useAppDispatch();

  const signaturesFetched = useAppSelector(selectSignaturesFetched);
  const API_BASE_URI: string = envVars.API_BASE_URI || '';

  const digitalIdentity: DigitalIdentityProps | null = useAppSelector(
    selectDigitalIdentity,
  );
  const nin = digitalIdentity?.nin;
  const idp = digitalIdentity?.idp;
  const isUserMissingNinOrIdp = !nin || !idp?.includes('bankid');

  const ns = 'construo.signatureRequests';
  const translations = {
    userMissingNinAndBankId: useTranslation(`${ns}.userMissingNinAndBankId`),
    userMissingNinAndLocal: useTranslation(`${ns}.userMissingNinAndLocal`),
  };

  useEffect(() => {
    /**
     * The tricky behavior of useEffect hook in React 18
     * https://medium.com/geekculture/the-tricky-behavior-of-useeffect-hook-in-react-18-282ef4fb570a
     * let ignore
     */
    let ignore = false;
    if (signaturesFetched === null && !isUserMissingNinOrIdp) {
      const dataObject = {
        baseUrl: API_BASE_URI,
      };
      setTimeout(() => {
        if (!ignore) {
          dispatch(fetchSignatureRequests(dataObject));
        }
      }, 0);
    } else if (signaturesFetched === null && isUserMissingNinOrIdp) {
      const isUserMissingNinAndBankId = !nin && idp?.includes('bankid');
      const isUserMissingNinAndLocal = !nin && idp === 'local';
      let message;
      const isMessageRead = sessionStorage.getItem('isMessageRead') === 'true';
      if (isUserMissingNinAndBankId) {
        message = translations.userMissingNinAndBankId;
      } else if (isUserMissingNinAndLocal) {
        message = translations.userMissingNinAndLocal;
      }
      if (!!message && !isMessageRead) {
        sessionStorage.setItem('isMessageRead', 'false');
        dispatch(
          setAlerts({ message: message, type: 'info', isMissingNin: true }),
        );
      }
    }
    return () => {
      ignore = true;
    };
  }, [
    dispatch,
    signaturesFetched,
    API_BASE_URI,
    idp,
    nin,
    isUserMissingNinOrIdp,
    translations.userMissingNinAndBankId,
    translations.userMissingNinAndLocal,
  ]);

  /**
   * Account Based filtering of E-sign Requests
   */
  const accountBasedData = useAccountBasedSignatures();

  return (
    <>
      {accountBasedData.map((signature: any) => {
        return <SignatureRequest key={signature.id} signature={signature} />;
      })}
    </>
  );
};

export default SignatureRequestList;
