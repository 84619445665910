import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useTranslation } from '../../hooks/useTranslation';

const FormInaccessible = () => {
  const ns = 'construo.forms';
  const translations = {
    formInaccessibleHeading: useTranslation(`${ns}.formInaccessibleHeading`),
    formInaccessibleText: useTranslation(`${ns}.formInaccessibleText`),
    reload: useTranslation(`${ns}.reload`),
  };

  return (
    <>
      <h1>{translations.formInaccessibleHeading}</h1>
      <p>{translations.formInaccessibleText}</p>
      <Button themeColor='primary' onClick={() => window.location.reload()}>
        {translations.reload}
      </Button>
    </>
  );
};

export default FormInaccessible;
