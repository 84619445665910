import React from 'react';
import { Skeleton } from '@progress/kendo-react-indicators';

export const DocumentListSkeleton = () => {
  const item = (
    <div className='list-item'>
      <div className='list-item-checkbox'>
        <Skeleton shape={'rectangle'} style={{ width: 15, height: 15 }} />
      </div>
      <div className='list-item-document'>
        <div className='list-item-document-name' style={{ minWidth: '50%' }}>
          <Skeleton shape={'text'} style={{ maxWidth: 150 }} />
          <Skeleton shape={'text'} style={{ height: 40 }} />
          <div className='list-item-document-contacts'>
            <Skeleton shape={'text'} style={{ width: '50%' }} />
          </div>
        </div>
        <div className='list-item-date'>
          <Skeleton shape={'text'} style={{ width: 100 }} />
        </div>
      </div>
    </div>
  );

  return (
    <div className='k-listview documents-list'>
      {item}
      {item}
      {item}
    </div>
  );
};

export default DocumentListSkeleton;
