import { useEffect, useState } from 'react';
import { useAppSelector } from './reduxHooks';
import {
  selectHasRepresentations,
  selectSelectedAccount,
} from '../components/api-authorization/representationSlice';
import { selectUser } from '../components/api-authorization/userSlice';
import {
  selectSignatures,
  selectSignaturesFetched,
} from '../components/signatures/signaturesSlice';

const useAccountBasedSignatures = () => {
  const signaturesFetched = useAppSelector(selectSignaturesFetched);
  const selectedAccount = useAppSelector(selectSelectedAccount);
  const userState = useAppSelector(selectUser);
  const hasRepresentations = useAppSelector(selectHasRepresentations);
  const signatures = useAppSelector(selectSignatures);

  const [accountBasedSignatures, setAccountBasedSignatures] = useState<
    object[]
  >([]);

  useEffect(() => {
    const selectedContactGuid = selectedAccount?.contactGuid;
    const isLoggedInUser = userState.contactGuid === selectedContactGuid;
    if (
      signaturesFetched &&
      hasRepresentations &&
      signatures.length > 0 &&
      !!selectedContactGuid
    ) {
      if (isLoggedInUser) {
        // NO NEED TO FILTER Signature Requests by account contact GUID
        setAccountBasedSignatures(signatures);
      } else {
        // NEED TO remove Signature Requests by account contact GUID
        setAccountBasedSignatures([]);
      }
    } else if (!hasRepresentations && signatures.length > 0) {
      // NO NEED TO FILTER Signature Requests by account contact GUID
      setAccountBasedSignatures(signatures);
    }
  }, [
    signatures,
    hasRepresentations,
    selectedAccount?.contactGuid,
    signaturesFetched,
    userState.contactGuid,
  ]);

  return accountBasedSignatures;
};

export default useAccountBasedSignatures;
