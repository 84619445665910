import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import authService, { AuthenticationResultStatus } from './AuthorizeService';
import {
  QueryParameterNames,
  LogoutActions,
  ApplicationPaths,
} from './ApiAuthorizationConstants';
import { showMessage, removeUser, selectUser } from './userSlice';
import { Link } from 'react-router-dom';
import { navigateToReturnUrl } from '../../helpers/utils';
import OverallLoader from '../common/OverallLoader';
import LanguageSwitcher from '../common/LanguageSwitcher';
import { Card, CardBody, CardSubtitle } from '@progress/kendo-react-layout';
import Layout from '../layout/Layout';
import { useTranslation } from '../../hooks/useTranslation';

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the Global Menu (Navigation component).
interface Props {
  action: string;
}

export const Logout = ({ action }: Props) => {
  const dispatch = useAppDispatch();
  const userState = useAppSelector(selectUser);

  const loginURL = ApplicationPaths.Login;

  const ns = 'construo.errors';
  const translations = {
    logBackIn: useTranslation('construo.global.logBackIn'),
    processingLogout: useTranslation('construo.global.processingLogout'),
    processingLogoutCallback: useTranslation(
      'construo.global.processingLogoutCallback',
    ),
    loggedOutHeading: useTranslation('construo.global.loggedOutHeading'),
    loggedOutSubHeading: useTranslation('construo.global.loggedOutSubHeading'),
    invalidAuth: useTranslation(`${ns}.invalidAuth`),
    shouldNotRedirect: useTranslation(`${ns}.shouldNotRedirect`),
    invalidAction: useTranslation(`${ns}.invalidAction`),
    invalidReturnUrl: useTranslation(`${ns}.invalidReturnUrl`),
  };

  useEffect(() => {
    const logout = async (returnUrl: string): Promise<void> => {
      if (userState.isLoggedIn) {
        const result = await authService.signOut();
        switch (result.status) {
          case AuthenticationResultStatus.Redirect:
            break;
          case AuthenticationResultStatus.Success:
            dispatch(removeUser());
            navigateToReturnUrl(returnUrl);
            break;
          case AuthenticationResultStatus.Fail:
            dispatch(showMessage(result.message));
            break;
          default:
            throw new Error(translations.invalidAuth);
        }
      } else {
        dispatch(showMessage(translations.loggedOutHeading));
      }
    };
    const getReturnUrl = (state?: any) => {
      const params = new URLSearchParams(window.location.search);
      const fromQuery = params.get(QueryParameterNames.ReturnUrl);
      if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
        // This is an extra check to prevent open redirects.
        throw new Error(translations.invalidReturnUrl);
      }
      return (
        (state && state.returnUrl) ||
        fromQuery ||
        `${window.location.origin}${ApplicationPaths.LoggedOut}`
      );
    };

    const processLogoutCallback = async (): Promise<void> => {
      /**
       * Removing any sessionStorage item starting with authCustomerResponse_
       */
      Object.keys(sessionStorage)
        .filter(item => item.startsWith('authCustomerResponse_'))
        .forEach(item => sessionStorage.removeItem(item));

      sessionStorage.removeItem('authUrl');

      if (userState.isLoggedIn) {
        const url = window.location.href;
        const result = await authService.completeSignOut(url);
        switch (result.status) {
          case AuthenticationResultStatus.Redirect:
            // There should not be any redirects as the only time completeAuthentication finishes
            // is when we are doing a redirect sign in flow.
            throw new Error(translations.shouldNotRedirect);
          case AuthenticationResultStatus.Success:
            dispatch(removeUser());
            navigateToReturnUrl(getReturnUrl(result.state));
            break;
          case AuthenticationResultStatus.Fail:
            dispatch(showMessage(result.message));
            break;
          default:
            throw new Error(translations.invalidAuth);
        }
      } else {
        navigateToReturnUrl(getReturnUrl(null));
      }
    };

    switch (action) {
      case LogoutActions.Logout:
        logout(getReturnUrl());
        break;
      case LogoutActions.LogoutCallback:
        processLogoutCallback();
        break;
      case LogoutActions.LoggedOut:
        dispatch(showMessage(translations.loggedOutHeading));
        break;
      default:
        throw new Error(`${translations.invalidAction} '${action}'`);
    }
    return () => {
      dispatch(showMessage(''));
    };
  }, [
    action,
    dispatch,
    userState.isLoggedIn,
    userState.username,
    translations.invalidAction,
    translations.invalidAuth,
    translations.invalidReturnUrl,
    translations.shouldNotRedirect,
    translations.loggedOutHeading,
  ]);

  const LogoutScreen = () => {
    return (
      <>
        <Layout isSplashLayout={true} layoutClassName='logged-out-layout'>
          <div className='main-section logout'>
            <div className='container'>
              <Card>
                <CardBody>
                  <h1 className='k-card-title'>{userState.message}</h1>
                  <CardSubtitle>
                    {translations.loggedOutSubHeading}
                  </CardSubtitle>
                  <Link className='login-link' to={loginURL}>
                    {translations.logBackIn}
                  </Link>
                </CardBody>
              </Card>
              <LanguageSwitcher />
            </div>
          </div>
        </Layout>
      </>
    );
  };

  if (!!userState.message) {
    return <LogoutScreen />;
  } else {
    switch (action) {
      case LogoutActions.Logout:
        return <OverallLoader message={translations.processingLogout} />;
      case LogoutActions.LogoutCallback:
        return (
          <OverallLoader message={translations.processingLogoutCallback} />
        );
      case LogoutActions.LoggedOut:
        // Removing selectedAccount from session storage on LoggedOut action
        sessionStorage.removeItem('selectedAccount');
        localStorage.removeItem('selectedAccountContactGuid');

        return <LogoutScreen />;
      default:
        throw new Error(`${translations.invalidAction} '${action}'`);
    }
  }
};
