import React from 'react';
import { Holding } from '../pages/portfolios/types';
import { useConfig } from '../../hooks/useConfig';
import { useTranslation } from '../../hooks/useTranslation';

export interface PortfoliosLastUpdatedInfoProps {
  portfolio?: Holding;
  lastUpdatedInfoPlaceholders?: string[];
}

const PortfoliosLastUpdatedInfo = ({
  portfolio,
  lastUpdatedInfoPlaceholders: lastUpdatedInfoPlaceholdersProp,
}: PortfoliosLastUpdatedInfoProps) => {
  const config = useConfig();

  const lastUpdatedInfoPlaceholders =
    lastUpdatedInfoPlaceholdersProp === undefined
      ? config.portfolios.lastUpdatedInfoPlaceholders
      : lastUpdatedInfoPlaceholdersProp;

  const lastUpdatedTxtArgs = lastUpdatedInfoPlaceholders.map(
    (key: keyof Holding) => {
      const date = portfolio && portfolio[key];
      const isStringValidDate = !isNaN(Date.parse(date as string));
      return isStringValidDate ? new Date(date as Date) : date;
    },
  );

  const lastUpdatedTxt = useTranslation(
    `construo.portfolios.lastUpdatedTxt`,
    lastUpdatedTxtArgs,
  );

  const lackOfUpdatesTxt = useTranslation(
    'construo.portfolios.lackOfUpdatesTxt',
  );
  const isSecondSentenceVisible = portfolio?.int1 === 1; // If property "int1" is defined and equal to 1 show second sentence (lackOfUpdatesTxt) after lastUpdatedTxt
  const lackOfUpdates = isSecondSentenceVisible ? lackOfUpdatesTxt : '';

  return (
    <>
      {!!portfolio && (
        <p className='portfolios-last-updated-text'>
          {lastUpdatedTxt} {lackOfUpdates}
        </p>
      )}
    </>
  );
};

export default PortfoliosLastUpdatedInfo;
