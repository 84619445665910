import { TreeListColumnProps } from '@progress/kendo-react-treelist';
import { AxiosRequestConfig } from 'axios';
import { Holding } from '../components/pages/portfolios/types';
import { IAccount } from '../components/pages/select-account/SelectAccount';
import envVars from '../resources/envVars';

/**
 * Filter array of string - remove all duplicates
 * @param array
 * @returns
 */
function arrayUnique(array: Holding[]) {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }
  return a;
}

export const navigateToReturnUrl = (returnUrl: string) => {
  // It's important that we do a replace here so that we remove the callback uri with the
  // fragment containing the tokens from the browser history.
  window.location.replace(returnUrl);
};

/**
 * Get URL param by param name
 * @param param
 * @returns
 */
export const getSearchParam = (param: string) => {
  const urlString = window.location.href;
  const url = new URL(urlString);
  const eSignStatus = url.searchParams.get(param);
  return eSignStatus;
};

/**
 * Make object comparable - replace all values with empty string while keeping nested structure
 * @param obj
 */
export const getKeys = (obj: { [key: string]: any }) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] !== null && typeof obj[key] === 'object') {
      getKeys(obj[key]);
    } else {
      obj[key] = '';
    }
  });
  return obj;
};

/**
 * Axios Config - get config object for axios calls
 */
export const getAxiosConfig = (
  token: string | null | undefined,
  responseType: any,
) => {
  const config: AxiosRequestConfig = {
    headers: !token
      ? {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
      : {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
    responseType: responseType,
  };
  return config;
};

/**
 * Get filtered list of assigned documents
 * @param items
 * @returns
 */
export const getOnlyAssignedDocuments = (items: any[]) => {
  const assignedOnly = items.filter((item: any) => item.Contacts !== undefined);
  return assignedOnly;
};

/**
 * Get filtered list of public documents
 * @param items
 * @returns
 */
export const getOnlyPublicDocuments = (items: any[]) => {
  const publicOnly = items.filter((item: any) => item.Contacts === undefined);
  return publicOnly;
};

/**
 * Get filtered list of unique contacts filtered by it's own account contact GUID
 * @param items
 * @param accounts
 * @param selectedGuid
 * @returns
 */
export const getFilteredContactsByOwnContactGuid = (
  items: any[],
  accounts: any[] | null,
  selectedGuid: string | undefined,
) => {
  let filteredItems: any[] = [];
  // Remove items that belong to other accounts (other than logged in user when logged in user is selected account)
  const otherAccounts = accounts?.filter(
    (account: any) => account.contactGuid !== selectedGuid,
  );
  const otherGUIDs = otherAccounts?.map((account: any) => account.contactGuid);
  filteredItems = items?.filter((item: any) => {
    const contactGUIDs = item?.Contacts?.map(
      (contact: any) => contact?.ContactGuid,
    );
    const contactGUIDsMatch: any[] | undefined = otherGUIDs?.filter(
      (otherGUID: string) =>
        // remove other that user represents but don't remove ones that are assigned to the user
        contactGUIDs?.includes(otherGUID) &&
        !contactGUIDs?.includes(selectedGuid),
    );
    const matchWithOtherGUIDs =
      !!contactGUIDsMatch && contactGUIDsMatch?.length > 0;
    return !matchWithOtherGUIDs;
  });
  return filteredItems;
};

/**
 * Get filtered list of Documents or Document Upload Requests filtered by account contact GUID
 * @param items
 * @param accounts
 * @param selectedGuid
 * @param isLoggedInUser
 * @returns
 */
export const getItemsFilteredByAccountContactGuid = (
  items: any[],
  accounts: any[] | null,
  selectedGuid: string,
  isLoggedInUser = false,
) => {
  let filteredItems: any[] = [];
  if (isLoggedInUser) {
    // Remove items that belong to other accounts (other than logged in user when logged in user is selected account) and
    // remove those that are public (unassigned - not assigned to contact)

    const otherAccounts = accounts?.filter(
      (account: any) => account.contactGuid !== selectedGuid,
    );
    const otherGUIDs = otherAccounts?.map(
      (account: any) => account.contactGuid,
    );
    filteredItems = items?.filter((item: any) => {
      const contactGUIDs = item?.Contacts?.map(
        (contact: any) => contact?.ContactGuid,
      );

      const contactGUIDsMatch: any[] | undefined = otherGUIDs?.filter(
        (otherGUID: string) =>
          // remove other that user represents but don't remove ones that are assigned to the user
          contactGUIDs?.includes(otherGUID) &&
          !contactGUIDs?.includes(selectedGuid),
      );
      const matchWithOtherGUIDs =
        !!contactGUIDsMatch && contactGUIDsMatch?.length > 0;

      const itemContacts = item.Contacts;
      const isPublicDocument = itemContacts === undefined;

      return !matchWithOtherGUIDs && !isPublicDocument;
    });
  } else {
    // Filter out items that match selected contact guid
    filteredItems = items?.filter((item: any) => {
      let includesContactGuid;
      includesContactGuid = item.Contacts?.filter(
        (contact: any) => contact.ContactGuid === selectedGuid,
      );
      return (
        Array.isArray(includesContactGuid) && includesContactGuid?.length > 0
      );
    });
  }
  const filteredByAccount = filteredItems;
  return filteredByAccount;
};

/**
 * Get filtered list of Submission Requests filtered by account contact GUID
 */
export const getSubmissionsFilteredByAccountContactGuid = (
  items: object[],
  allButMyselfGUIDs: string[] | undefined,
  selectedGuid: string,
  isLoggedInUser: boolean,
) => {
  let isMatch: boolean;
  const filteredByAccount = items?.filter((item: any) => {
    if (isLoggedInUser) {
      isMatch =
        item.ContactGuid === selectedGuid ||
        (!allButMyselfGUIDs?.includes(item.ContactGuid) &&
          item.ContactGuid !== selectedGuid);
    } else {
      isMatch = item.ContactGuid === selectedGuid;
    }
    return isMatch;
  });
  return filteredByAccount;
};

/**
 * Get sorted account list, companies accounts first persons accounts second and personal account last
 * @param data
 * @returns
 */
export const getSortedAccountsList = (data: IAccount[] | null) => {
  // Sort data by name property ASCENDING (A - Z)
  const dataAscending =
    data !== null &&
    [...data].sort((a: any, b: any): number => (a.name > b.name ? 1 : -1));
  // Sort dataAscending by type property companies first individuals second
  const companies =
    !!dataAscending &&
    dataAscending?.filter((object: any) => object.type === 2);
  const persons =
    !!dataAscending &&
    dataAscending?.filter((object: any) => object.type === 1);
  const dataSorted = !!companies && !!persons && companies.concat(persons);
  const accountsSorted = dataSorted === false ? [] : dataSorted;
  return accountsSorted;
};

/**
 * Get filtered list of Workflows filtered by account contact GUID
 */
export const getWorkflowsFilteredByAccountContactGuid = (
  items: object[],
  allButMyselfGUIDs: string[] | undefined,
  selectedGuid: string,
  isLoggedInUser: boolean,
) => {
  let isMatch: boolean;
  const filteredByAccount = items?.filter((item: any) => {
    if (isLoggedInUser) {
      isMatch =
        item.contactGuid === selectedGuid ||
        (!allButMyselfGUIDs?.includes(item.contactGuid) &&
          item.contactGuid !== selectedGuid);
    } else {
      isMatch = item.contactGuid === selectedGuid;
    }
    return isMatch;
  });
  return filteredByAccount;
};

export const getDefaultPortfolio = (
  isMatch: boolean,
  portfolios: Holding[],
  portfolioId: string | null,
) => {
  // Find portfolio by portfolioId
  const matchPortfolio = portfolios.filter(
    (portfolio: any) => portfolio.portfolioId === portfolioId,
  )[0];

  const portfolio = isMatch ? matchPortfolio : portfolios[0];
  if (!isMatch) {
    window.history.replaceState(
      null,
      '',
      `portfolios/${portfolio.portfolioId}`,
    );
  }
  return portfolio;
};

export const getPortfoliosDataObject = (
  selectedContactGuid: string | boolean | null,
  url: string,
  columns: TreeListColumnProps[],
  configFieldsArray: string[],
) => {
  const configFieldsColumnsArray = columns.map(
    (column: TreeListColumnProps) => {
      return column.field;
    },
  );
  let configFields: any[] | string =
    configFieldsColumnsArray.concat(configFieldsArray);
  configFields = arrayUnique(configFields);
  configFields = configFields.join('\n ');

  const graphqlQuery = {
    query: `query Holdings {
      holdings (
        where: {
          and: [
            {reportLevel: {eq: 0}},
            {isIndex: {eq: false}}
          ]
        },
        order: {sortOrder: ASC}
      ) {
          contactGuid
          sortOrder
          portfolioId
          mtdTotalGain
          ytdTotalGain
          sinceInceptionTotalGain
          sinceInceptionDate
          recordId
          periodEndMarketValue
          int1
          ${configFields}
        }
    }`,
  };

  return {
    selectedContactGuid,
    graphqlQuery,
    url,
  };
};

export const getPortfolioHoldingsDataObject = (
  selectedContactGuid: string | boolean,
  portfolioId: string,
  url: string,
  columns: TreeListColumnProps[],
) => {
  const configFieldsArray = columns.map((column: TreeListColumnProps) => {
    return column.field;
  });
  const configFields = configFieldsArray.join('\n ');
  const graphqlQuery = {
    query: `query Holdings {
      holdings (
        where: {
          and: [
            {contactGuid: {eq: "${selectedContactGuid}"}},
            {portfolioId: {eq: "${portfolioId}"}},
            {reportLevel: {gt: 0}},
            {isIndex: {eq: false}}
          ]
        },
        order: {sortOrder: ASC}
      ) {
          sortOrder
          portfolioId
          reportLevel
          parentId
          recordId
          periodEndMarketValue
          ${configFields}
      }
    }`,
  };

  return {
    selectedContactGuid,
    portfolioId,
    graphqlQuery,
    url,
  };
};

export const getPortfolioTimeSeriesDataObject = (
  selectedContactGuid: string | boolean,
  portfolioId: string,
  url: string,
  columns: TreeListColumnProps[],
) => {
  const configFieldsArray = columns?.map((column: TreeListColumnProps) => {
    return column.field;
  });
  const configFields = configFieldsArray.join('\n ');
  const graphqlQuery = {
    query: `query TimeSeries {
      timeSeries (
        where: {
          and: [
            {contactGuid: {eq: "${selectedContactGuid}"}},
            {portfolioId: { eq: "${portfolioId}"}},
            {isIndex: { eq: false}}
          ]
        },
        order: {timeSeriesDate: ASC}
      ) {
          title
          portfolioId
          ${configFields}
      }
    }`,
  };

  return {
    selectedContactGuid,
    portfolioId,
    graphqlQuery,
    url,
  };
};

export const getPortfolioIndicesDataObject = (
  url: string,
  columns: TreeListColumnProps[],
) => {
  const configFieldsArray = columns.map((column: TreeListColumnProps) => {
    return column.field;
  });
  const configFields = configFieldsArray.join('\n ');
  const graphqlQuery = {
    query: `query Indices {
      indices (order: {sortOrder: ASC}) {
          parentId
          recordId
          reportLevel
          ${configFields}
        }
    }`,
  };

  return {
    graphqlQuery,
    url,
  };
};

/**
 * Add Summary / Total row for Allocations, Holdings and Performance tables
 * @param portfolios
 * @param recordId
 * @returns
 */
export const getSumTableRow = (
  portfolios: Holding[] | undefined,
  recordId?: number,
) => {
  let sumRow = portfolios?.filter(
    (portfolio: Holding) => portfolio.recordId === recordId,
  )[0];
  return !!sumRow && sumRow;
};

/**
 * Get Publication Categories defined in global configuration (env)
 * @returns
 */
export const getPublicationCategories = () => {
  const allCategories = [
    envVars.PUBLICATION_CATEGORY_1,
    envVars.PUBLICATION_CATEGORY_2,
    envVars.PUBLICATION_CATEGORY_3,
    envVars.PUBLICATION_CATEGORY_4,
  ];
  const categories: (string | undefined)[] = allCategories.filter(
    (category: string | undefined) => category !== undefined,
  );
  const categoryObjects =
    !!categories &&
    categories.map((category: string | undefined, index: number) => {
      const categoryObject = {
        sortOrder: index + 1,
        name: category,
      };
      return categoryObject;
    });
  return categoryObjects;
};
