export const ApplicationName = 'CustomerPortal';

export const QueryParameterNames = {
  ReturnUrl: 'returnUrl',
  Message: 'message',
};

export const LogoutActions = {
  LogoutCallback: 'logout-callback',
  Logout: 'logout',
  LoggedOut: 'logged-out',
};

export const LoginActions = {
  Login: 'login',
  LoginCallback: 'login-callback',
  LoginFailed: 'login-failed',
  FormAuthentication: 'form-authentication',
  FormAuthenticationCallback: 'form-authentication-callback',
  FormAuthenticationFailed: 'form-authentication-failed',
};

const prefix = '/authentication';

export const ApplicationPaths = {
  DefaultLoginRedirectPath: '/',
  ApiAuthorizationClientConfigurationUrl: `_configuration`,
  ApiAuthorizationPrefix: prefix,
  Login: `${prefix}/${LoginActions.Login}`,
  LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
  LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
  FormAuthentication: `${prefix}/${LoginActions.FormAuthentication}`,
  FormAuthenticationCallback: `${prefix}/${LoginActions.FormAuthenticationCallback}`,
  FormAuthenticationFailed: `${prefix}/${LoginActions.FormAuthenticationFailed}`,
  LogOut: `${prefix}/${LogoutActions.Logout}`,
  LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
  LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
};
