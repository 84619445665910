export const svFormioMessages = {
  unsavedRowsError: 'Vänligen lagra alla rader innan du går vidare.',
  invalidRowsError: 'Vänligen korrigera ogiltiga rader innan du går vidare.',
  invalidRowError: 'Ogiltig rad. Vänligen korrigera eller radera.',
  alertMessageWithLabel: '{{label}}: {{message}}',
  alertMessage: '{{message}}',
  complete: 'Din data är inskickad/Uppladdning genomförd',
  error:
    'Vänligen korrigera följande fel innan uppladdning/du skickar in din data.',
  errorListHotkey: 'Ctrl + Alt + X för att gå tillbaka till errorlista.',
  errorsListNavigationMessage:
    'Klicka för att navigera till fält med följande fel.',
  submitError:
    'Vänligen kontrollera schema och korrigera alla fel innan uppladdning/du skickar in din data.',
  required: '{{field}} är obligatorisk',
  unique: '{{field}} måste vara unik',
  array: '{{field}} måste vara en array',
  array_nonempty: '{{field}} måste vara en array som inte är tom',
  nonarray: '{{field}} kan inte vara en array',
  select: '{{field}} innehåller ett ogiltigt val',
  pattern: '{{field}} samsvarar inte med mönsteret {{pattern}}',
  minLength: '{{field}} måste innehålla minst {{length}} tecken.',
  maxLength: '{{field}} kan inte innehålla fler än {{length}} tecken.',
  minWords: '{{field}} måste innehålla minst {{length}} ord.',
  maxWords: '{{field}} kan inte innehålla fler än {{length}} ord.',
  min: '{{field}} kan inte vare mindre än {{min}}.',
  max: '{{field}} kan inte vare större än {{max}}.',
  maxDate: '{{field}} ska inte innehålla datum efter {{- maxDate}}',
  minDate: '{{field}} ska inte innehålla datum före {{- minDate}}',
  maxYear: '{{field}} ska inte innehålla årtal efter {{maxYear}}',
  minYear: '{{field}} ska inte innehålla årtal före {{minYear}}',
  invalid_email: '{{field}} måste vara en giltig e-post.',
  invalid_url: '{{field}} måste vara en giltig url.',
  invalid_regex: '{{field}} samsvarar inte med mönsteret {{regex}}.',
  invalid_date: '{{field}} är inte ett giltig datum.',
  invalid_day: '{{field}} är inte en giltig dag.',
  mask: '{{field}} samsvarar inte med masken.',
  valueIsNotAvailable: '{{ field }} är ett ogiltigt värde.',
  stripe: '{{stripe}}',
  month: 'månad',
  Month: 'Månad',
  day: 'dag',
  Day: 'Dag',
  year: 'år',
  Year: 'År',
  january: 'januari',
  January: 'Januari',
  february: 'februari',
  February: 'Februari',
  march: 'mars',
  March: 'Mars',
  april: 'april',
  April: 'April',
  may: 'maj',
  May: 'Maj',
  june: 'juni',
  June: 'Juni',
  july: 'juli',
  July: 'Juli',
  august: 'augusti',
  August: 'Augusti',
  september: 'september',
  September: 'September',
  october: 'oktober',
  October: 'Oktober',
  november: 'november',
  November: 'November',
  december: 'december',
  December: 'December',
  next: 'Nästa',
  previous: 'Föregående',
  cancel: 'Avbryt',
  submit: 'Skicka in ansökan',
  submitForRequiredAuthentication: 'Identifiera dig för att skicka',
  confirmCancel: 'Är du säker på att du vill avbryta?',
  saveDraftInstanceError:
    'Kan inte lagra kladd för att formio-instans inte finns.',
  saveDraftAuthError:
    'Kan inte lagra kladd med mindre användaren är autentiserad.',
  restoreDraftInstanceError:
    'Kan inte återupprätta kladd för att formio-instans inte finns.',
  time: 'Ogiltig tidpunkt',
  'Type to search': 'Skriv för att söka',
  'No results found': 'Inget resultat hittades',
};
