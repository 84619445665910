import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from '../../hooks/reduxHooks';
import { selectUser } from '../api-authorization/userSlice';
import Logo from './Logo';
import AccountButton from './AccountButton';
import useNotifications from '../../hooks/useNotifications';
import { useTranslation } from '../../hooks/useTranslation';

export interface HeaderProps {
  onShowMenu?: () => void;
  onHideMenu?: () => void;
  menuBtnLabel?: string;
  showNotifications?: boolean;
  isSplashScreen?: boolean | null;
  isUserLogged?: boolean;
  accountButton?: JSX.Element;
}

const Header = ({
  onShowMenu,
  onHideMenu,
  menuBtnLabel: menuBtnLabelProp,
  showNotifications: showNotificationsProp,
  isSplashScreen,
  isUserLogged: isUserLoggedProp,
  accountButton: accountButtonProp,
}: HeaderProps) => {
  const ns = 'construo.header';
  const translations = {
    menuBtnLabel: useTranslation(`${ns}.menuBtnLabel`),
  };
  const menuBtnLabel =
    menuBtnLabelProp === undefined
      ? translations.menuBtnLabel
      : menuBtnLabelProp;

  const userState = useAppSelector(selectUser);
  const isUserLogged =
    isUserLoggedProp === undefined ? userState.isLoggedIn : isUserLoggedProp;

  const notifications: number = useNotifications();
  const hasNotifications = notifications > 0;
  const showNotifications =
    showNotificationsProp === undefined
      ? !!isUserLogged && hasNotifications
      : showNotificationsProp;

  const AccountButtonComponent = () => {
    return accountButtonProp === undefined ? (
      <AccountButton />
    ) : (
      accountButtonProp
    );
  };

  return (
    <header className='global-header'>
      <div className='container'>
        <NavLink className='logo' to='/' onClick={onHideMenu}>
          <Logo />
        </NavLink>
        {!isSplashScreen && (
          <div className='header-buttons'>
            {isUserLogged && <AccountButtonComponent />}
            <button
              type='button'
              className='btn btn-link btn-menu'
              onClick={onShowMenu}
            >
              <span>{menuBtnLabel}</span>
              <i
                className={`fal fa-bars ${
                  showNotifications ? 'notification-badge' : ''
                }`}
              />
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
