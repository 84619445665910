export const fiFormioMessages = {
  unsavedRowsError: 'Tallenna kaikki rivit ennen kuin jatkat.',
  invalidRowsError: 'Korjaa virheelliset rivit ennen kuin jatkat.',
  invalidRowError: 'Virheellinen rivi. Korjaa se tai poista se.',
  alertMessageWithLabel: '{{label}}: {{message}}',
  alertMessage: '{{message}}',
  complete: 'Lähetetty onnistuneesti',
  error: 'Korjaa seuraavat virheet ennen lähettämistä.',
  errorListHotkey: 'Palaa virheluetteloon painamalla Ctrl + Alt + X.',
  errorsListNavigationMessage:
    'Napsauta navigoidaksesi kenttään, jossa on seuraava virhe.',
  submitError: 'Tarkista lomake ja korjaa kaikki virheet ennen lähettämistä.',
  required: '{{field}} vaaditaan',
  unique: '{{field}} on oltava ainutlaatuinen',
  array: '{{field}} on oltava joukko',
  array_nonempty: '{{field}} on oltava ei-tyhjä joukko',
  nonarray: '{{field}} ei saa olla joukko',
  select: '{{field}} sisältää virheellisen valinnan',
  pattern: '{{field}} ei vastaa mallia {{pattern}}',
  minLength: '{{field}} on oltava vähintään {{length}} merkkiä.',
  maxLength: '{{field}} saa olla enintään {{length}} merkkiä.',
  minWords: '{{field}} on oltava vähintään {{length}} words.',
  maxWords: '{{field}} saa olla enintään {{length}} sanaa.',
  min: '{{field}} ei voi olla pienempi kuin {{min}}.',
  max: '{{field}} ei voi olla suurempi kuin {{max}}.',
  maxDate: '{{field}} ei saa sisältää päivämäärää {{- maxDate}} jälkeen',
  minDate: '{{field}} ei saa sisältää päivämäärää ennen {{- minDate}}',
  maxYear: '{{field}} pitäisi olla mikä tahansa vuosi ennen vuotta {{maxYear}}',
  minYear: '{{field}} pitäisi olla vuosi vuoden {{minYear}} jälkeen',
  invalid_email: '{{field}} on oltava kelvollinen sähköpostiosoite.',
  invalid_url: '{{field}} on oltava kelvollinen URL-osoite.',
  invalid_regex: '{{field}} ei vastaa kuviota {{regex}}.',
  invalid_date: '{{field}} ei ole kelvollinen päivämäärä.',
  invalid_day: '{{field}} ei ole kelvollinen päivä.',
  mask: '{{field}} ei vastaa maskia.',
  valueIsNotAvailable: '{{ field }} on virheellinen arvo.',
  stripe: '{{stripe}}',
  month: 'kuukausi',
  Month: 'Kuukausi',
  day: 'päivä',
  Day: 'Päivä',
  year: 'vuosi',
  Year: 'Vuosi',
  january: 'tammikuuta',
  January: 'Tammikuu',
  february: 'helmikuuta',
  February: 'Helmikuu',
  march: 'Maaliskuuta',
  March: 'Maaliskuu',
  april: 'huhtikuuta',
  April: 'Huhtikuu',
  may: 'toukokuuta',
  May: 'Toukokuu',
  june: 'kesäkuuta',
  June: 'Kesäkuu',
  july: 'heinäkuuta',
  July: 'Heinäkuu',
  august: 'elokuuta',
  August: 'Elokuu',
  september: 'syyskuuta',
  September: 'Syyskuu',
  october: 'lokakuuta',
  October: 'Lokakuu',
  november: 'marraskuuta',
  November: 'Marraskuu',
  december: 'joulukuuta',
  December: 'Joulukuu',
  next: 'Seuraava',
  previous: 'Edellinen',
  cancel: 'Peruuttaa',
  submit: 'Lähetä',
  submitForRequiredAuthentication: 'Tunnista ja lähetä',
  confirmCancel: 'Oletko varma, että haluat peruuttaa?',
  saveDraftInstanceError: 'Luonnosta ei voi tallentaa.',
  saveDraftAuthError:
    'Luonnosta ei voi tallentaa, ellei käyttäjää ole todennettu.',
  restoreDraftInstanceError: 'Luonnosta ei voi palauteta',
  time: 'Anna kelvollinen aika',
  'Type to search': 'Kirjoita etsiäksesi',
  'No results found': 'Ei tuloksia löytynyt',
};
