import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { NavLink, useParams } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import HelmetTitle from '../../common/HelmetTitle';
import {
  Form,
  Field,
  FormElement,
  FieldWrapper,
} from '@progress/kendo-react-form';
import { Label, Hint, Error } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import {
  fetchPublicSubmission,
  fetchSMSSubmission,
  fetchBankIdSubmission,
  updateSubmissionData,
  resetSubmissionData,
  selectSubmission,
  selectSubmissionJSON,
  selectSubmissionFetched,
  setSubmissionFetched,
  selectSubmissionError,
} from './submissionSlice';
import { Loading, setLoading } from '../../../app/slices/loadingSlice';
import { setLoadingMessage } from '../../../app/slices/loadingMessageSlice';
import { selectUser } from '../../api-authorization/userSlice';
import { selectLanguage } from '../../common/languageSwitcherSlice';
import { resetAlerts, setAlerts } from '../../../app/slices/alertSlice';
import envVars from '../../../resources/envVars';
import { HubConnection } from '@microsoft/signalr';
import { ApplicationPaths } from '../../api-authorization/ApiAuthorizationConstants';
import { convertMilisecondsToDateTime } from '../../../helpers/DateHelper';
import FormioForm from '../../common/FormioForm';
import FormSealed from '../../common/FormSealed';
import FormInaccessible from '../../common/FormInaccessible';
import { getAxiosConfig, getSortedAccountsList } from '../../../helpers/utils';
import Layout from '../../layout/Layout';
import { useTranslation } from '../../../hooks/useTranslation';
import SignalRConnection from '../../common/SignalRConnection';
import {
  selectRepresentationRights,
  selectSelectedAccount,
  setSelectedRepresentation,
} from '../../api-authorization/representationSlice';

interface FormioSubmissionProps {
  isSubmitted?: boolean;
}

const SubmissionForm = ({ isSubmitted = false }: FormioSubmissionProps) => {
  const dispatch = useAppDispatch();
  let languageId = useAppSelector(selectLanguage);

  const { token } = useParams();
  const tokenDecoded: any = !!token && jwtDecode(token);
  const authenticationType = tokenDecoded.AuthenticationType;

  // Check IF token expired and when
  const timeNow = Date.now();
  const isTokenExpired = !!tokenDecoded
    ? Number(tokenDecoded.exp) * 1000 < timeNow
    : false;

  const tokenExpiredOn =
    isTokenExpired &&
    convertMilisecondsToDateTime(Number(tokenDecoded.exp), languageId);

  const API_BASE_URI = envVars.API_BASE_URI;

  const formSubmission: any = useAppSelector(selectSubmission);
  const submissionFetched = useAppSelector(selectSubmissionFetched);
  const submissionData = useAppSelector(selectSubmissionJSON);
  const isFormSealed = formSubmission?.FormSubmissionStatus === 'Sealed';
  const form = !!submissionData && JSON.parse(submissionData);

  const submissionError = useAppSelector(selectSubmissionError);

  let customerResponse =
    !!formSubmission.CustomerResponse &&
    JSON.parse(formSubmission.CustomerResponse);
  customerResponse = !!customerResponse ? customerResponse : {};

  const submissionGuid = formSubmission.SubmissionGuid;
  const submissionTitle = formSubmission?.FormTemplate?.Title;

  const FORM_SUBMISSIONS_URI = API_BASE_URI + '/kyc/FormSubmissions';

  const [connection, setConnection] = useState<null | HubConnection>(null);
  const [isConnectionStarted, setIsConnectionStarted] = useState(false);

  const [invokeSMS, setInvokeSMS] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(isSubmitted);

  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isCodeNeedResend, setIsCodeNeedResend] = useState(false);

  const codeRemainingTime = useRef(60);
  // const [displayRemainingTime, setDisplayRemainingTime] = useState<any>();

  const userState = useAppSelector(selectUser);

  const isUserLogged = userState.isLoggedIn;
  const loginPath = ApplicationPaths.Login;

  const ns = 'construo.forms';
  const translations = {
    login: useTranslation(`construo.homepage.login`),
    gotoLoginPage: useTranslation(`construo.global.gotoLoginPage`),
    loaderCreatingSigningRequest: useTranslation(
      `${ns}.loaderCreatingSigningRequest`,
    ),
    loaderSubmittingForm: useTranslation(`${ns}.loaderSubmittingForm`),
    loaderGeneratingPdf: useTranslation(`${ns}.loaderGeneratingPdf`),
    alertErrorCreatingSigningRequest: useTranslation(
      `${ns}.alertErrorCreatingSigningRequest`,
    ),
    inputValidatorText: useTranslation(`${ns}.inputValidatorText`),
    formSubmittedHeading: useTranslation(`${ns}.formSubmittedHeading`),
    formSubmittedText: useTranslation(`${ns}.formSubmittedText`),
    fieldHint: useTranslation(`${ns}.fieldHint`),

    smsSecurityCode: useTranslation(`${ns}.smsSecurityCode`),
    inOrderToAccess: useTranslation(`${ns}.inOrderToAccess`),
    sendCode: useTranslation(`${ns}.sendCode`),
    sentSixDigitCode: useTranslation(`${ns}.sentSixDigitCode`),
    enterBelow: useTranslation(`${ns}.enterBelow`),
    accessBtnLabel: useTranslation(`${ns}.accessBtnLabel`),
    didNotReceiveCode: useTranslation(`${ns}.didNotReceiveCode`),
    requestCode: useTranslation(`${ns}.requestCode`),
    waitToRequestCode: useTranslation(`${ns}.waitToRequestCode`),

    bankIdLoginWarningHeading: useTranslation(
      `${ns}.bankIdLoginWarningHeading`,
    ),
    bankIdLoginWarningText: useTranslation(`${ns}.bankIdLoginWarningText`),
    tokenExpiredHeading: useTranslation(`${ns}.tokenExpiredHeading`),
    tokenExpiredText: useTranslation(`${ns}.tokenExpiredText`, [
      tokenExpiredOn,
    ]),
  };

  const accounts = useAppSelector(selectRepresentationRights);
  const selectedAccount = useAppSelector(selectSelectedAccount);
  const hasSelectedAccount = !!selectedAccount;

  /**
   * If user is logged in (isUserLogged) and account is not selected, selected account to be personal user account
   */
  useEffect(() => {
    const accountsSorted = getSortedAccountsList(accounts);
    const personalAccount = accountsSorted[accountsSorted.length - 1];
    if (isUserLogged && !hasSelectedAccount) {
      dispatch(setSelectedRepresentation(personalAccount));
    }
  }, [dispatch, accounts, isUserLogged, hasSelectedAccount]);

  useEffect(() => {
    return () => {
      dispatch(resetAlerts());
      dispatch(setSubmissionFetched(null));
      dispatch(resetSubmissionData());
    };
  }, [dispatch]);

  const codeRemainingTimeInterval: any = useRef(null);

  const smsCodeResult = useRef();

  const reusableSetInterval = () => {
    return setInterval(() => {
      if (codeRemainingTime.current > 0) {
        const remainingTime: number = codeRemainingTime.current - 1;
        codeRemainingTime.current = remainingTime;
        // setDisplayRemainingTime(remainingTime);
      } else if (codeRemainingTime.current === 0) {
        setIsCodeNeedResend(true);
      }
    }, 1000);
  };

  useEffect(() => {
    if (
      !isCodeNeedResend &&
      isCodeSent &&
      codeRemainingTimeInterval.current === null
    ) {
      // TODO: start counter for sms code
      codeRemainingTimeInterval.current = reusableSetInterval();
    } else if (isCodeNeedResend) {
      dispatch(resetAlerts());
      clearInterval(codeRemainingTimeInterval.current);
    }
  }, [dispatch, codeRemainingTime, isCodeNeedResend, isCodeSent]);

  const sendSMSCode = useCallback(async () => {
    const smsCodeURL = `${FORM_SUBMISSIONS_URI}/SMS?token=${token}`;
    const config = {};
    await axios
      .get(smsCodeURL, config)
      .then(response => {
        smsCodeResult.current = response.data;
      })
      .catch(error => {
        dispatch(
          setAlerts({ message: error.response?.data?.Message, type: 'error' }),
        );
        if (error.response.status === 409) {
          setIsCodeNeedResend(false);
        }
      });
  }, [dispatch, FORM_SUBMISSIONS_URI, token]);

  useEffect(() => {
    const formSubmissionData = {
      token,
      API_BASE_URI,
    };

    if (submissionFetched === null) {
      if (authenticationType === 'Public' && !isTokenExpired) {
        dispatch(setLoading(Loading.Show));
        dispatch(fetchPublicSubmission(formSubmissionData));
      } else if (
        authenticationType === 'Sms authentication' &&
        !isTokenExpired
      ) {
        // Send request to the Gateway to send SMS with a code to specified mobile phone number
        if (!smsCodeResult.current && invokeSMS && isCodeSent) {
          setInvokeSMS(false);
          sendSMSCode();
        }
      } else if (
        authenticationType === 'BankId authentication' &&
        !isTokenExpired
      ) {
        // Check IF User is Logged
        if (isUserLogged) {
          // Remove redirectToSubmissionURL from session storage once "BankId" Form Submission is accessed, User is logged in
          sessionStorage.removeItem('redirectToSubmissionURL');
          const bankIdFormSubmissionData = {
            token: token,
            url: API_BASE_URI + '/kyc/FormSubmissions/NIN?token=',
          };
          dispatch(setLoading(Loading.Show));
          dispatch(fetchBankIdSubmission(bankIdFormSubmissionData));
        }
      }
    } else if (submissionFetched) {
      dispatch(setLoading(Loading.Hide));
    }
  }, [
    dispatch,
    isUserLogged,
    isTokenExpired,
    token,
    API_BASE_URI,
    FORM_SUBMISSIONS_URI,
    submissionFetched,
    authenticationType,
    invokeSMS,
    isCodeSent,
    sendSMSCode,
  ]);

  // Display error alert if submission is not accessible
  useEffect(() => {
    if (submissionFetched && !form && submissionError) {
      dispatch(setAlerts({ message: submissionError, type: 'error' }));
    }
  }, [dispatch, submissionFetched, form, submissionError]);

  const saveRedirectUrl = () => {
    sessionStorage.setItem(
      'redirectToSubmissionURL',
      JSON.stringify(window.location.pathname),
    );
  };

  const onSubmit = async (submission: any) => {
    delete submission.metadata;
    delete submission.state;

    dispatch(updateSubmissionData(JSON.stringify(submission)));
    dispatch(setLoading(Loading.Show));
    dispatch(setLoadingMessage(translations.loaderSubmittingForm));

    const config = getAxiosConfig(null, 'json');
    const data = {
      SubmissionGuid: submissionGuid,
      UserToken: token,
      CustomerResponse: JSON.stringify(submission),
      FormSubmissionStatus: 'Submitted by client',
      CurrentConnectionId:
        !!connection && !!connection.connectionId
          ? connection.connectionId
          : null,
    };
    const dataStringify = JSON.stringify(data);
    const url = FORM_SUBMISSIONS_URI;

    await axios
      .put(url, dataStringify, config)
      .then(response => {
        dispatch(setLoading(Loading.Hide));
        dispatch(setLoadingMessage(null));
        // Show success message
        setIsFormSubmitted(true);
        window.scrollTo(0, 0);
        if (formSubmission.DirectSign) {
          dispatch(setLoading(Loading.Show));
          dispatch(setLoadingMessage(translations.loaderGeneratingPdf));
        }
      })
      .catch(error => {
        dispatch(setLoading(Loading.Hide));
        dispatch(setLoadingMessage(null));
        // Show error message after submission fails
        dispatch(setAlerts({ message: error.message, type: 'error' }));
        // And scroll to top so that alert is visible
        window.scrollTo(0, 0);
      });
  };

  const onChange = (submission: any, schema: any) => {
    const fileLink = document.querySelector(
      '.formio-component-upload a[ref=fileLink]',
    );
    fileLink?.setAttribute('style', 'pointer-events: none;');

    const removeLink = document.querySelector(
      '.formio-component-upload i[ref=removeLink]',
    );
    removeLink?.setAttribute('style', 'cursor: pointer;');

    if (
      !!submission.changed &&
      submission.changed.component.type === 'file' &&
      submission.changed.value.length &&
      submission.isValid
    ) {
      fileLink?.removeAttribute('href');
    }
  };

  const max = 6;
  const inputValidator = (value: any) =>
    !value ? translations.inputValidatorText : '';

  const FormInput = (fieldRenderProps: any) => {
    const {
      validationMessage,
      touched,
      label,
      id,
      valid,
      disabled,
      hint,
      type,
      optional,
      max,
      value,
      ...others
    } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hindId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';

    return (
      <>
        <FieldWrapper>
          <Label
            editorId={id}
            editorValid={valid}
            editorDisabled={disabled}
            optional={optional}
          >
            {label}
          </Label>
          <div className={'k-form-field-wrap'}>
            <Input
              valid={valid}
              type={type}
              id={id}
              disabled={disabled}
              maxLength={max}
              ariaDescribedBy={`${hindId} ${errorId}`}
              {...others}
            />
            <div style={{ display: 'flex', justifyContent: 'start' }}>
              {showHint && <Hint id={hindId}>{hint}</Hint>}
              {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
              )}
              <Hint style={{ marginLeft: 'auto' }}>
                {value.length} / {max}
              </Hint>
            </div>
          </div>
        </FieldWrapper>
      </>
    );
  };

  const handleSMSCodeSubmit = (dataItem: any) => {
    const smsCode = dataItem.smsCode;
    // send request to the Gateway to send submission form
    const smsFormSubmissionData = {
      token: token,
      url: FORM_SUBMISSIONS_URI,
      smsCode: smsCode,
    };
    setIsCodeNeedResend(true);
    // clearInterval(codeRemainingTimeInterval.current);
    dispatch(setLoading(Loading.Show));
    dispatch(fetchSMSSubmission(smsFormSubmissionData));
  };

  return (
    <>
      <HelmetTitle title={submissionTitle} />

      {formSubmission.DirectSign && (
        <SignalRConnection
          connection={connection}
          setConnection={setConnection}
          isConnectionStarted={isConnectionStarted}
          setIsConnectionStarted={setIsConnectionStarted}
          isDirectSign={formSubmission.DirectSign}
          isFormSubmitted={isFormSubmitted}
        />
      )}

      <Layout>
        <section className='main-section'>
          <div className='container'>
            {submissionError && <FormInaccessible />}

            {submissionFetched &&
              (isFormSubmitted ? (
                <>
                  <h1>{translations.formSubmittedHeading}</h1>
                  <p>{translations.formSubmittedText}</p>
                  <p>
                    <NavLink to={loginPath}>
                      {translations.gotoLoginPage}
                    </NavLink>
                  </p>
                </>
              ) : (
                !!form &&
                submissionFetched &&
                !isTokenExpired &&
                !isFormSealed &&
                (!formSubmission.DirectSign ||
                  (formSubmission.DirectSign && isConnectionStarted)) &&
                (authenticationType === 'Public' ||
                  authenticationType === 'Sms authentication' ||
                  authenticationType === 'BankId authentication') && (
                  <div lang={formSubmission?.Language?.Code}>
                    {!!formSubmission.Title && <h1>{formSubmission.Title}</h1>}
                    {!!formSubmission.Description && (
                      <p>{formSubmission.Description}</p>
                    )}
                    <FormioForm
                      form={form}
                      submission={customerResponse}
                      onSubmit={onSubmit}
                      onChange={onChange}
                      languageCode={formSubmission?.Language?.Code}
                    />
                  </div>
                )
              ))}

            {!submissionFetched &&
              !isTokenExpired &&
              !isFormSealed &&
              authenticationType === 'Sms authentication' && (
                <>
                  {!!formSubmission.Title && <h1>{formSubmission.Title}</h1>}
                  <h5>{translations.smsSecurityCode}</h5>
                </>
              )}

            {!submissionFetched &&
              !isTokenExpired &&
              !isFormSealed &&
              !isCodeSent &&
              authenticationType === 'Sms authentication' && (
                <>
                  <p>{translations.inOrderToAccess}</p>
                  <Button
                    themeColor='primary'
                    onClick={() => setIsCodeSent(true)}
                  >
                    {translations.sendCode}
                  </Button>
                </>
              )}

            {!submissionFetched &&
              !isTokenExpired &&
              !isFormSealed &&
              isCodeSent &&
              authenticationType === 'Sms authentication' && (
                <>
                  <p>
                    {translations.sentSixDigitCode}
                    <br />
                    {translations.enterBelow}
                  </p>

                  <Form
                    initialValues={{
                      smsCode: '',
                    }}
                    onSubmit={handleSMSCodeSubmit}
                    render={formRenderProps => (
                      <FormElement style={{ maxWidth: '400px' }}>
                        <fieldset className={'k-form-fieldset'}>
                          <Field
                            id={'smsCode'}
                            name={'smsCode'}
                            label={'SMSLogin:'}
                            max={max}
                            value={formRenderProps.valueGetter('smsCode')}
                            hint={translations.fieldHint}
                            component={FormInput}
                            validator={inputValidator}
                          />
                          <div className='k-form-buttons k-justify-content-start'>
                            <Button
                              themeColor='primary'
                              type={'submit'}
                              disabled={!formRenderProps.allowSubmit}
                            >
                              {translations.accessBtnLabel}
                            </Button>
                          </div>
                        </fieldset>
                      </FormElement>
                    )}
                  />
                  <div style={{ marginTop: '20px' }}>
                    <span
                      style={{
                        display: 'inline-block',
                        marginRight: '10px',
                        verticalAlign: 'middle',
                      }}
                    >
                      {translations.didNotReceiveCode}
                    </span>

                    {isCodeNeedResend ? (
                      <>
                        <button
                          className='btn btn-link'
                          style={{ padding: '0' }}
                          onClick={e => {
                            e.preventDefault();
                            sendSMSCode();
                            dispatch(resetAlerts());
                            setIsCodeNeedResend(false);
                            setIsCodeSent(true);
                            codeRemainingTime.current = 60;
                            codeRemainingTimeInterval.current = null;
                            // codeRemainingTimeInterval.current = reusableSetInterval();
                          }}
                        >
                          {translations.requestCode}
                        </button>
                      </>
                    ) : (
                      <span
                        style={{
                          display: 'inline-block',
                          marginRight: '10px',
                          verticalAlign: 'middle',
                        }}
                      >
                        {translations.waitToRequestCode}
                        {/* <span> {displayRemainingTime} </span> */}
                      </span>
                    )}
                  </div>
                </>
              )}

            {!submissionFetched &&
              !isTokenExpired &&
              !isFormSealed &&
              authenticationType === 'BankId authentication' && (
                <>
                  <h1>{translations.bankIdLoginWarningHeading}</h1>
                  <p>{translations.bankIdLoginWarningText}</p>
                  <NavLink
                    className='btn btn-primary'
                    to={loginPath}
                    onClick={saveRedirectUrl}
                  >
                    {translations.login}
                  </NavLink>
                </>
              )}

            {isTokenExpired && (
              <>
                <h1>{translations.tokenExpiredHeading}</h1>
                <p>{translations.tokenExpiredText}</p>
              </>
            )}
            {isFormSealed && <FormSealed />}
          </div>
        </section>
      </Layout>
    </>
  );
};

export default SubmissionForm;
