import React from 'react';
import { Skeleton } from '@progress/kendo-react-indicators';

export const ConversationListSkeleton = () => {
  const item = (
    <div className='list-group-item'>
      <div className='list-item-text'>
        <Skeleton shape={'text'} style={{ width: 250 }} />
      </div>
      <div className='list-item-date'>
        <Skeleton shape={'text'} style={{ width: 80 }} />
      </div>
    </div>
  );

  return (
    <div className='list-group conversations-list'>
      {item}
      {item}
      {item}
    </div>
  );
};

export default ConversationListSkeleton;
