import React, { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import {
  selectRepresentationRights,
  selectSelectedAccount,
  setSelectedRepresentation,
} from '../../api-authorization/representationSlice';
import { selectUser } from '../../api-authorization/userSlice';
import SelectAccount, { IAccount } from './SelectAccount';
import {
  getSortedAccountsList,
  navigateToReturnUrl,
} from '../../../helpers/utils';
import { setSelectedPortfolioId } from '../portfolios/portfoliosSlice';
import {
  setLatestDocumentsFetched,
  setLatestPublicationsFetched,
} from '../documents/documentsSlice';
import { useTranslation } from '../../../hooks/useTranslation';

const SelectAccountProvider = () => {
  const dispatch = useAppDispatch();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const returnUrl = urlParams.get('returnUrl');
  const contactGuid = localStorage.getItem('selectedAccountContactGuid');
  const accounts: IAccount[] | null = useAppSelector(
    selectRepresentationRights,
  );
  const selectedAccount: IAccount | undefined = accounts?.filter(
    (account: IAccount) => account.contactGuid === contactGuid,
  )[0];

  const reduxSelectedAccount = useAppSelector(selectSelectedAccount);

  const handleAccountClick = useCallback(
    (account: IAccount) => {
      dispatch(setSelectedPortfolioId(null));
      dispatch(setLatestDocumentsFetched(null));
      dispatch(setLatestPublicationsFetched(null));
      dispatch(setSelectedRepresentation(account));
      sessionStorage.setItem('selectedAccount', JSON.stringify(account));
      if (!!account.contactGuid) {
        localStorage.setItem('selectedAccountContactGuid', account.contactGuid);
      }
      if (!!returnUrl) {
        navigateToReturnUrl(returnUrl);
      }
    },
    [dispatch, returnUrl],
  );

  useEffect(() => {
    if (!!returnUrl && reduxSelectedAccount === null && !!selectedAccount) {
      handleAccountClick(selectedAccount);
    }
  }, [
    dispatch,
    returnUrl,
    selectedAccount,
    reduxSelectedAccount,
    handleAccountClick,
  ]);

  const user = useAppSelector(selectUser);
  const data = useAppSelector(selectRepresentationRights);

  /**
   * Create translations object
   */
  const ns = 'construo.selectAccount';
  const translations = {
    title: useTranslation(`${ns}.title`),
    heading: useTranslation(`${ns}.heading`, [user.username]),
    subheading: useTranslation(`${ns}.subheading`),
  };

  const accountsSorted = getSortedAccountsList(data);

  return (
    <SelectAccount
      title={translations.title}
      heading={translations.heading}
      subheading={translations.subheading}
      accountListData={accountsSorted}
      onAccountClick={handleAccountClick}
    />
  );
};

export default SelectAccountProvider;
