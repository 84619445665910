import React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import Header from './Header';
import Footer from './Footer';
import {
  selectVisibilityMenu,
  setVisibilityMenu,
  VisibilityMenu,
} from '../../app/slices/visibilityMenuSlice';
import { selectAlert } from '../../app/slices/alertSlice';
import Alerts from '../common/Alerts';
import GlobalMenuDataProvider from './GlobalMenuDataProvider';
import DesktopNavigation from './DesktopNavigation';
import { selectUser } from '../api-authorization/userSlice';

export interface LayoutProps {
  children?: React.ReactNode;
  isSplashLayout?: boolean;
  layoutClassName?: string;
}

const Layout = ({ children, isSplashLayout, layoutClassName }: LayoutProps) => {
  const dispatch = useAppDispatch();
  const visibilityMenu = useAppSelector(selectVisibilityMenu);
  const isMenuVisible = visibilityMenu === VisibilityMenu.Show;
  const showMenu = () => dispatch(setVisibilityMenu(VisibilityMenu.Show));
  const hideMenu = () => dispatch(setVisibilityMenu(VisibilityMenu.Hide));
  const userState = useAppSelector(selectUser);
  const isUserLogged = userState.isLoggedIn;

  let alerts = useAppSelector(selectAlert);

  /**
   * If there are any saved alerts (session storage) show those, we are saving alerts on UploadActionDetails page/component,
   * since we are showing alerts message later on Inbox page
   */
  const sessionAlerts = sessionStorage.getItem('alerts');
  const sessionAlertsParsed = sessionAlerts && JSON.parse(sessionAlerts);
  if (!!sessionAlerts && sessionAlerts.length > 0) {
    alerts = sessionAlertsParsed;
  }

  return (
    <div
      className={`layout
        ${isMenuVisible ? 'global-menu-visible' : ''}
        ${isSplashLayout ? 'splash-layout' : ''}
        ${layoutClassName ? layoutClassName : ''}
      `}
    >
      <Header
        onShowMenu={showMenu}
        onHideMenu={hideMenu}
        isSplashScreen={isSplashLayout}
      />
      {isUserLogged && !isSplashLayout && <DesktopNavigation />}
      <Alerts alerts={alerts} />
      {!isSplashLayout && <GlobalMenuDataProvider onHideMenu={hideMenu} />}
      <main>{children}</main>
      <Footer />
      {!isSplashLayout && (
        <div className='global-menu-overlay' onClick={hideMenu} />
      )}
    </div>
  );
};

export default Layout;
