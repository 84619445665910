import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import {
  NavLink,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import HelmetTitle from '../../common/HelmetTitle';
import axios from 'axios';
import {
  fetchPublicForm,
  resetFormData,
  selectFormError,
  selectFormFetched,
  selectPublicFormData,
  selectTemplateData,
  setFormFetched,
  updateFormData,
} from './publicFormSlice';
import { Loading, setLoading } from '../../../app/slices/loadingSlice';
import { setLoadingMessage } from '../../../app/slices/loadingMessageSlice';
import { resetAlerts, setAlerts } from '../../../app/slices/alertSlice';
import { HubConnection } from '@microsoft/signalr';
import envVars from '../../../resources/envVars';
import FormioForm from '../../common/FormioForm';
import { getAxiosConfig, getSortedAccountsList } from '../../../helpers/utils';
import { ApplicationPaths } from '../../api-authorization/ApiAuthorizationConstants';
import Layout from '../../layout/Layout';
import SignalRConnection from '../../common/SignalRConnection';
import authService from '../../api-authorization/AuthorizeService';
import { jwtDecode } from 'jwt-decode';
import { useTranslation } from '../../../hooks/useTranslation';
import {
  selectRepresentationRights,
  selectSelectedAccount,
  setSelectedRepresentation,
} from '../../api-authorization/representationSlice';

export interface FormioFormProps {
  isSubmitted?: boolean;
}

const PublicForm = ({ isSubmitted = false }: FormioFormProps) => {
  const ns = 'construo.forms';
  const translations = {
    login: useTranslation(`construo.homepage.login`),
    returnHome: useTranslation(`${ns}.returnHome`),
    gotoLoginPage: useTranslation(`construo.global.gotoLoginPage`),
    alertTest: useTranslation(`${ns}.alertTest`),
    loaderSubmittingForm: useTranslation(`${ns}.loaderSubmittingForm`),
    loaderGeneratingPdf: useTranslation(`${ns}.loaderGeneratingPdf`),
    formSubmittedHeading: useTranslation(`${ns}.formSubmittedHeading`),
    formSubmittedText: useTranslation(`${ns}.formSubmittedText`),
    formInaccessibleHeading: useTranslation(`${ns}.formInaccessibleHeading`),
    formInaccessibleText: useTranslation(`${ns}.formInaccessibleText`),
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  let { guid } = useParams();
  const loginPath = ApplicationPaths.Login;
  const homePath = ApplicationPaths.DefaultLoginRedirectPath;

  // Determine IF page render is a TEST preview, isTest=true
  const [searchParams, setSearchParams] = useSearchParams();
  const isTest = searchParams.get('isTest') === 'true';

  if (isTest) {
    guid = guid + '?isTest=true';
  }

  // Determine IF user is coming back from authentication, isFormAuthenticationCallback=true
  const isFormAuthenticationCallback =
    searchParams.get('isFormAuthenticationCallback') === 'true';

  const accounts = useAppSelector(selectRepresentationRights);
  const selectedAccount = useAppSelector(selectSelectedAccount);
  const hasSelectedAccount = !!selectedAccount;

  const publicForm: any = useAppSelector(selectTemplateData);

  const isRequiredAuthentication =
    !!publicForm && publicForm.RequiredAuthentication?.length > 0;

  const [isRequiredAuthenticationBtn, setIsRequiredAuthenticationBtn] =
    useState<boolean | null>(null);

  const formFetched = useAppSelector(selectFormFetched);
  const formData = useAppSelector(selectPublicFormData);

  const form = !!formData && JSON.parse(formData);
  const formError = useAppSelector(selectFormError);

  const customerResponse = useRef(null);

  const customerResponseSessionStorage = sessionStorage.getItem(
    `authCustomerResponse_${guid}`,
  );

  const currentSubmission = !!customerResponseSessionStorage
    ? JSON.parse(customerResponseSessionStorage)
    : customerResponse.current;

  /**
   * Check if user is logged in and if yes decode access token and compare idp prop value with
   * public form RequiredAuthentication prop value
   */
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [token, setToken] = useState(null);
  const [isTokenResolved, setIsTokenResolved] = useState(false);

  useEffect(() => {
    const requiredAuthentication = publicForm?.RequiredAuthentication;
    if (requiredAuthentication) {
      (async () => {
        const token = await authService
          .getAccessToken()
          .then()
          .finally(() => {
            setIsTokenResolved(true);
          });
        const tokenDecoded: any = !!token && jwtDecode(token);
        setToken(token);
        if (tokenDecoded?.idp === requiredAuthentication?.toString()) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      })().catch(err => {
        console.error(err);
      });
    }
  }, [publicForm?.RequiredAuthentication]);

  const API_BASE_URI = envVars.API_BASE_URI;

  const [connection, setConnection] = useState<null | HubConnection>(null);
  const [isConnectionStarted, setIsConnectionStarted] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(isSubmitted);

  // Load the form initially
  useEffect(() => {
    let ignoreFetch = false;
    const fetchPublicFormData = {
      guid,
      API_BASE_URI,
    };
    if (formFetched === null) {
      setTimeout(() => {
        if (!ignoreFetch) {
          dispatch(setLoading(Loading.Show));
          dispatch(fetchPublicForm(fetchPublicFormData));
        }
      }, 0);
    } else if (formFetched) {
      // if public form DOESN'T HAVE direct sign hide loader
      // or if public form HAVE direct sign wait for connection to start and then hide loader
      (!publicForm.DirectSign ||
        (publicForm.DirectSign && isConnectionStarted)) &&
        dispatch(setLoading(Loading.Hide));
    }
    return () => {
      ignoreFetch = true;
    };
  }, [
    dispatch,
    publicForm.DirectSign,
    formFetched,
    isConnectionStarted,
    guid,
    API_BASE_URI,
  ]);

  /**
   * If user is authenticated (isAuthenticated) and form data (sessionStorage.getItem('authUrl'))
   * is saved in session storage select selected account to be personal user account
   */
  useEffect(() => {
    const accountsSorted = getSortedAccountsList(accounts);
    const personalAccount = accountsSorted[accountsSorted.length - 1];
    if (isAuthenticated && !hasSelectedAccount) {
      dispatch(setSelectedRepresentation(personalAccount));
    }
  }, [dispatch, accounts, isAuthenticated, hasSelectedAccount]);

  // Display error alert if form is not accessible
  useEffect(() => {
    if (formFetched && !form && formError) {
      dispatch(setAlerts({ message: formError, type: 'error' }));
    }
  }, [dispatch, formFetched, form, formError]);

  // Display warning alert if form is in test mode
  useEffect(() => {
    if (isTest) {
      dispatch(setAlerts({ message: translations.alertTest, type: 'warning' }));
    }
  }, [dispatch, isTest, translations.alertTest]);

  useEffect(() => {
    return () => {
      dispatch(resetAlerts());
      dispatch(setLoading(Loading.Hide));
      dispatch(setFormFetched(null));
      dispatch(resetFormData());
    };
  }, [dispatch]);

  const FORM_SUBMISSIONS_URI = envVars.API_BASE_URI + '/kyc/FormSubmissions';
  const AUTH_FORM_SUBMISSIONS_URI =
    envVars.API_BASE_URI + '/kyc/formsubmissions/authenticated';

  const GUID = publicForm?.GUID;

  const submitData = async (submission: any) => {
    if (!isFormAuthenticationCallback) {
      dispatch(setLoading(Loading.Show));
      dispatch(setLoadingMessage(translations.loaderSubmittingForm));
    }

    let url: string = FORM_SUBMISSIONS_URI;
    let config = getAxiosConfig(null, 'json');

    if (isRequiredAuthentication) {
      config = getAxiosConfig(token, 'json');
      url = AUTH_FORM_SUBMISSIONS_URI;
    }

    const data = {
      TemplateGuid: GUID,
      CustomerResponse: JSON.stringify(submission),
      FormSubmissionStatus: 'Submitted by client',
      CurrentConnectionId:
        !!connection && !!connection.connectionId
          ? connection.connectionId
          : null,
    };

    const dataStringify = JSON.stringify(data);

    await axios
      .post(url, dataStringify, config)
      .then(response => {
        dispatch(setLoading(Loading.Hide));
        dispatch(setLoadingMessage(null));
        dispatch(updateFormData(JSON.stringify(submission)));

        // On successful data submit remove Form URL and data from sessionStorage
        Object.keys(sessionStorage)
          .filter(item => item.startsWith('authCustomerResponse_'))
          .forEach(item => sessionStorage.removeItem(item));
        sessionStorage.removeItem('authUrl');

        // Remove search (query) parameter "isFormAuthenticationCallback" from URL by removing all params
        setSearchParams('');

        // Show success message
        setIsFormSubmitted(true);
        window.scrollTo(0, 0);

        if (publicForm?.DirectSign) {
          dispatch(setLoading(Loading.Show));
          dispatch(setLoadingMessage(translations.loaderGeneratingPdf));
        }
      })
      .catch(error => {
        dispatch(setLoading(Loading.Hide));
        dispatch(setLoadingMessage(null));
        // Show error message after submission fails
        dispatch(setAlerts({ message: error.message, type: 'error' }));
        // And scroll to top so that alert is visible
        window.scrollTo(0, 0);
      });
  };

  const isSubmitTriggered = useRef(false);
  const isResetSubmitTriggered = useRef(false);

  if (isConnectionStarted && !isResetSubmitTriggered.current) {
    isResetSubmitTriggered.current = true;
    isSubmitTriggered.current = false;
  }

  const onSubmit = (submission: any) => {
    delete submission.metadata;
    delete submission.state;

    if (!isSubmitTriggered.current) {
      isSubmitTriggered.current = true;
      if (isRequiredAuthentication) {
        if (isAuthenticated) {
          submitData(submission);
        } else {
          delete submission.metadata;
          delete submission.state;

          sessionStorage.setItem('authUrl', `/Forms/${guid}`);
          sessionStorage.setItem(
            `authCustomerResponse_${guid}`,
            JSON.stringify(submission),
          );
          navigate(ApplicationPaths.FormAuthentication);
        }
      } else {
        submitData(submission);
      }
    }
  };

  const onChange = (submission: any) => {
    if (!!submission.changed) {
      customerResponse.current = submission;
    }

    const fileLink = document.querySelector(
      '.formio-component-upload a[ref=fileLink]',
    );
    fileLink?.setAttribute('style', 'pointer-events: none;');

    const removeLink = document.querySelector(
      '.formio-component-upload i[ref=removeLink]',
    );
    removeLink?.setAttribute('style', 'cursor: pointer;');

    if (
      !!submission.changed &&
      submission.changed.component.type === 'file' &&
      submission.changed.value.length &&
      submission.isValid
    ) {
      fileLink?.removeAttribute('href');
    }
  };

  useEffect(() => {
    if (isTokenResolved && formFetched) {
      if (isRequiredAuthentication && !isAuthenticated) {
        setIsRequiredAuthenticationBtn(true);
      } else {
        setIsRequiredAuthenticationBtn(false);
      }
    }
  }, [isTokenResolved, formFetched, isRequiredAuthentication, isAuthenticated]);

  const onFormReady = (formInstance: any) => {
    if (form.display === 'wizard' && !!customerResponseSessionStorage) {
      const lastPage = formInstance.pages.length - 1;
      formInstance.setPage(lastPage);
    }
  };

  if (
    isFormAuthenticationCallback &&
    isAuthenticated &&
    !!customerResponseSessionStorage &&
    !isSubmitTriggered.current
  ) {
    if (publicForm.DirectSign) {
      if (isConnectionStarted) {
        // Submit data if signalR connection is started
        isSubmitTriggered.current = true;
        submitData(JSON.parse(customerResponseSessionStorage!));
      }
    } else {
      isSubmitTriggered.current = true;
      submitData(JSON.parse(customerResponseSessionStorage));
    }
  }

  const renderForm =
    !!form &&
    ((publicForm.DirectSign && isConnectionStarted) || !publicForm.DirectSign);

  return (
    <>
      <HelmetTitle title={publicForm?.Title} />
      {publicForm?.DirectSign && (
        <SignalRConnection
          connection={connection}
          setConnection={setConnection}
          isConnectionStarted={isConnectionStarted}
          setIsConnectionStarted={setIsConnectionStarted}
          isDirectSign={publicForm?.DirectSign}
          isFormSubmitted={isFormSubmitted}
        />
      )}

      <Layout>
        <section className='main-section'>
          <div className='container'>
            {formFetched &&
              isRequiredAuthenticationBtn !== null &&
              (isFormSubmitted ? (
                <>
                  <h1>{translations.formSubmittedHeading}</h1>
                  <p>{translations.formSubmittedText}</p>
                  <p>
                    {isAuthenticated ? (
                      <NavLink to={homePath}>{translations.returnHome}</NavLink>
                    ) : (
                      <NavLink to={loginPath}>
                        {translations.gotoLoginPage}
                      </NavLink>
                    )}
                  </p>
                </>
              ) : (
                <>
                  {!!renderForm &&
                    (!isFormAuthenticationCallback || !!formError) && (
                      <>
                        <div lang={publicForm?.LanguageCode}>
                          <h1>{publicForm?.Title}</h1>
                          {publicForm?.Description && (
                            <p>{publicForm?.Description}</p>
                          )}
                          <FormioForm
                            form={form}
                            submission={currentSubmission}
                            onSubmit={onSubmit}
                            onChange={onChange}
                            languageCode={publicForm?.LanguageCode}
                            isRequiredAuthenticationBtn={
                              isRequiredAuthenticationBtn
                            }
                            onFormReady={onFormReady}
                          />
                        </div>
                      </>
                    )}
                  {!renderForm && isConnectionStarted && (
                    <>
                      <h1>{translations.formInaccessibleHeading}</h1>
                      <p>{translations.formInaccessibleText}</p>
                    </>
                  )}
                </>
              ))}
          </div>
        </section>
      </Layout>
    </>
  );
};

export default PublicForm;
