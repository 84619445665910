import React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import { Button } from '@progress/kendo-react-buttons';
import {
  selectHasRepresentations,
  selectSelectedAccount,
  removeSelectedRepresentation,
} from '../api-authorization/representationSlice';
import { selectUser } from '../api-authorization/userSlice';
import { Avatar } from '@progress/kendo-react-layout';
import { Icon } from '@progress/kendo-react-common';
import { useTranslation } from '../../hooks/useTranslation';

export interface AccountButtonProps {
  hasRepresentations?: boolean;
  hasTagline?: boolean;
  nameTagline?: string;
  name?: string;
  type?: 1 | 2;
}

const AccountButton = ({
  hasRepresentations: hasRepresentationsProp,
  hasTagline: hasTaglineProp,
  nameTagline: nameTaglineProp,
  name: nameProp,
  type: typeProp,
}: AccountButtonProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userState = useAppSelector(selectUser);
  const hasRepresentationsSelect = useAppSelector(selectHasRepresentations);
  const hasRepresentations =
    hasRepresentationsProp === undefined
      ? hasRepresentationsSelect
      : hasRepresentationsProp;
  const selectedAccount = useAppSelector(selectSelectedAccount);
  const selectedAccountGuid = selectedAccount?.contactGuid;

  const isLoggedInUser = userState.contactGuid === selectedAccountGuid;

  const translations = {
    nameTagline: useTranslation(`construo.menu.accountNameTagline`, [
      userState.username,
    ]),
  };

  const hasTagline =
    hasTaglineProp === undefined
      ? hasRepresentations && !isLoggedInUser
      : hasTaglineProp;
  const nameTagline =
    nameTaglineProp === undefined ? translations.nameTagline : nameTaglineProp;
  const accountName = nameProp === undefined ? selectedAccount?.name : nameProp;
  const userName = nameProp === undefined ? userState.username : nameProp;
  const type = typeProp === undefined ? selectedAccount?.type : typeProp;
  const icon = type === 2 ? 'building' : 'user';

  const handleButtonClick = () => {
    dispatch(removeSelectedRepresentation());
    navigate('/');
  };

  return (
    <>
      <Button
        className='account-button'
        fillMode={'flat'}
        disabled={!hasRepresentations}
        onClick={handleButtonClick}
      >
        <div className='account-button-text'>
          {hasTagline && (
            <span className='tagline text-truncate'>{nameTagline}</span>
          )}
          <span className='name text-truncate'>
            {hasRepresentations ? accountName : userName}
          </span>
        </div>
        <Avatar
          className={'account-button-avatar'}
          type={'icon'}
          fillMode={'outline'}
          border={true}
          themeColor={'light'}
        >
          <Icon className={`far fa-${icon} fa-fw`} />
        </Avatar>
      </Button>
    </>
  );
};

export default AccountButton;
