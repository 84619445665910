import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { Link, useNavigate } from 'react-router-dom';
import useOpenActions from '../../../hooks/useOpenActions';
import {
  fetchConversationsWithUnreadMessages,
  selectConversationsWithUnreadMessages,
  selectConversationsWithUnreadMessagesFetched,
} from '../../workflows/taskDetails/messagesSlice';
import { selectWorkflows } from '../../workflows/workflowSlice';
import { Icon } from '@progress/kendo-react-common';
import envVars from '../../../resources/envVars';
import axios from 'axios';
import authService from '../../api-authorization/AuthorizeService';
import { getAxiosConfig } from '../../../helpers/utils';
import { Loading, setLoading } from '../../../app/slices/loadingSlice';
import { setLoadingMessage } from '../../../app/slices/loadingMessageSlice';
import { resetAlerts, setAlerts } from '../../../app/slices/alertSlice';
import { useInternationalization } from '@progress/kendo-react-intl';
import { useTranslation } from '../../../hooks/useTranslation';

export interface OpenActionListProps {
  hasUnreadMessages?: boolean;
  openActionList?: any;
}

const OpenActionList = ({
  hasUnreadMessages,
  openActionList = null,
}: OpenActionListProps) => {
  const dispatch = useAppDispatch();
  const intl = useInternationalization();
  const navigate = useNavigate();
  const ns = 'construo.inbox';
  const ns2 = 'construo.signatureRequests';
  const translations = {
    yourPendingActivities: useTranslation(`${ns}.yourPendingActivities`),
    noPendingActivities: useTranslation(`${ns}.noPendingActivities`),
    unreadMessage: useTranslation(`${ns}.unreadMessage`),
    signBy: useTranslation(`${ns}.signBy`),
    uploadBy: useTranslation(`${ns}.uploadBy`),

    signatureRequired: useTranslation(`${ns}.signatureRequired`),
    documentRequired: useTranslation(`${ns}.documentRequired`),
    completeForm: useTranslation(`${ns}.completeForm`),
    ongoingApplicationProcess: useTranslation(
      `${ns}.ongoingApplicationProcess`,
    ),

    redirectToESignLoadingText: useTranslation(
      `${ns2}.redirectToESignLoadingText`,
    ),
  };

  const isESignRequestsFeature = envVars.FEATURES?.includes('eSignRequests');
  const isUploadRequestsFeature = envVars.FEATURES?.includes('uploadRequests');
  const isFormSharingFeature = envVars.FEATURES?.includes('formRequests');
  const isWorkflowsFeature = envVars.FEATURES?.includes('workflows');
  const isActionsFeature =
    isESignRequestsFeature ||
    isUploadRequestsFeature ||
    isFormSharingFeature ||
    isWorkflowsFeature;

  const conversationsFetched = useAppSelector(
    selectConversationsWithUnreadMessagesFetched,
  );

  useEffect(() => {
    /**
     * The tricky behavior of useEffect hook in React 18
     * https://medium.com/geekculture/the-tricky-behavior-of-useeffect-hook-in-react-18-282ef4fb570a
     * let ignore
     */
    let ignore = false;
    if (conversationsFetched === null) {
      const API_BASE_URI = envVars.API_BASE_URI || '';
      const unreadMessagesUrl = `${API_BASE_URI}/user/conversations/unreadactivities?page=1&pageSize=99`;
      const dataObject = {
        url: unreadMessagesUrl,
      };
      setTimeout(() => {
        if (!ignore) {
          dispatch(fetchConversationsWithUnreadMessages(dataObject));
        }
      }, 0);
    }
    return () => {
      ignore = true;
    };
  }, [dispatch, conversationsFetched]);

  useEffect(() => {
    return () => {
      dispatch(resetAlerts());
    };
  }, [dispatch]);

  const workflows = useAppSelector(selectWorkflows);
  const conversations = useAppSelector(selectConversationsWithUnreadMessages);

  const findIfWorkflowHasUnreadMessages = (workflowId: string) => {
    const workflow = workflows?.filter(
      (workflow: any) => workflow.id === workflowId,
    )[0];
    const tasksInWorkflow = workflow?.tasks;
    const conversationsWithUnreadMessages = conversations?.filter(
      (conversation: any) => {
        const tasksWithUnreadMessages = tasksInWorkflow?.filter(
          (task: any) => conversation.resourceName === task.id,
        );
        return tasksWithUnreadMessages.length > 0;
      },
    );
    const amountsOfUnreadMessagesPerConversation =
      conversationsWithUnreadMessages?.map((c: any) => {
        const unreadMessagesNo = !!c && c?.numberOfUnreadActivities;
        return !!unreadMessagesNo ? unreadMessagesNo : 0;
      });
    const totalUnreadMessagesPerWorkflow =
      amountsOfUnreadMessagesPerConversation?.reduce(
        (partialSum: number, a: number) => partialSum + a,
        0,
      );
    return totalUnreadMessagesPerWorkflow > 0;
  };

  const openActionsHook = useOpenActions();

  openActionList = openActionList === null ? openActionsHook : openActionList;

  const openActions = openActionList?.map((action: any) => {
    let goToUrl: string = '';
    const actionId = action.id;
    const actionDisplayName = action.displayName;
    const actionType = action.type;
    let actionIcon;
    let actionName;
    let beforeText;
    switch (actionType) {
      case 'signature':
        actionIcon = 'file-signature';
        actionName = translations.signatureRequired;
        beforeText = translations.signBy;
        break;
      case 'upload':
        actionIcon = 'cloud-upload';
        actionName = translations.documentRequired;
        // beforeText = translations.uploadBy;

        break;
      case 'submission':
        actionIcon = 'sparkles';
        actionName = translations.completeForm;
        goToUrl = `/user/submissions/${actionId}`;
        break;
      case 'workflow':
        actionIcon = 'business-time';
        actionName = translations.ongoingApplicationProcess;
        goToUrl = `/user/workflows/${actionId}`;
        break;
      default:
      // code
    }

    const title = action.displayName;

    let before: string | null | undefined = action.before;
    before = before?.replace(/\./g, ':'); // replace all dots (.) with (:)
    before = before?.replace(/:([^:]*)$/, '.$1'); // replace last (:) with (.)
    before = !!before
      ? intl.formatDate(new Date(before as string), { date: 'short' })
      : null;

    let workflowHasUnreadMessages = false;
    if (actionType === 'workflow') {
      workflowHasUnreadMessages = findIfWorkflowHasUnreadMessages(action.id);
    }

    const showUnreadMessageFlag =
      (!!hasUnreadMessages && actionType === 'workflow') ||
      workflowHasUnreadMessages;

    const onActionClick = async (
      e: React.MouseEvent,
      id: string,
      type: string,
      goToUrl: string,
    ) => {
      e.preventDefault();
      if (type === 'signature') {
        const signingRequestsBaseUri =
          envVars.API_BASE_URI + '/user/signing-requests/';
        const token = await authService.getAccessToken();
        const config = getAxiosConfig(token, 'json');
        const url = signingRequestsBaseUri + id + '/redirectUrl';
        dispatch(setLoading(Loading.Show));
        dispatch(setLoadingMessage(translations.redirectToESignLoadingText));
        await axios
          .get(url, config)
          .then(response => {
            window.location.assign(response.data);
          })
          .catch(error => {
            dispatch(setLoading(Loading.Hide));
            dispatch(setLoadingMessage(null));
            // Show error message after submission fails
            dispatch(setAlerts({ message: error.message, type: 'error' }));
            // And scroll to top so that alert is visible
            window.scrollTo(0, 0);
          });
      } else {
        navigate(goToUrl);
      }
    };

    return actionType !== 'upload' ? (
      <Link
        className='list-group-item list-group-item-action with-badge'
        key={actionId}
        to='/'
        onClick={e => onActionClick(e, actionId, actionType, goToUrl)}
      >
        <span className='icon-text'>
          <Icon className={`list-item-icon far fa-${actionIcon} fa-fw`} />
          <span className='list-item-text'>
            <span className='action-name text-truncate'>{actionName}</span>
            <span className='title text-truncate' title={title}>
              {title}
            </span>
          </span>
        </span>

        {!!before && actionType === 'signature' && (
          <span className='list-item-badge'>{`${beforeText} ${before}`}</span>
        )}

        {showUnreadMessageFlag && (
          <span className='list-item-badge'>{translations.unreadMessage}</span>
        )}
      </Link>
    ) : (
      <Link
        className='list-group-item list-group-item-action'
        key={actionId}
        to={`/upload-action-details?actionId=${actionId}&displayName=${actionDisplayName}&url=${goToUrl}`}
      >
        <Icon className={`list-item-icon far fa-${actionIcon} fa-fw`} />
        <span className='list-item-text'>
          <span className='action-name text-truncate'>{actionName}</span>
          <span className='title text-truncate' title={title}>
            {title}
          </span>
        </span>
      </Link>
    );
  });

  const OpenActionListContainer = () => {
    return (
      <>
        <h6>{translations.yourPendingActivities}</h6>
        <div className='list-group open-action-list'>{openActions}</div>
      </>
    );
  };

  return isActionsFeature ? (
    openActionList?.length > 0 ? (
      <OpenActionListContainer />
    ) : (
      <p>{translations.noPendingActivities}</p>
    )
  ) : null;
};

export default OpenActionList;
