import React from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { Skeleton } from '@progress/kendo-react-indicators';
import { useTranslation } from '../../../hooks/useTranslation';

const PortfoliosSkeleton = () => {
  const ns = 'construo.portfolios';
  const translations = {
    title: useTranslation(`${ns}.title`),
    tabAllocationTitle: useTranslation(`${ns}.tabAllocationTitle`),
    tabHoldingsTitle: useTranslation(`${ns}.tabHoldingsTitle`),
    tabPerformanceTitle: useTranslation(`${ns}.tabPerformanceTitle`),
    tabIndexTitle: useTranslation(`${ns}.tabIndexTitle`),
  };
  return (
    <>
      <h2>
        <Skeleton shape={'text'} style={{ maxWidth: 300, height: 60 }} />
      </h2>
      <h3 className='portfolio-value'>
        <Skeleton shape={'text'} style={{ maxWidth: 100, height: 40 }} />
      </h3>
      <TabStrip selected={0}>
        <TabStripTab title={translations.tabAllocationTitle}>
          <p>
            <Skeleton shape={'text'} />
          </p>
          <Skeleton shape={'rectangle'} style={{ height: 300 }} />
        </TabStripTab>
        <TabStripTab title={translations.tabHoldingsTitle}></TabStripTab>
        <TabStripTab title={translations.tabPerformanceTitle}></TabStripTab>
        <TabStripTab title={translations.tabIndexTitle}></TabStripTab>
      </TabStrip>
      <p>
        <Skeleton shape={'text'} />
      </p>
    </>
  );
};

export default PortfoliosSkeleton;
