import React from 'react';
import UploadActionDetails from './UploadActionDetails';
import { useTranslation } from '../../../hooks/useTranslation';

const UploadActionDetailsProvider = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const actionId = urlParams.get('actionId');
  const actionDisplayName = urlParams.get('displayName');
  const actionUrl = urlParams.get('url');
  const ns = 'construo.inbox';
  const translations = {
    uploadActionDetailsTitle: useTranslation(`${ns}.uploadActionDetailsTitle`),
    uploadActionDetailsText: useTranslation(`${ns}.uploadActionDetailsText`),
    uploadActionDetailsNote: useTranslation(`${ns}.uploadActionDetailsNote`),
    uploadActionDetailsButtonLabel: useTranslation(
      `${ns}.uploadActionDetailsButtonLabel`,
    ),
  };
  return (
    <UploadActionDetails
      actionId={actionId}
      actionDisplayName={actionDisplayName}
      actionUrl={actionUrl}
      title={translations.uploadActionDetailsTitle}
      text={translations.uploadActionDetailsText}
      note={translations.uploadActionDetailsNote}
      buttonLabel={translations.uploadActionDetailsButtonLabel}
    />
  );
};

export default UploadActionDetailsProvider;
