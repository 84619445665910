import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import HelmetTitle from '../../common/HelmetTitle';
import { Navigate, useLocation } from 'react-router-dom';
import { resetAlerts, setAlerts } from '../../../app/slices/alertSlice';
import { resetConversationError } from '../inbox/conversationsSlice';
import { resetWorkflowError } from '../../workflows/workflowSlice';
import { resetSubmissionError } from '../submission-forms/submissionSlice';
import Layout from '../../layout/Layout';
import { Card, CardBody, CardSubtitle } from '@progress/kendo-react-layout';
import LanguageSwitcher from '../../common/LanguageSwitcher';
import { useTranslation } from '../../../hooks/useTranslation';

const ResourceNotFound = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const guid = params.get('guid');
  const type = params.get('type');
  const errorMessage = params.get('error');

  useEffect(() => {
    if (errorMessage) {
      dispatch(setAlerts({ message: errorMessage, type: 'error' }));
    }
    return () => {
      dispatch(resetAlerts());
      switch (type) {
        case 'submission':
          dispatch(resetSubmissionError());
          break;
        case 'workflow':
          dispatch(resetWorkflowError());
          break;
        case 'task':
          //
          break;
        case 'conversation':
          dispatch(resetConversationError());
          break;

        default:
          break;
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const ns = 'construo.global';
  const translations: any = {
    submissionType: useTranslation(`${ns}.submissionType`),
    workflowType: useTranslation(`${ns}.workflowType`),
    taskType: useTranslation(`${ns}.taskType`),
    conversationType: useTranslation(`${ns}.conversationType`),
    documentType: useTranslation(`${ns}.documentType`),
    resource: useTranslation(`${ns}.resource`),
    type: useTranslation(`${ns}.type`),
    id: useTranslation(`${ns}.id`),

    invalid: useTranslation(`${ns}.invalid`),
  };

  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  let isIdValid: boolean = true;

  let resourceType: any = translations.resource;

  switch (type) {
    case 'submission':
      resourceType = translations.submissionType;
      isIdValid = !!guid && regexExp.test(guid);
      break;
    case 'workflow':
      resourceType = translations.workflowType;
      isIdValid = !!guid && regexExp.test(guid);
      break;
    case 'task':
      resourceType = translations.taskType;
      isIdValid = !!guid && regexExp.test(guid);
      break;
    case 'conversation':
      resourceType = translations.conversationType;
      isIdValid = !!guid && regexExp.test(guid);
      break;
    case 'document':
      resourceType = translations.documentType;
      // isIdValid = true;
      break;
    default:
      break;
  }

  translations['resource404Title'] = useTranslation(
    `${ns}.resource404Title`,
    resourceType,
  );
  translations['resource404Text'] = useTranslation(
    `${ns}.resource404Text`,
    resourceType,
  );

  return (
    <>
      <HelmetTitle title={translations.resource404Title} />

      {!guid ? (
        <Navigate to={'error'} />
      ) : (
        <Layout
          isSplashLayout={true}
          layoutClassName='resource-not-found-layout'
        >
          <div className='main-section resource-not-found'>
            <div className='container'>
              <Card>
                <CardBody>
                  <h1 className='k-card-title'>
                    {translations.resource404Title}
                  </h1>
                  <CardSubtitle>{translations.resource404Text}</CardSubtitle>
                  <p className='small'>
                    <strong>{`${translations.resource} ${translations.id}: `}</strong>
                    {isIdValid ? guid : `${guid} (${translations.invalid})`}
                    <br />
                    <strong>{`${translations.resource} ${translations.type}: `}</strong>
                    {resourceType}
                  </p>
                </CardBody>
              </Card>
              <LanguageSwitcher />
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default ResourceNotFound;
