import React from 'react';
import { useAppSelector } from '../../hooks/reduxHooks';
import { Navigate } from 'react-router-dom';
import {
  ApplicationPaths,
  QueryParameterNames,
} from './ApiAuthorizationConstants';
import { selectUser } from './userSlice';
import { selectSelectedAccount } from './representationSlice';
import { selectHasRepresentations } from './representationSlice';

interface Props {
  path?: string;
  children: JSX.Element;
}

export const AuthorizeRoute = (props: Props) => {
  const { children, path } = props;

  const userState = useAppSelector(selectUser);
  const isLoggedIn = userState.isLoggedIn;
  const hasRepresentations = useAppSelector(selectHasRepresentations);
  const selectedAccount = useAppSelector(selectSelectedAccount);
  const isSelectedAccount = selectedAccount !== null;

  const redirectUrl = `${ApplicationPaths.Login}?${
    QueryParameterNames.ReturnUrl
  }=${encodeURIComponent(window.location.href)}`;
  const homeUrl = ApplicationPaths.DefaultLoginRedirectPath;

  const redirectUrlExternal = `${ApplicationPaths.DefaultLoginRedirectPath}?${
    QueryParameterNames.ReturnUrl
  }=${encodeURIComponent(window.location.href)}`;

  if (isLoggedIn && hasRepresentations) {
    if (isSelectedAccount) {
      return children;
    } else if (!isSelectedAccount && path !== homeUrl) {
      return <Navigate to={`${redirectUrlExternal}`} />;
    } else if (!isSelectedAccount && path === homeUrl) {
      return children;
    }
  } else if (isLoggedIn && !hasRepresentations) {
    return children;
  } else if (!isLoggedIn) {
    return <Navigate to={redirectUrl} />;
  }

  return children;
};
