import { Dispatch } from 'react';
import { Loading, setLoading } from '../app/slices/loadingSlice';
import { setLoadingMessage } from '../app/slices/loadingMessageSlice';
import authService from '../components/api-authorization/AuthorizeService';
import { getAxiosConfig } from './utils';
import envVars from '../resources/envVars';
import axios from 'axios';
import { Modal, setModal } from '../app/slices/modalSlice';
import { setAlerts } from '../app/slices/alertSlice';
import { setPreviewProps } from '../components/pages/documents/documentsSlice';

export type ItemProps = {
  _id: string;
  Category: string;
  DocumentName: string;
  FileExtension: string;
  Contacts: object[];
};

const API_BASE_URI = envVars.API_BASE_URI;

const showDocument = (
  blob: any,
  name: string,
  ext: string,
  category: string,
  contacts: object[],
  dispatch: Dispatch<any>
) => {
  const newBlob = new Blob([blob], { type: blob.type });

  // // IE doesn't allow using a blob object directly as link href
  // // instead it is necessary to use msSaveOrOpenBlob
  // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //   window.navigator.msSaveOrOpenBlob(newBlob);
  //   return;
  // }
  // // For other browsers:
  // // Create a link pointing to the ObjectURL containing the blob.
  // const data = window.URL.createObjectURL(newBlob);

  const fileURL = window.URL.createObjectURL(newBlob);

  dispatch(setLoading(Loading.Hide));
  dispatch(setLoadingMessage(null));

  dispatch(
    setPreviewProps({
      docType: blob.type,
      url: fileURL,
      name: `${name}${ext}`,
      category: category,
      contacts: contacts,
    })
  );

  const link = document.createElement('a');
  link.textContent = ` ${name}${ext}`;
  link.href = fileURL;
  link.setAttribute('target', '_blank');
  const appendContainer: HTMLElement | null =
    document.getElementById('append-container');
  appendContainer?.appendChild(link);
};

export const handleDocumentClick = async (
  e: React.MouseEvent,
  item: ItemProps,
  dispatch: Dispatch<any>,
  loadingMessage: string
) => {
  e.preventDefault();

  dispatch(setLoading(Loading.Show));
  dispatch(setLoadingMessage(loadingMessage));

  const name = item.DocumentName;
  const ext = item.FileExtension;
  const documentCategory = item.Category;
  const token = await authService.getAccessToken();
  const config = getAxiosConfig(token, 'blob');
  const url =
    API_BASE_URI + '/user/documents/CustomerPortal/' + item._id + '/download';
  const contacts = item?.Contacts;

  await axios
    .get(url, config)
    .then((response: any) => response.data)
    .then((blob: any) => {
      dispatch(setModal(Modal.Show));
      showDocument(blob, name, ext, documentCategory, contacts, dispatch);
    })
    .catch(error => {
      // Hide and reset loading msg
      dispatch(setLoading(Loading.Hide));
      dispatch(setLoadingMessage(null));
      // Show error message if getting document fails
      dispatch(setAlerts({ message: error.toJSON().message, type: 'error' }));
      // And scroll to top so that alert is visible
      window.scrollTo(0, 0);
    });
};
