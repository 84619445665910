import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axios from 'axios';
import {
  CompositeFilterDescriptor,
  SortDescriptor,
} from '@progress/kendo-data-query';
import { getAxiosConfig } from '../../../helpers/utils';
import authService from '../../api-authorization/AuthorizeService';

export type DataObjectProps = {
  baseUrl: string | null;
  recent?: number | null;
  isUserContactGuid?: boolean;
  otherContactGuids?: (string | null)[];
  selectedContactGuids?: any[] | null;
  categoryName?: string;
  last?: boolean;
};

// Gateway API version - getting documents from Gateway API
export const populateDocumentsData = createAsyncThunk(
  'populateDocumentsData',
  async (dataObject: DataObjectProps) => {
    const token = await authService.getAccessToken();
    const config = getAxiosConfig(token, 'json');
    const url = `${dataObject.baseUrl}/user/documents/`;
    const response = await axios.get(url, config);
    const data = JSON.parse(response.data.results);
    return data;
  }
);

// Gateway API version - getting latest (recent) documents from Gateway API
export const populateRecentDocumentsData = createAsyncThunk(
  'populateRecentDocumentsData',
  async (dataObject: DataObjectProps) => {
    const token = await authService.getAccessToken();
    const config = getAxiosConfig(token, 'json');
    const selectedContactGuids = dataObject.selectedContactGuids;
    const otherContactGuids = dataObject.otherContactGuids;
    const recent = dataObject.recent;
    const url = dataObject.isUserContactGuid
      ? `${dataObject.baseUrl}/user/documents?pageSize=${recent}&contactGuidsIn=${selectedContactGuids}&contactGuidsNotIn=${otherContactGuids}`
      : `${dataObject.baseUrl}/user/documents?pageSize=${recent}&contactGuidsIn=${selectedContactGuids}`;
    const response = await axios.get(url, config);
    const data = JSON.parse(response.data.results);
    return data;
  }
);

// Gateway API version - getting latest (recent) publications from Gateway API
export const populateRecentPublicationsData = createAsyncThunk(
  'populateRecentPublicationsData',
  async (dataObject: DataObjectProps) => {
    const token = await authService.getAccessToken();
    const config = getAxiosConfig(token, 'json');
    const categoryName = dataObject.categoryName;
    const recent = dataObject.recent;
    const url = `${dataObject.baseUrl}/user/documents/publications?pageSize=${recent}&category=${categoryName}`;
    const response = await axios.get(url, config);
    const data = response.data;
    return data;
  }
);

interface DocumentsDataState {
  pageNumber: number;
  filter: {
    logic: 'and' | 'or';
    filters: object[];
  };
  filterStatus: boolean;
  allDocuments: object[];
  allDocumentsError: string | null;
  allDocumentsFetched: boolean | null;
  latestDocuments: object[];
  latestDocumentsError: string | null;
  latestDocumentsFetched: boolean | null;
  latestPublications: {
    [key: string]: object[];
  };
  latestPublicationsError: string | null;
  latestPublicationsFetched: boolean | null;
  sort: SortDescriptor[];
  currentSortOption: number;
  previewProps: {
    docType: string;
    url: string;
    name: string;
    category: string;
    contacts: object[];
  };
  bulkDownloadItems: object[];
}

const initialState: DocumentsDataState = {
  pageNumber: 1,
  filter: {
    logic: 'and',
    filters: [],
  } as CompositeFilterDescriptor,
  filterStatus: false,
  allDocuments: [],
  allDocumentsError: null,
  allDocumentsFetched: null,
  latestDocuments: [],
  latestDocumentsError: null,
  latestDocumentsFetched: null,
  latestPublications: {},
  latestPublicationsError: null,
  latestPublicationsFetched: null,
  sort: [{ field: 'DocumentDate', dir: 'desc' }],
  currentSortOption: 1,
  previewProps: {
    docType: '',
    url: '',
    name: '',
    category: '',
    contacts: [],
  },
  bulkDownloadItems: [],
};

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setStateFilters: (state, action) => {
      state.filter = action.payload;
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
    setFiltersStatus: (state, action) => {
      state.filterStatus = action.payload;
    },
    setStateSort: (state, action) => {
      state.sort = action.payload;
    },
    setCurrentSortOption: (state, action) => {
      state.currentSortOption = action.payload;
    },
    setPreviewProps: (state, action) => {
      state.previewProps = action.payload;
    },
    setBulkDownloadItems: (state, action) => {
      state.bulkDownloadItems = action.payload;
    },
    resetFilters: state => {
      state.filter.filters = [];
    },
    clearAllDocumentsError: state => {
      state.allDocumentsError = null;
    },
    clearLatestDocumentsError: state => {
      state.latestDocumentsError = null;
    },
    clearLatestPublicationsError: state => {
      state.latestPublicationsError = null;
    },
    setLatestDocumentsFetched: (state, action) => {
      state.latestDocumentsFetched = action.payload;
    },
    setLatestPublicationsFetched: (state, action) => {
      state.latestPublicationsFetched = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(populateDocumentsData.pending, (state, action) => {
        // console.log('DOCUMENTS LOADING');
        state.allDocumentsFetched = false;
      })
      .addCase(populateDocumentsData.fulfilled, (state, action) => {
        // console.log('DOCUMENTS FULFILLED');
        state.allDocuments = action.payload;
        state.allDocumentsFetched = true;
      })
      .addCase(populateDocumentsData.rejected, (state, action) => {
        state.allDocumentsFetched = true;
        // console.log('DOCUMENTS REJECTED', action);
        state.allDocumentsError = !!action.error.message
          ? `Fetch Documents: ${action.error.message}`
          : null;
      })
      .addCase(populateRecentDocumentsData.pending, (state, action) => {
        // console.log('LATEST DOCUMENTS LOADING');
        state.latestDocumentsFetched = false;
      })
      .addCase(populateRecentDocumentsData.fulfilled, (state, action) => {
        // console.log('LATEST DOCUMENTS FULFILLED');
        state.latestDocuments = action.payload;
        state.latestDocumentsFetched = true;
      })
      .addCase(populateRecentDocumentsData.rejected, (state, action) => {
        state.latestDocumentsFetched = true;
        // console.log('LATEST DOCUMENTS REJECTED', action);
        state.latestDocumentsError = !!action.error.message
          ? `Fetch Latest Documents: ${action.error.message}`
          : null;
      })
      .addCase(populateRecentPublicationsData.pending, (state, action) => {
        // console.log('LATEST PUBLICATIONS LOADING');
        state.latestPublicationsFetched = false;
      })
      .addCase(populateRecentPublicationsData.fulfilled, (state, action) => {
        // console.log('LATEST PUBLICATIONS FULFILLED');
        const category: string = !!action.meta.arg.categoryName
          ? action.meta.arg.categoryName
          : '';
        const last = action.meta.arg.last;

        let newLatestPublicationsData: any = { ...state };
        if (last) {
          newLatestPublicationsData = {
            ...state,
            latestPublications: {
              ...state.latestPublications,
              [category]: action.payload,
            },
            latestPublicationsFetched: true,
          };
        } else {
          newLatestPublicationsData = {
            ...state,
            latestPublications: {
              ...state.latestPublications,
              [category]: action.payload,
            },
          };
        }
        return newLatestPublicationsData;
      })
      .addCase(populateRecentPublicationsData.rejected, (state, action) => {
        // console.log('LATEST PUBLICATIONS REJECTED', action);
        state.latestPublicationsFetched = true;
        state.latestPublicationsError = !!action.error.message
          ? `Fetch Latest Publications: ${action.error.message}`
          : null;
      });
  },
});

export const {
  setStateFilters,
  setPageNumber,
  setFiltersStatus,
  setStateSort,
  setCurrentSortOption,
  setPreviewProps,
  setBulkDownloadItems,
  resetFilters,
  clearAllDocumentsError,
  clearLatestDocumentsError,
  clearLatestPublicationsError,
  setLatestDocumentsFetched,
  setLatestPublicationsFetched,
} = documentsSlice.actions;

export const selectData = (state: RootState) => state.documents;
export const selectDocumentsData = (state: RootState) =>
  state.documents.allDocuments;
export const selectDocError = (state: RootState) =>
  state.documents.allDocumentsError;
export const selectDocFetched = (state: RootState) =>
  state.documents.allDocumentsFetched;

export const selectLatestDocumentsData = (state: RootState) =>
  state.documents.latestDocuments;
export const selectLatestDocError = (state: RootState) =>
  state.documents.latestDocumentsError;
export const selectLatestDocFetched = (state: RootState) =>
  state.documents.latestDocumentsFetched;

export const selectLatestPublicationsData = (state: RootState) =>
  state.documents.latestPublications;
export const selectLatestPublicationsError = (state: RootState) =>
  state.documents.latestPublicationsError;
export const selectLatestPublicationsFetched = (state: RootState) =>
  state.documents.latestPublicationsFetched;

export const selectDocumentsFilter = (state: RootState) =>
  state.documents.filter;
export const selectFiltersStatus = (state: RootState) =>
  state.documents.filterStatus;
export const selectDocumentsSort = (state: RootState) => state.documents.sort;
export const selectDocumentsCurrentSortOption = (state: RootState) =>
  state.documents.currentSortOption;
export const selectPageNumber = (state: RootState) =>
  state.documents.pageNumber;
export const selectPreviewProps = (state: RootState) =>
  state.documents.previewProps;
export const selectBulkDownloadItems = (state: RootState) =>
  state.documents.bulkDownloadItems;

export default documentsSlice.reducer;
