import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from '../../hooks/useTranslation';

export interface DesktopNavigationProps {
  links?: { label: string; url: string }[];
}

const DesktopNavigation = ({ links }: DesktopNavigationProps) => {
  const ns = 'construo.desktopNavigation';

  const translations = {
    link1Label: useTranslation(`${ns}.link1Label`),
    link2Label: useTranslation(`${ns}.link2Label`),
    link3Label: useTranslation(`${ns}.link3Label`),
    link4Label: useTranslation(`${ns}.link4Label`),
    link5Label: useTranslation(`${ns}.link5Label`),
    link1URL: useTranslation(`${ns}.link1URL`),
    link2URL: useTranslation(`${ns}.link2URL`),
    link3URL: useTranslation(`${ns}.link3URL`),
    link4URL: useTranslation(`${ns}.link4URL`),
    link5URL: useTranslation(`${ns}.link5URL`),
    links: () => {
      let linksArr: DesktopNavigationProps['links'] = [];

      if (translations.link1Label !== '') {
        linksArr.push({
          label: translations.link1Label,
          url: translations.link1URL,
        });
      }

      if (translations.link2Label !== '') {
        linksArr.push({
          label: translations.link2Label,
          url: translations.link2URL,
        });
      }

      if (translations.link3Label !== '') {
        linksArr.push({
          label: translations.link3Label,
          url: translations.link3URL,
        });
      }

      if (translations.link4Label !== '') {
        linksArr.push({
          label: translations.link4Label,
          url: translations.link4URL,
        });
      }

      if (translations.link5Label !== '') {
        linksArr.push({
          label: translations.link5Label,
          url: translations.link5URL,
        });
      }

      return linksArr;
    },
  };

  links = links !== undefined ? links : translations.links();

  return !!links?.length ? (
    <nav className='desktop-navigation'>
      <div className='container'>
        <ul className=''>
          {links?.map((link, index) => (
            <li key={index}>
              <NavLink to={link.url}>{link.label}</NavLink>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  ) : null;
};

export default DesktopNavigation;
