import { nbProductMessages } from '../../../messages/product.nb';

/**
 * Specify Customer translations
 */
export const nbCustomMessages = {
  ...nbProductMessages,
  construo: {
    ...nbProductMessages.construo,
    homepage: {
      ...nbProductMessages.construo.homepage,
      publicHeading: 'Velkommen til Portalen',
    },
    footer: {
      ...nbProductMessages.construo.footer,
      link1Label: '',
      link1URL: '',
      link2Label: '',
      link2URL: '',
    },
  },
};
