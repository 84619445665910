import React from 'react';
import { Skeleton } from '@progress/kendo-react-indicators';

export const RequiredActionSkeleton = () => {
  const item = (
    <div className='list-group-item list-group-item-action action-request'>
      <Skeleton
        shape='rectangle'
        className='k-icon'
        style={{ height: 20, width: 20 }}
      />
      <div className='request-info'>
        <div className='request-info-text'>
          <Skeleton shape='text' style={{ maxWidth: 150 }} />
          <Skeleton shape='text' style={{ maxWidth: 250 }} />
        </div>
      </div>
    </div>
  );

  return (
    <>
      {item}
      {item}
      {item}
    </>
  );
};

export default RequiredActionSkeleton;
