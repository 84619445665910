import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
} from '@progress/kendo-react-layout';
import { useTranslation } from '../../hooks/useTranslation';

enum TaskStatusIconClasses {
  'hidden-icon',
  'quarter-circle',
  'half-circle',
  'three-quarters-circle',
  'check-mark',
}

type TaskStatusIconClassesStrings = keyof typeof TaskStatusIconClasses;

export interface TaskProps {
  id: string;
  displayName: string;
  shortDescription: string | null;
  status: string;
  openUserActions: boolean;
  progress: {
    total: number;
    pendingActionsCount: number;
    doneActionsCount: number;
    errorActionsCount: number;
    skippedActionsCount: number;
    notStartedActionsCount: number;
  };
  conversationsUnreadMessages?: number;
}

const Task = ({
  id,
  displayName,
  shortDescription,
  status,
  openUserActions,
  progress,
  conversationsUnreadMessages,
}: TaskProps) => {
  const actionsTotal = progress.total;
  const actionsDone = progress.doneActionsCount;

  const statusNumber =
    !!actionsDone &&
    !!actionsTotal &&
    Math.round((actionsDone / actionsTotal) * 10) / 10;

  let statusIconClassName: TaskStatusIconClassesStrings = 'hidden-icon';
  let subtitle = shortDescription ? shortDescription : '';
  let subtitleClassName = 'subtitle-short-description';

  const translations = {
    unreadMessages: useTranslation(`construo.workflows.unreadMessages`),
    reviewingSubmission: useTranslation(
      `construo.workflows.reviewingSubmission`,
    ),
    completedSubmission: useTranslation(
      `construo.workflows.completedSubmission`,
    ),
  };

  if (openUserActions) {
    statusIconClassName = 'hidden-icon';
  } else if (typeof statusNumber === 'number' && statusNumber < 0.5) {
    statusIconClassName = 'quarter-circle';
    subtitle = translations.reviewingSubmission;
    subtitleClassName = 'subtitle-system-text';
  } else if (typeof statusNumber === 'number' && statusNumber < 0.75) {
    statusIconClassName = 'half-circle';
    subtitle = translations.reviewingSubmission;
    subtitleClassName = 'subtitle-system-text';
  } else if (typeof statusNumber === 'number' && statusNumber < 1) {
    statusIconClassName = 'three-quarters-circle';
    subtitle = translations.reviewingSubmission;
    subtitleClassName = 'subtitle-system-text';
  } else if (statusNumber === 1) {
    statusIconClassName = 'check-mark';
    subtitle = translations.completedSubmission;
    subtitleClassName = 'subtitle-system-text';
  }

  return (
    <NavLink key={id} to={`/user/tasks/${id}`} className='workflow-task'>
      <Card>
        <CardBody className='workflow-task-body'>
          <div className='task-text'>
            <CardTitle>{displayName}</CardTitle>
            {!!subtitle && (
              <CardSubtitle className={subtitleClassName}>
                {subtitle}
              </CardSubtitle>
            )}
            {!!conversationsUnreadMessages && (
              <span className='badge bg-primary rounded-pill'>
                {translations.unreadMessages}
              </span>
            )}
          </div>

          <div className={`task-status-icon ${statusIconClassName}`} />
        </CardBody>
      </Card>
    </NavLink>
  );
};

export default Task;
