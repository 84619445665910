import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { Input } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import {
  selectDocumentsFilter,
  setPageNumber,
  setStateFilters,
} from './documentsSlice';
import { useTranslation } from '../../../hooks/useTranslation';

type QuickSearchProps = {
  inputValue?: string;
  setInputValue: (...arg: any[]) => void;
};

const QuickSearch = ({ inputValue, setInputValue }: QuickSearchProps) => {
  const dispatch = useAppDispatch();

  const ns = 'construo.documents';
  const translations = {
    filterByDocumentName: useTranslation(`${ns}.filterByDocumentName`),
  };

  const filter = useAppSelector(selectDocumentsFilter);
  // Filter by document name - quick search
  const handleFilterByDocumentName = (value: string) => {
    setInputValue(value);
    dispatch(setPageNumber(1));
    const filtersReduced = filter.filters.filter((item: any) => {
      return item.field !== 'DocumentName';
    });
    dispatch(
      setStateFilters({
        ...filter,
        filters: !!value
          ? [
              ...filtersReduced,
              { field: 'DocumentName', operator: 'contains', value: value },
            ]
          : [...filtersReduced],
      }),
    );
  };

  return (
    <div className='quick-search row'>
      <div className='col-md-12'>
        <Label editorId='documentNameFilter'>
          {translations.filterByDocumentName}
        </Label>
        <Input
          type='text'
          id='documentNameFilter'
          name='documentNameFilter'
          onChange={e => handleFilterByDocumentName(e.value)}
          value={inputValue}
        />
        <i className='fal fa-search' />
      </div>
    </div>
  );
};

export default QuickSearch;
