import React, { useEffect, useMemo } from 'react';
import { useAppDispatch } from '../../../../hooks/reduxHooks';
import { Card, CardBody } from '@progress/kendo-react-layout';
import { Holding } from '../../portfolios/types';
import PortfoliosHeader from './PortfoliosHeader';
import PortfoliosAccordion from './PortfoliosAccordion';
import { resetAlerts, setAlerts } from '../../../../app/slices/alertSlice';
import { useTranslation } from '../../../../hooks/useTranslation';

export interface PortfolioSectionProps {
  isLoading: boolean;
  portfolios: Holding[] | undefined;
  portfoliosHeader?: JSX.Element;
  portfoliosAccordion?: JSX.Element;
  userPortfoliosError: string | null;
}

const PortfoliosSection = ({
  isLoading,
  portfolios,
  portfoliosHeader,
  portfoliosAccordion,
  userPortfoliosError,
}: PortfolioSectionProps) => {
  const dispatch = useAppDispatch();

  const ns = 'construo.homepage.portfoliosSection';
  const ns2 = 'construo.portfolios';
  const translations = {
    title: useTranslation(`${ns}.title`),
    userPortfoliosError: useTranslation(`${ns2}.userPortfoliosError`),
  };

  const totalMarketValue = useMemo(() => {
    // Calculate sum of visible portfolios
    let total = 0;
    portfolios?.forEach(portfolio => {
      portfolio.periodEndMarketValue &&
        (total += portfolio.periodEndMarketValue);
    });
    return total;
  }, [portfolios]);

  const Header = () => {
    return portfoliosHeader === undefined ? (
      <PortfoliosHeader
        isLoading={isLoading}
        totalMarketValue={totalMarketValue}
      />
    ) : (
      portfoliosHeader
    );
  };

  const Accordion = () => {
    return portfoliosAccordion === undefined ? (
      <PortfoliosAccordion isLoading={isLoading} portfolios={portfolios} />
    ) : (
      portfoliosAccordion
    );
  };

  useEffect(() => {
    if (!!userPortfoliosError) {
      dispatch(
        setAlerts({
          message: `${translations.userPortfoliosError} ${userPortfoliosError}`,
          type: 'error',
        }),
      );
    }
  }, [dispatch, userPortfoliosError, translations.userPortfoliosError]);

  useEffect(() => {
    return () => {
      dispatch(resetAlerts());
    };
  }, [dispatch]);

  return (
    <>
      <section className='portfolios-section main-section'>
        <div className='container'>
          {!!translations.title && <h2>{translations.title}</h2>}
          <Card>
            <CardBody>
              <Header />
              <Accordion />
            </CardBody>
          </Card>
        </div>
      </section>
    </>
  );
};

export default PortfoliosSection;
