import { fiProductMessages } from '../../../messages/product.fi';

export const fiCustomMessages = {
  ...fiProductMessages,
  construo: {
    ...fiProductMessages.construo,
    footer: {
      ...fiProductMessages.construo.footer,
      link1Label: '',
      link1URL: '',
      link2Label: '',
      link2URL: '',
    },
  },
};
