import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axios from 'axios';
import { getAxiosConfig } from '../../../helpers/utils';
import authService from '../../api-authorization/AuthorizeService';

// Gateway API version - getting user conversation specific Messages from Gateway API
export const fetchMessages = createAsyncThunk(
  'fetchMessages',
  async (dataObject: any, thunkAPI) => {
    const token = await authService.getAccessToken();
    const url = dataObject.url;
    const config = getAxiosConfig(token, 'json');
    try {
      const response = await axios.get(url, config);
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Gateway API version - getting user conversations which have unread messages - from Gateway API
export const fetchConversationsWithUnreadMessages = createAsyncThunk(
  'fetchConversationsWithUnreadMessages',
  async (dataObject: any, thunkAPI) => {
    const token = await authService.getAccessToken();
    const url = dataObject.url;
    const config = getAxiosConfig(token, 'json');
    try {
      const response = await axios.get(url, config);
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

interface MessagesState {
  data: any;
  messagesFetched: boolean | null;
  messagesError?: string;
  conversationsWithUnreadMessages: any;
  conversationsWithUnreadMessagesFetched: boolean | null;
  conversationsError?: string;
  totalNumberOfConversations: number | null;
}

const initialState: MessagesState = {
  data: [],
  messagesFetched: null,
  conversationsWithUnreadMessages: null,
  conversationsWithUnreadMessagesFetched: null,
  totalNumberOfConversations: null,
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    resetMessagesData: state => {
      state.data = [];
      state.messagesFetched = null;
    },
    resetConversationsWithUnreadMessages: state => {
      state.conversationsWithUnreadMessages = null;
      state.conversationsWithUnreadMessagesFetched = null;
      state.totalNumberOfConversations = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchMessages.pending, (state, action) => {
        // console.log('MESSAGES LOADING');
        state.messagesFetched = false;
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        // console.log('MESSAGES FULFILLED');
        state.data = action.payload;
        state.messagesFetched = true;
      })
      .addCase(fetchMessages.rejected, (state, action: any) => {
        state.messagesFetched = true;
        // console.log('MESSAGES REJECTED', action);
        state.messagesError = action?.payload?.response?.data?.Message;
      })
      .addCase(
        fetchConversationsWithUnreadMessages.pending,
        (state, action) => {
          // console.log('CONVERSATIONS WITH UNREAD MESSAGES LOADING');
          state.conversationsWithUnreadMessagesFetched = false;
        }
      )
      .addCase(
        fetchConversationsWithUnreadMessages.fulfilled,
        (state, action) => {
          // console.log('CONVERSATIONS WITH UNREAD MESSAGES FULFILLED');
          state.conversationsWithUnreadMessages = action.payload.data;
          state.totalNumberOfConversations = action.payload.totalNumberOfItems;
          state.conversationsWithUnreadMessagesFetched = true;
        }
      )
      .addCase(
        fetchConversationsWithUnreadMessages.rejected,
        (state, action: any) => {
          state.conversationsWithUnreadMessagesFetched = true;
          // console.log('CONVERSATIONS WITH UNREAD MESSAGES REJECTED', action);
          state.conversationsError = action?.payload?.response?.data?.Message;
        }
      );
  },
});

export const {
  resetMessagesData,
  resetConversationsWithUnreadMessages,
} = messagesSlice.actions;

export const selectMessages = (state: RootState) => state.messages.data;
export const selectMessagesFetched = (state: RootState) =>
  state.messages.messagesFetched;
export const selectMessagesError = (state: RootState) =>
  state.messages.messagesError;

export const selectConversationsWithUnreadMessages = (state: RootState) =>
  state.messages.conversationsWithUnreadMessages;

export const selectConversationsWithUnreadMessagesFetched = (
  state: RootState
) => state.messages.conversationsWithUnreadMessagesFetched;

export const selectConversationsTotal = (state: RootState) =>
  state.messages.totalNumberOfConversations;

export default messagesSlice.reducer;
