import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import { setAlerts } from '../app/slices/alertSlice';
import {
  fetchSignatureRequests,
  selectSignaturesFetched,
  selectSignatures,
  selectDigitalIdentity,
  DigitalIdentityProps,
  selectSignatureError,
} from '../components/signatures/signaturesSlice';
import {
  fetchSubmissionRequests,
  selectSubmissionsFetched,
  selectSubmissions,
  selectSubmissionsError,
} from '../components/submissions/submissionsSlice';
import {
  fetchUploadRequests,
  selectUploadsFetched,
  selectUploads,
  selectUploadError,
} from '../components/upload-requests/uploadSlice';
import {
  fetchWorkflows,
  selectWorkflowsFetched,
  selectWorkflows,
  selectWorkflowsError,
} from '../components/workflows/workflowSlice';
import envVars from '../resources/envVars';
import { useTranslation } from './useTranslation';

const useOpenActions = () => {
  const dispatch = useAppDispatch();
  const ns = 'construo.signatureRequests';
  const translations = {
    userMissingNinAndBankId: useTranslation(`${ns}.userMissingNinAndBankId`),
    userMissingNinAndLocal: useTranslation(`${ns}.userMissingNinAndLocal`),
  };

  const isESignRequestsFeature = envVars.FEATURES?.includes('eSignRequests');
  const isUploadRequestsFeature = envVars.FEATURES?.includes('uploadRequests');
  const isFormSharingFeature = envVars.FEATURES?.includes('formRequests');
  const isWorkflowsFeature = envVars.FEATURES?.includes('workflows');

  const signaturesFetched = useAppSelector(selectSignaturesFetched);
  const submissionsFetched = useAppSelector(selectSubmissionsFetched);
  const uploadsFetched = useAppSelector(selectUploadsFetched);
  const workflowsFetched = useAppSelector(selectWorkflowsFetched);

  const signaturesError = useAppSelector(selectSignatureError);
  const uploadsError = useAppSelector(selectUploadError);
  const submissionsError = useAppSelector(selectSubmissionsError);
  const workflowsError = useAppSelector(selectWorkflowsError);

  const digitalIdentity: DigitalIdentityProps | null = useAppSelector(
    selectDigitalIdentity,
  );

  const API_BASE_URI: string = envVars.API_BASE_URI || '';

  useEffect(() => {
    const nin = digitalIdentity?.nin;
    const idp = digitalIdentity?.idp;
    const isUserMissingNinOrIdp = !nin || !idp?.includes('bankid');
    if (signaturesFetched === null && !isUserMissingNinOrIdp) {
      const dataObject = {
        baseUrl: API_BASE_URI,
      };
      dispatch(fetchSignatureRequests(dataObject));
    } else if (signaturesFetched === null && isUserMissingNinOrIdp) {
      const isUserMissingNinAndBankId = !nin && idp?.includes('bankid');
      const isUserMissingNinAndLocal = !nin && idp === 'local';
      let message;
      const isMessageRead = sessionStorage.getItem('isMessageRead') === 'true';
      if (isUserMissingNinAndBankId) {
        message = translations.userMissingNinAndBankId;
      } else if (isUserMissingNinAndLocal) {
        message = translations.userMissingNinAndLocal;
      }
      if (!!message && !isMessageRead) {
        sessionStorage.setItem('isMessageRead', 'false');
        dispatch(
          setAlerts({ message: message, type: 'info', isMissingNin: true }),
        );
      }
    }
  }, [
    dispatch,
    API_BASE_URI,
    signaturesFetched,
    digitalIdentity,
    translations.userMissingNinAndBankId,
    translations.userMissingNinAndLocal,
  ]);

  useEffect(() => {
    if (uploadsFetched === null) {
      const dataObject = {
        baseUrl: API_BASE_URI,
      };
      dispatch(fetchUploadRequests(dataObject));
    }
  }, [dispatch, API_BASE_URI, uploadsFetched]);

  useEffect(() => {
    if (submissionsFetched === null) {
      const dataObject = {
        baseUrl: API_BASE_URI,
      };
      dispatch(fetchSubmissionRequests(dataObject));
    }
  }, [dispatch, API_BASE_URI, submissionsFetched]);

  useEffect(() => {
    if (workflowsFetched === null) {
      const dataObject = {
        baseUrl: API_BASE_URI,
      };
      dispatch(fetchWorkflows(dataObject));
    }
  }, [dispatch, API_BASE_URI, workflowsFetched]);

  const signatures = useAppSelector(selectSignatures);
  const uploads = useAppSelector(selectUploads);
  const submissions = useAppSelector(selectSubmissions);
  const workflows = useAppSelector(selectWorkflows);

  useEffect(() => {
    if (signaturesFetched && !!signaturesError) {
      dispatch(setAlerts({ message: signaturesError, type: 'error' }));
    }
  }, [dispatch, signaturesFetched, signaturesError]);

  useEffect(() => {
    if (uploadsFetched && !!uploadsError) {
      dispatch(setAlerts({ message: uploadsError, type: 'error' }));
    }
  }, [dispatch, uploadsFetched, uploadsError]);

  useEffect(() => {
    if (submissionsFetched && !!submissionsError) {
      dispatch(setAlerts({ message: submissionsError, type: 'error' }));
    }
  }, [dispatch, submissionsFetched, submissionsError]);

  useEffect(() => {
    if (workflowsFetched && !!workflowsError) {
      dispatch(setAlerts({ message: workflowsError, type: 'error' }));
    }
  }, [dispatch, workflowsFetched, workflowsError]);

  const [openSignatures, setOpenSignatures] = useState<any>(null);
  const [openUploads, setOpenUploads] = useState<any>(null);
  const [openSubmissions, setOpenSubmissions] = useState<any>(null);
  const [openWorkflows, setOpenWorkflows] = useState<any>(null);
  const [openActions, setOpenActions] = useState<any>();

  useEffect(() => {
    const openSign = isESignRequestsFeature
      ? signatures.map((signature: any) => {
          const object = {
            id: signature.taskId,
            displayName: signature?.taskDocumentActions[0].documentName,
            type: 'signature',
            before: signature.expiryDateTime,
          };
          return object;
        })
      : [];
    setOpenSignatures(openSign);
    const openU = isUploadRequestsFeature
      ? uploads.map((upload: any) => {
          const object = {
            id: upload._id,
            displayName: upload.DocumentName,
            type: 'upload',
            before: upload.ExpiryDate,
          };
          return object;
        })
      : [];
    setOpenUploads(openU);
    const openSub = isFormSharingFeature
      ? submissions.map((submission: any) => {
          const object = {
            id: submission.FormSubmissionGuid,
            displayName: submission.Title,
            type: 'submission',
          };
          return object;
        })
      : [];
    setOpenSubmissions(openSub);
    const openW = isWorkflowsFeature
      ? workflows.map((workflow: any) => {
          const object = {
            id: workflow.id,
            displayName: workflow.displayName,
            type: 'workflow',
          };
          return object;
        })
      : [];
    setOpenWorkflows(openW);
  }, [
    isESignRequestsFeature,
    isUploadRequestsFeature,
    isFormSharingFeature,
    isWorkflowsFeature,
    signatures,
    uploads,
    submissions,
    workflows,
  ]);

  useEffect(() => {
    // Combine arrays of open actions in one single array
    const actions = openSignatures?.concat(
      openUploads,
      openSubmissions,
      openWorkflows,
    );
    setOpenActions(actions);
  }, [openSignatures, openUploads, openSubmissions, openWorkflows]);

  return openActions;
};

export default useOpenActions;
