import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import PortfoliosSection from './PortfoliosSection';
import { getPortfoliosDataObject } from '../../../../helpers/utils';
import {
  fetchUserPortfolios,
  selectUserPortfolios,
  selectUserPortfoliosError,
  selectUserPortfoliosFetched,
} from '../../portfolios/portfoliosSlice';
import envVars from '../../../../resources/envVars';
import { Holding } from '../../portfolios/types';
import { selectSelectedAccount } from '../../../api-authorization/representationSlice';
import { useConfig } from '../../../../hooks/useConfig';
import { selectUser } from '../../../api-authorization/userSlice';

const PortfoliosSectionProvider = () => {
  const config = useConfig();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const userState = useAppSelector(selectUser);
  const userContactGuid = userState.contactGuid;

  const selectedAccount = useAppSelector(selectSelectedAccount);

  const selectedContactGuid = !!selectedAccount
    ? selectedAccount.contactGuid
    : userContactGuid;

  const portfoliosColumnsConfig = useMemo(() => {
    return [
      ...config.portfolios.allocationsTable.columns,
      ...config.portfolios.holdingsTable.columns,
      ...config.portfolios.performanceTable.columns,
      ...config.portfolios.performanceTableTwo.columns,
    ];
  }, [config.portfolios]);

  const GATEWAY_API_URL = envVars.GATEWAY_API_URL;
  const portfoliosApiUrl = `${GATEWAY_API_URL}/portfolios`;
  const portfolios: Holding[] | null = useAppSelector(selectUserPortfolios);
  const accountPortfolios: Holding[] | undefined = portfolios?.filter(
    (portfolio: any) => portfolio.contactGuid === selectedContactGuid,
  );
  const hasAccountPortfolios =
    !!accountPortfolios && accountPortfolios.length > 0;

  const isUserPortfoliosFetched = useAppSelector(selectUserPortfoliosFetched);
  const userPortfoliosError = useAppSelector(selectUserPortfoliosError);

  /**
   * Fetch User Portfolios, if not fetched already
   */
  useEffect(() => {
    /**
     * The tricky behavior of useEffect hook in React 18
     * https://medium.com/geekculture/the-tricky-behavior-of-useeffect-hook-in-react-18-282ef4fb570a
     * let ignore
     */
    let ignore = false;
    if (isUserPortfoliosFetched === null) {
      const dataObject = getPortfoliosDataObject(
        selectedContactGuid,
        portfoliosApiUrl,
        portfoliosColumnsConfig,
        config.portfolios.lastUpdatedInfoPlaceholders,
      );
      setTimeout(() => {
        if (!ignore) {
          setIsLoading(true);
          dispatch(fetchUserPortfolios(dataObject));
        }
      }, 0);
    }
    return () => {
      ignore = true;
    };
  }, [
    dispatch,
    portfoliosApiUrl,
    selectedContactGuid,
    isUserPortfoliosFetched,
    portfoliosColumnsConfig,
    config.portfolios.lastUpdatedInfoPlaceholders,
  ]);

  useEffect(() => {
    if (!!portfolios) setIsLoading(false);
  }, [dispatch, portfolios]);

  return (
    <>
      {(!isUserPortfoliosFetched || // needed in order to show skeletons before portfolios are fetched
        hasAccountPortfolios || // show section only if there are portfolios to show
        !!userPortfoliosError) && (
        <PortfoliosSection
          portfolios={accountPortfolios}
          isLoading={isLoading}
          userPortfoliosError={userPortfoliosError}
        />
      )}
    </>
  );
};

export default PortfoliosSectionProvider;
