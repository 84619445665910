import { UserManager, WebStorageStateStore, Log, User } from 'oidc-client';
import { ApplicationName, ApplicationPaths } from './ApiAuthorizationConstants';
import envVars from '../../resources/envVars';

const _callbacks: any = [];
let _nextSubscriptionId: number = 0;

export let userManager: UserManager | undefined;

const AuthorizeService: any = () => {
  const error = (message: string): AuthorizeResultData => {
    return { status: AuthenticationResultStatus.Fail, message };
  };

  const success = (
    message?: string,
    user?: User,
    state?: any,
  ): AuthorizeResultData => {
    return {
      status: AuthenticationResultStatus.Success,
      message,
      user: user,
      state: state,
    };
  };

  const notifySubscribers = () => {
    for (let i = 0; i < _callbacks.length; i++) {
      const callback = _callbacks[i].callback;
      callback();
    }
  };

  const updateState = () => {
    notifySubscribers();
  };

  const ensureUserManagerInitialized = async (acrValues?: string[]) => {
    if (userManager !== undefined && !acrValues) {
      return;
    }

    const postLogoutRedirectUri =
      envVars.CUSTOMER_PORTAL_URL + ApplicationPaths.LogOutCallback;
    const redirectUri =
      envVars.CUSTOMER_PORTAL_URL + ApplicationPaths.LoginCallback;

    let settings: any = {
      authority: envVars.AUTHENTICATION_AUTHORITY,
      automaticSilentRenew: envVars.AUTHENTICATION_AUTOMATIC_SILENT_RENEW,
      client_id: envVars.AUTHENTICATION_CLIENT_ID,
      includeIdTokenInSilentRenew:
        envVars.AUTHENTICATION_INCLUDE_ID_TOKEN_IN_SILENT_RENEW,
      post_logout_redirect_uri: postLogoutRedirectUri,
      redirect_uri: redirectUri,
      response_type: envVars.AUTHENTICATION_RESPONSE_TYPE,
      scope: envVars.AUTHENTICATION_SCOPE,
      silent_redirect_uri: envVars.AUTHENTICATION_SILENT_REDIRECT_URI,
      userStore: new WebStorageStateStore({
        prefix: `${ApplicationName}-`,
        store: window.sessionStorage,
      }),
    };

    if (!!acrValues) {
      settings = {
        ...settings,
        acr_values: `idp:${acrValues.join(' ')}`,
        redirect_uri:
          envVars.CUSTOMER_PORTAL_URL +
          ApplicationPaths.FormAuthenticationCallback,
      };
    }

    userManager = new UserManager(settings);

    userManager.events.addUserSignedOut(async () => {
      await userManager?.removeUser();
      updateState();
    });

    userManager.events.addAccessTokenExpired(async expired => {
      await userManager?.removeUser();
      updateState();
    });

    userManager.events.addSilentRenewError(async error => {
      await userManager?.removeUser();
      updateState();
    });

    if (process.env.NODE_ENV !== 'production') {
      Log.logger = console;
      Log.level = Log.DEBUG;
    }
  };

  const getUser = async () => {
    await ensureUserManagerInitialized();
    const user = await userManager?.getUser();
    if (user?.expired) {
      updateState();
      await userManager?.removeUser();
      return null;
    }
    return user && user.profile;
  };

  const getAccessToken = async () => {
    await ensureUserManagerInitialized();
    const user = await userManager?.getUser();
    return user && user.access_token;
  };

  const getUserContactGuid = async () => {
    await ensureUserManagerInitialized();
    const user = await userManager?.getUser();
    return user && user;
  };

  // We try to authenticate the user in two different ways:
  // 1) We try to see if we can authenticate the user silently. This happens
  //    when the user is already logged in on the IdP
  // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
  //    redirect flow.
  const signIn = async (returnUrl: string, acrValues: string[]) => {
    await ensureUserManagerInitialized(acrValues);
    var user = await getUser();
    if (user && user.refresh_token) {
      try {
        const silentUser = await userManager?.signinSilent();
        return success(undefined, silentUser);
      } catch (silentError) {
        return await signInRedirect(returnUrl);
      }
    } else {
      return await signInRedirect(returnUrl);
    }
  };

  const signInRedirect = async (returnUrl: string) => {
    try {
      await userManager?.signinRedirect({
        useReplaceToNavigate: true,
        data: returnUrl,
      });
      return redirect();
    } catch (redirectError: any) {
      return error(redirectError);
    }
  };

  const completeSignIn = async (url: string) => {
    await ensureUserManagerInitialized();
    try {
      const user = await userManager?.signinCallback(url);
      return success(undefined, user, user?.state);
    } catch (err: any) {
      return error(err.toString());
    }
  };

  // We try to sign out the user in two different ways:
  // 1) We redirect the browser to the IdP to perform a traditional
  //    post logout redirect flow.
  const signOut = async () => {
    await ensureUserManagerInitialized();

    try {
      await userManager?.signoutRedirect();
      return redirect();
    } catch (redirectSignOutError: any) {
      return error(redirectSignOutError.toString());
    }
  };

  const completeSignOut = async (url: string) => {
    await ensureUserManagerInitialized();
    try {
      var response = await userManager?.signoutCallback(url);
      return success(undefined, undefined, response?.state);
    } catch (err: any) {
      return error(err.toString());
    }
  };

  const subscribe = (callback: Function) => {
    _callbacks.push({
      callback,
      subscription: _nextSubscriptionId++,
    });
    return _nextSubscriptionId - 1;
  };

  const unsubscribe = (subscriptionId: any) => {
    const subscriptionIndex = _callbacks
      .map((element: { subscription: any }, index: any) =>
        element.subscription === subscriptionId
          ? { found: true, index }
          : { found: false },
      )
      .filter((element: { found: boolean }) => element.found === true);
    if (subscriptionIndex.length !== 1) {
      throw new Error(
        `Found an invalid number of subscriptions ${subscriptionIndex.length}`,
      );
    }

    _callbacks.splice(subscriptionIndex[0].index, 1);
  };

  const redirect = (): AuthorizeResultData => {
    return { status: AuthenticationResultStatus.Redirect };
  };

  return {
    ensureUserManagerInitialized,
    getUser,
    getUserContactGuid,
    getAccessToken,
    signIn,
    completeSignIn,
    signOut,
    completeSignOut,
    subscribe,
    unsubscribe,
  };
};

export const AuthenticationResultStatus = {
  Redirect: 'redirect',
  Success: 'success',
  Fail: 'fail',
};

export type AuthorizeResultData = {
  status: string;
  message?: string;
  user?: User;
  state?: any;
};

const authService = AuthorizeService();

export default authService;
