import React, { useEffect, useRef } from 'react';
import { Chat } from '@progress/kendo-react-conversational-ui';
import { Skeleton } from '@progress/kendo-react-indicators';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import envVars from '../../../resources/envVars';
import axios from 'axios';
import authService from '../../api-authorization/AuthorizeService';
import { getAxiosConfig } from '../../../helpers/utils';
import { resetAlerts, setAlerts } from '../../../app/slices/alertSlice';
import { resetConversationList } from '../../pages/inbox/conversationsSlice';
import {
  fetchConversationsWithUnreadMessages,
  resetConversationsWithUnreadMessages,
  resetMessagesData,
  selectConversationsWithUnreadMessages,
  selectConversationsWithUnreadMessagesFetched,
} from './messagesSlice';
import { useTranslation } from '../../../hooks/useTranslation';

interface MessagesProps {
  isLoaded: boolean;
  workflowId: string | null;
  taskId: string;
  taskTitle: string | null;
  messages: {
    data: any;
  } | null;
  isScrollIntoView: boolean;
}

const Messages = ({
  isLoaded,
  workflowId,
  taskId,
  taskTitle,
  messages,
  isScrollIntoView,
}: MessagesProps) => {
  const ns = 'construo.workflows';
  const translations = {
    messagesTitle: useTranslation(`${ns}.messagesTitle`),
    initializationText: useTranslation(`${ns}.initializationText`),
    placeholderText: useTranslation(`${ns}.placeholderText`),
    anonymousAuthorName: useTranslation(`${ns}.anonymousAuthorName`),
    readReceiptsError: useTranslation(`${ns}.readReceiptsError`),
    warningConversationStatus: useTranslation(
      `construo.inbox.warningConversationStatus`,
    ),
  };

  const dispatch = useAppDispatch();
  const API_BASE_URI = envVars.API_BASE_URI;

  const messagesData = messages?.data;
  const conversationId = messagesData?.conversationGuid;

  const conversationStatusId = messagesData?.statusId;
  // const conversationStatus = messagesData?.status;
  const isConversationClosed = !conversationStatusId
    ? false
    : conversationStatusId === 1
    ? false
    : true;

  const messagesSource = messagesData?.activities.map((activity: any) => {
    const authorId = activity.userName ? activity.userId : null;

    const authorName = activity.userName
      ? activity.userName
      : translations.anonymousAuthorName;
    const timestamp = new Date(activity.dateCreated);
    const text = activity.content;
    return {
      author: {
        id: authorId,
        name: authorName,
      },
      timestamp: timestamp,
      text,
    };
  });

  const conversationsWithUnreadMessages = useAppSelector(
    selectConversationsWithUnreadMessages,
  );
  const conversationsFetched = useAppSelector(
    selectConversationsWithUnreadMessagesFetched,
  );

  const unreadMessagesUrl = `${API_BASE_URI}/user/conversations/unreadactivities`;

  useEffect(() => {
    return () => {
      dispatch(resetMessagesData());
      dispatch(resetConversationList());
      dispatch(resetAlerts());
    };
  }, [dispatch]);

  useEffect(() => {
    /**
     * The tricky behavior of useEffect hook in React 18
     * https://medium.com/geekculture/the-tricky-behavior-of-useeffect-hook-in-react-18-282ef4fb570a
     * let ignore
     */
    let ignore = false;
    if (conversationsFetched === null) {
      const dataObject = {
        url: unreadMessagesUrl,
      };
      setTimeout(() => {
        if (!ignore) {
          dispatch(fetchConversationsWithUnreadMessages(dataObject));
        }
      }, 0);
    }
    return () => {
      ignore = true;
    };
  }, [dispatch, conversationsFetched, unreadMessagesUrl]);

  const getConversationsUnreadMessages = (taskId: any) => {
    const taskConversation = conversationsWithUnreadMessages?.filter(
      (conversation: any) => conversation.resourceName === taskId,
    )[0];
    const taskConversationUnreadMessages =
      taskConversation?.numberOfUnreadActivities;
    return taskConversationUnreadMessages;
  };
  const unreadMessages = getConversationsUnreadMessages(taskId);

  // let participants: any = {};

  // messagesData?.participants.map((participant: any) => {
  //   const participantName = participant.participantName;
  //   const participantId = participant.userId;
  //   const propertyName = participant.isCurrent ? 'user' : participantId;
  //   participants[propertyName] = {
  //     id: participantId,
  //     name: participantName,
  //     currentId: participant.isCurrent,
  //   };
  //   return false;
  // });

  const mappedParticipants = messagesData?.participants?.map(
    (participant: any) => {
      return {
        id: participant.userId,
        name: participant.participantName,
        isCurrent: participant.isCurrent,
      };
    },
  );

  const currentUser = mappedParticipants?.filter(
    (participant: any) => participant.isCurrent === true,
  )[0];

  const notCurrentUserActivities = messagesData?.activities?.filter(
    (activity: any) => activity.userId !== currentUser.id,
  );

  const lastActivityId = useRef(null);

  lastActivityId.current =
    !!notCurrentUserActivities &&
    notCurrentUserActivities[notCurrentUserActivities.length - 1]?.activityGuid;

  /**
   * 3 second after conversation is loaded trigger "Create read receipt" for last Outbound Activity (activityId) in THE Conversation (conversationId)
   * This will set all unread messages to have status "read"
   */
  useEffect(() => {
    const readReceipts = async () => {
      const activityId = lastActivityId.current;
      const url = `${API_BASE_URI}/user/conversations/${conversationId}/activities/${activityId}/readReceipts`;
      const data = {
        parentId: workflowId,
      };
      const dataStringify = JSON.stringify(data);
      const token = await authService.getAccessToken();
      const config = getAxiosConfig(token, 'json');
      if (!!conversationId && unreadMessages > 0) {
        setTimeout(async () => {
          await axios
            .post(url, dataStringify, config)
            .then(response => response.data)
            .then(response => {
              dispatch(resetConversationsWithUnreadMessages());
              return response;
            })
            .catch(error => {
              // Show error message after Complete Action Event fails
              dispatch(
                setAlerts({
                  message: `${translations.readReceiptsError} ${error.message}`,
                  type: 'error',
                }),
              );
              // And scroll to top so that alert is visible
              window.scrollTo(0, 0);
            });
        }, 3000); // readReceipts event is delayed for 3 sec
      }
    };
    readReceipts();
  }, [
    dispatch,
    API_BASE_URI,
    workflowId,
    conversationId,
    unreadMessages,
    // lastActivityId,
    translations.readReceiptsError,
  ]);

  const addMessage = async (message: any) => {
    let url;
    let data;
    if (!!messagesSource) {
      // Add new message
      url = `${API_BASE_URI}/user/conversations/${conversationId}/activities`;
      data = JSON.stringify({
        activity: {
          content: message.text,
          activityTypeId: 2,
          contentTypeId: 1,
        },
        parentId: workflowId,
      });
    } else {
      // Initiate new conversation with first message
      url = `${API_BASE_URI}/user/conversations`;
      data = JSON.stringify({
        conversation: {
          resourceTypeId: 1,
          resourceName: taskId,
          conversationTypeId: 1,
          friendlyName: taskTitle,
          parentId: workflowId,
        },
        activity: {
          content: message.text,
          activityTypeId: 2,
          contentTypeId: 1,
        },
      });
    }
    const token = await authService.getAccessToken();
    const config = getAxiosConfig(token, 'json');
    try {
      const response = await axios.post(url, data, config);
      const dataResponse = response.data.data;
      dispatch(resetMessagesData());
      return dataResponse;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Show error message after Complete Action Event fails
        const errorResponse = error.message;
        dispatch(setAlerts({ message: errorResponse, type: 'error' }));
        // And scroll to top so that alert is visible
        window.scrollTo(0, 0);
      } else {
        dispatch(
          setAlerts({
            message:
              'Something went wrong. Try again and if error persist contact administrator.',
            type: 'error',
          }),
        );
        // And scroll to top so that alert is visible
        window.scrollTo(0, 0);
      }
    }
  };

  const addNewMessage = (event: any) => {
    addMessage(event.message);
  };

  const conversationRef = useRef(null);
  const element: any = conversationRef.current;

  useEffect(() => {
    if (!!isScrollIntoView && element !== null && conversationsFetched) {
      setTimeout(() => {
        element.scrollTo(0, 0);
        element.scrollIntoView();
        // console.log('conversationRef?.current', conversationRef?.current);
      }, 1000);
    }
  }, [conversationsFetched, element, isScrollIntoView]);

  return (
    <>
      <section className='main-section task-messages' ref={conversationRef}>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 col-xl-9'>
              {isLoaded ? (
                <>
                  <h2 id='conversation'>{translations.messagesTitle}</h2>
                  <Chat
                    className={`conversation ${
                      isConversationClosed && 'disabled'
                    }`}
                    user={currentUser}
                    messages={messagesSource}
                    onMessageSend={addNewMessage}
                    placeholder={
                      !!messagesSource
                        ? translations.placeholderText
                        : translations.initializationText
                    }
                  />
                  {isConversationClosed && (
                    <p className='closed-conversation alert alert-secondary'>
                      <span>{translations.warningConversationStatus}</span>
                    </p>
                  )}
                </>
              ) : (
                <>
                  <h2>
                    <Skeleton shape={'text'} style={{ maxWidth: 300 }} />
                  </h2>
                  <Skeleton shape={'rectangle'} style={{ height: 150 }} />
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Messages;
