import React from 'react';
import HelmetTitle from '../../common/HelmetTitle';
import Layout from '../../layout/Layout';
import { Card, CardBody, CardSubtitle } from '@progress/kendo-react-layout';
import LanguageSwitcher from '../../common/LanguageSwitcher';
import { useTranslation } from '../../../hooks/useTranslation';

function InformationPage() {
  const ns = 'construo.forms';
  const translations = {
    requiredAuthenticationInfoPageHeader: useTranslation(
      `${ns}.requiredAuthenticationInfoPageHeader`,
    ),
    requiredAuthenticationInfoPageText: useTranslation(
      `${ns}.requiredAuthenticationInfoPageText`,
    ),
  };
  return (
    <>
      <HelmetTitle title={translations.requiredAuthenticationInfoPageHeader} />

      <Layout isSplashLayout={true} layoutClassName='not-found-layout'>
        <div className='main-section not-found'>
          <div className='container'>
            <Card>
              <CardBody>
                <h1 className='k-card-title'>
                  {translations.requiredAuthenticationInfoPageHeader}
                </h1>
                <CardSubtitle>
                  {translations.requiredAuthenticationInfoPageText}
                </CardSubtitle>
              </CardBody>
            </Card>
            <LanguageSwitcher />
          </div>
        </div>
      </Layout>
    </>
  );
}

export default InformationPage;
