import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import useAccountBasedSubmissions from '../../hooks/useAccountBasedSubmissions';
import envVars from '../../resources/envVars';
import SubmissionRequest from './SubmissionRequest';
import {
  fetchSubmissionRequests,
  selectSubmissionsFetched,
} from './submissionsSlice';

const SubmissionList = () => {
  const dispatch = useAppDispatch();

  const submissionsFetched = useAppSelector(selectSubmissionsFetched);

  const API_BASE_URI = envVars.API_BASE_URI || '';

  useEffect(() => {
    /**
     * The tricky behavior of useEffect hook in React 18
     * https://medium.com/geekculture/the-tricky-behavior-of-useeffect-hook-in-react-18-282ef4fb570a
     * let ignore
     */
    let ignore = false;
    if (submissionsFetched === null) {
      const dataObject = {
        baseUrl: API_BASE_URI,
      };
      setTimeout(() => {
        if (!ignore) {
          dispatch(fetchSubmissionRequests(dataObject));
        }
      }, 0);
    }
    return () => {
      ignore = true;
    };
  }, [dispatch, submissionsFetched, API_BASE_URI]);

  /**
   * Account Based filtering of Submission Requests
   */
  const accountBasedData = useAccountBasedSubmissions();

  return (
    <>
      {accountBasedData.map((submission: any) => {
        return (
          <SubmissionRequest
            key={submission.FormSubmissionGuid}
            submission={submission}
          />
        );
      })}
    </>
  );
};

export default SubmissionList;
