import React, { useEffect, useState } from 'react';
import PublicationsSection from './PublicationsSection';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import {
  populateRecentPublicationsData,
  selectLatestPublicationsData,
  selectLatestPublicationsError,
  selectLatestPublicationsFetched,
} from '../../documents/documentsSlice';
import envVars from '../../../../resources/envVars';
import { getPublicationCategories } from '../../../../helpers/utils';

export type categoryProps = {
  sortOrder: number;
  name: string | undefined;
};

const PublicationsSectionProvider = () => {
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useAppDispatch();

  const latestPublications = useAppSelector(selectLatestPublicationsData);
  const isLatestPublicationsFetched = useAppSelector(
    selectLatestPublicationsFetched,
  );
  const latestPublicationsError = useAppSelector(selectLatestPublicationsError);

  /**
   * Fetch Latest Publications in selected (configuration) categories
   */
  useEffect(() => {
    /**
     * The tricky behavior of useEffect hook in React 18
     * https://medium.com/geekculture/the-tricky-behavior-of-useeffect-hook-in-react-18-282ef4fb570a
     * let ignore
     */
    let ignore = false;
    if (isLatestPublicationsFetched === null) {
      const categories: categoryProps[] = getPublicationCategories();
      const API_BASE_URI = envVars.API_BASE_URI;
      const RECENT_PUBLICATIONS_NO: number = envVars.RECENT_PUBLICATIONS_NO;
      const recentPublicationsNo: number =
        RECENT_PUBLICATIONS_NO === 0 ? 0 : RECENT_PUBLICATIONS_NO;

      setTimeout(() => {
        if (!ignore) {
          categories.map((category, index: number) => {
            const dataObject = {
              baseUrl: API_BASE_URI,
              recent: recentPublicationsNo,
              categoryName: category.name,
              last: !(categories.length > index + 1) ? true : false,
            };
            dispatch(populateRecentPublicationsData(dataObject));
            return null;
          });
        }
      }, 0);
    }
    return () => {
      ignore = true;
    };
  }, [dispatch, isLatestPublicationsFetched]);

  useEffect(() => {
    if (isLatestPublicationsFetched) {
      setIsLoading(false);
    }
  }, [isLatestPublicationsFetched]);

  /**
   * Sort publications in correct order, same as defined in global configurations
   */
  const categories: categoryProps[] = getPublicationCategories();
  let latestPublicationsSorted: { [key: string]: object[] } = {};
  if (Object.keys(latestPublications).length === categories.length) {
    categories.map((category: categoryProps) => {
      const categoryCorrectOrder = Object.keys(latestPublications).filter(
        (key: string) => {
          if (key === category.name) {
            latestPublicationsSorted = {
              ...latestPublicationsSorted,
              [category.name]: latestPublications[category.name],
            };
          }
          return key === category.name;
        },
      );
      return categoryCorrectOrder[0];
    });
  }

  return (
    <PublicationsSection
      isLoading={isLoading}
      publications={latestPublicationsSorted}
      publicationsError={latestPublicationsError}
    />
  );
};

export default PublicationsSectionProvider;
