import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ConfigChecker from './components/common/ConfigChecker';
import CustomizationProvider from './components/common/CustomizationProvider';
import ScrollToTop from './components/common/ScrollToTop';

const baseUrl =
  document.getElementsByTagName('base')[0].getAttribute('href') || undefined;

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router basename={baseUrl}>
        <ScrollToTop />
        <ConfigChecker>
          <HelmetProvider>
            <CustomizationProvider>
              <App />
            </CustomizationProvider>
          </HelmetProvider>
        </ConfigChecker>
      </Router>
    </Provider>
  </React.StrictMode>,
);
