import React from 'react';
import envVars from '../../resources/envVars';
import { useTranslation } from '../../hooks/useTranslation';

export interface FooterProps {
  text?: React.ReactNode;
  links?: { label: string; url: string }[];
}

const Footer = ({ text, links }: FooterProps) => {
  const customerName = envVars.CUSTOMER_NAME;
  const currentYear = new Date().getFullYear();

  const ns = 'construo.footer';
  const translations = {
    text: useTranslation(`${ns}.text`, [customerName, currentYear]),
    link1Label: useTranslation(`${ns}.link1Label`),
    link2Label: useTranslation(`${ns}.link2Label`),
    link3Label: useTranslation(`${ns}.link3Label`),
    link1URL: useTranslation(`${ns}.link1URL`),
    link2URL: useTranslation(`${ns}.link2URL`),
    link3URL: useTranslation(`${ns}.link3URL`),
    links: () => {
      let linksArr: FooterProps['links'] = [];

      if (translations.link1Label !== '') {
        linksArr.push({
          label: translations.link1Label,
          url: translations.link1URL,
        });
      }

      if (translations.link2Label !== '') {
        linksArr.push({
          label: translations.link2Label,
          url: translations.link2URL,
        });
      }

      if (translations.link3Label !== '') {
        linksArr.push({
          label: translations.link3Label,
          url: translations.link3URL,
        });
      }

      return linksArr;
    },
  };

  text = text !== undefined ? text : translations.text;
  links = links !== undefined ? links : translations.links();

  return (
    <footer className='global-footer'>
      <div className='container'>
        <div className='row'>
          {!!links?.length && (
            <div className='col-md-6 order-md-1'>
              <div className='global-footer-navigation'>
                <ul className='global-footer-nav'>
                  {links?.map((link, index) => (
                    <li key={index}>
                      <a
                        href={link.url}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {link.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          <div className='col-md-6 order-md-0'>
            <div className='global-footer-text'>{!!text && text}</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
