import React from 'react';
import ActionRequest from '../common/ActionRequest';
import { useTranslation } from '../../hooks/useTranslation';

const SubmissionRequest = (props: any) => {
  const { submission } = props;

  const ns = 'construo.forms';
  const translations = {
    noSubmissionName: useTranslation(`${ns}.noSubmissionName`),
    pleaseCompleteForm: useTranslation(`${ns}.pleaseCompleteForm`),
  };
  const submissionName = submission.Title;
  const heading = !!submissionName
    ? submissionName
    : translations.noSubmissionName;
  const formSubmissionGuid = submission.FormSubmissionGuid;

  return (
    <ActionRequest
      type='submission'
      href={`/user/submissions/${formSubmissionGuid}`}
      heading={heading}
    />
  );
};

export default SubmissionRequest;
