import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import {
  DropDownList,
  DropDownListChangeEvent,
} from '@progress/kendo-react-dropdowns';
import {
  setVisibilityMenu,
  VisibilityMenu,
} from '../../app/slices/visibilityMenuSlice';
import {
  setLanguage,
  selectLanguage,
  Languages,
} from './languageSwitcherSlice';
import envVars from '../../resources/envVars';
import { useTranslation } from '../../hooks/useTranslation';

interface LanguageSwitcherProps {
  isMultiLanguage?: boolean;
}

const LanguageSwitcher = ({
  isMultiLanguage: isMultiLanguageProp,
}: LanguageSwitcherProps) => {
  const configLangKeys = envVars.LANGUAGES;
  const isMultiLanguage =
    isMultiLanguageProp === undefined
      ? configLangKeys && configLangKeys.length > 1
      : isMultiLanguageProp;

  const ns = 'construo.menu';
  const translations = {
    languageSwitcherLabel: useTranslation(`${ns}.languageSwitcherLabel`),
    english: useTranslation(`${ns}.english`),
    norwegian: useTranslation(`${ns}.norwegian`),
    finnish: useTranslation(`${ns}.finnish`),
    swedish: useTranslation(`${ns}.swedish`),
  };

  const supportedLanguages = [
    {
      language: translations.english,
      id: 1,
      languageId: 'en-150',
      label: 'English',
    },
    {
      language: translations.finnish,
      id: 2,
      languageId: 'fi',
      label: 'Finnish',
    },
    {
      language: translations.norwegian,
      id: 3,
      languageId: 'nb',
      label: 'Norwegian',
    },
    {
      language: translations.swedish,
      id: 4,
      languageId: 'sv',
      label: 'Swedish',
    },
  ];

  type langProps = {
    language: string;
    id: number;
    languageId: string;
    label: string;
  };

  let filteredLangs: langProps[] = [];

  supportedLanguages.forEach((slang: any, index) => {
    configLangKeys?.forEach((key: string) => {
      if (slang.languageId === key) filteredLangs.push(slang);
    });
  });

  const languageNames = filteredLangs?.map((language: any) => {
    return language.language;
  });

  const dispatch = useAppDispatch();

  const valueRender = (element: any, value: string) => {
    if (!value) {
      return element;
    }
    const children = [
      <i key={1} className='fal fa-globe' />,
      <span key={2}>{element.props.children}</span>,
    ];
    return React.cloneElement(element, { ...element.props }, children);
  };

  const language = useAppSelector(selectLanguage);
  let languageName;
  languageName = filteredLangs?.filter(
    (languageItem: any) => languageItem.languageId === language,
  )[0].language;

  const onChange = (event: DropDownListChangeEvent) => {
    const lang: langProps = event.target.value;
    const language = filteredLangs.filter(
      (item: any) => item.language === lang,
    );
    let selectedLanguage = Languages.EN;
    switch (language[0].label) {
      case 'English':
        selectedLanguage = Languages.EN;
        break;
      case 'Norwegian':
        selectedLanguage = Languages.NB;
        break;
      case 'Finnish':
        selectedLanguage = Languages.FI;
        break;
      case 'Swedish':
        selectedLanguage = Languages.SV;
        break;
      default:
      // code
    }
    dispatch(setLanguage(selectedLanguage));
    dispatch(setVisibilityMenu(VisibilityMenu.Hide));
  };

  const languageSwitcherRef: any = useRef(null);
  const [popupSettings, setPopupSettings] = useState({});

  useEffect(() => {
    setPopupSettings({
      appendTo: languageSwitcherRef?.current,
    });
  }, []);

  return (
    <>
      {isMultiLanguage && (
        <div className='choose-language' ref={languageSwitcherRef}>
          <DropDownList
            label={translations.languageSwitcherLabel}
            data={languageNames}
            value={languageName}
            valueRender={valueRender}
            onChange={onChange}
            popupSettings={popupSettings}
          />
        </div>
      )}
    </>
  );
};

export default LanguageSwitcher;
