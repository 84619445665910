export const nbFormioMessages = {
  unsavedRowsError: '[NB] Please save all rows before proceeding.',
  invalidRowsError: '[NB] Please correct invalid rows before proceeding.',
  invalidRowError: '[NB] Invalid row. Please correct it or delete.',
  alertMessageWithLabel: '[NB] {{label}}: {{message}}',
  alertMessage: '[NB] {{message}}',
  complete: '[NB] Submission Complete',
  error: '[NB] Please fix the following errors before submitting.',
  errorListHotkey: '[NB] Press Ctrl + Alt + X to go back to the error list.',
  errorsListNavigationMessage:
    '[NB] Click to navigate to the field with following error.',
  submitError:
    '[NB] Please check the form and correct all errors before submitting.',
  required: '[NB] {{field}} is required',
  unique: '[NB] {{field}} must be unique',
  array: '[NB] {{field}} must be an array',
  array_nonempty: '[NB] {{field}} must be a non-empty array',
  nonarray: '[NB] {{field}} must not be an array',
  select: '[NB] {{field}} contains an invalid selection',
  pattern: '[NB] {{field}} does not match the pattern {{pattern}}',
  minLength: '[NB] {{field}} must have at least {{length}} characters.',
  maxLength: '[NB] {{field}} must have no more than {{length}} characters.',
  minWords: '[NB] {{field}} must have at least {{length}} words.',
  maxWords: '[NB] {{field}} must have no more than {{length}} words.',
  min: '[NB] {{field}} cannot be less than {{min}}.',
  max: '[NB] {{field}} cannot be greater than {{max}}.',
  maxDate: '[NB] {{field}} should not contain date after {{- maxDate}}',
  minDate: '[NB] {{field}} should not contain date before {{- minDate}}',
  maxYear: '[NB] {{field}} should not contain year greater than {{maxYear}}',
  minYear: '[NB] {{field}} should not contain year less than {{minYear}}',
  invalid_email: '[NB] {{field}} must be a valid email.',
  invalid_url: '[NB] {{field}} must be a valid url.',
  invalid_regex: '[NB] {{field}} does not match the pattern {{regex}}.',
  invalid_date: '[NB] {{field}} is not a valid date.',
  invalid_day: '[NB] {{field}} is not a valid day.',
  mask: '[NB] {{field}} does not match the mask.',
  valueIsNotAvailable: '[NB] {{ field }} is an invalid value.',
  stripe: '[NB] {{stripe}}',
  month: '[NB] Month',
  Month: '[NB] Month',
  day: '[NB] Day',
  Day: '[NB] Day',
  year: '[NB] Year',
  Year: '[NB] Year',
  january: '[NB] January',
  January: '[NB] January',
  february: '[NB] February',
  February: '[NB] February',
  march: '[NB] March',
  March: '[NB] March',
  april: '[NB] April',
  April: '[NB] April',
  may: '[NB] May',
  May: '[NB] May',
  june: '[NB] June',
  June: '[NB] June',
  july: '[NB] July',
  July: '[NB] July',
  august: '[NB] August',
  August: '[NB] August',
  september: '[NB] September',
  September: '[NB] September',
  october: '[NB] October',
  October: '[NB] October',
  november: '[NB] November',
  November: '[NB] November',
  december: '[NB] December',
  December: '[NB] December',
  next: '[NB] Next',
  previous: '[NB] Previous',
  cancel: '[NB] Cancel',
  submit: '[NB] Submit Form',
  submitForRequiredAuthentication: '[NB] Identify and submit',
  confirmCancel: '[NB] Are you sure you want to cancel?',
  saveDraftInstanceError:
    '[NB] Cannot save draft because there is no formio instance.',
  saveDraftAuthError: '[NB] Cannot save draft unless a user is authenticated.',
  restoreDraftInstanceError:
    '[NB] Cannot restore draft because there is no formio instance.',
  time: '[NB] Invalid time',
  'Type to search': '[NB] Type to search',
  'No results found': '[NB] No results found',
};
