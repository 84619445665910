import React, { useEffect, useMemo, useState } from 'react';
import {
  StockChart,
  ChartLegend,
  ChartNavigator,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartNavigatorSelect,
  TooltipContext,
  ChartSeriesItemTooltip,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  RenderEvent,
} from '@progress/kendo-react-charts';
import 'hammerjs';
import {
  DatePicker,
  DatePickerChangeEvent,
  SelectionRange,
} from '@progress/kendo-react-dateinputs';
import { isEqualDate, addDays } from '@progress/kendo-date-math';
import GraphSkeleton from './GraphSkeleton';
import { useInternationalization } from '@progress/kendo-react-intl';
import { Path, Group } from '@progress/kendo-drawing';
import { useConfig } from '../../../hooks/useConfig';
import { useTranslation } from '../../../hooks/useTranslation';

export type TimeSeriesProps = {
  date: Date;
  value: number;
};

export type PerformanceGraphData = {
  title: string;
  portfolioId: string;
  color?: string;
  timeSeries: TimeSeriesProps[];
};

export interface GraphProps {
  data: PerformanceGraphData[];
  isLoading: boolean;
}

const Graph = ({ data, isLoading }: GraphProps) => {
  const intl = useInternationalization();
  const config = useConfig();
  const ns = 'construo.portfolios';
  const translations = {
    graphPerformanceValueAxisTitle: useTranslation(
      `${ns}.graphPerformanceValueAxisTitle`,
    ),
    graphPerformanceDatepickerStartLabel: useTranslation(
      `${ns}.graphPerformanceDatepickerStartLabel`,
    ),
    graphPerformanceDatepickerEndLabel: useTranslation(
      `${ns}.graphPerformanceDatepickerEndLabel`,
    ),
  };

  const allDates = useMemo(() => {
    let dates: Date[] = [];
    data?.forEach(seriesItem => {
      seriesItem?.timeSeries?.forEach(dataItem => {
        dates?.push(dataItem?.date);
      });
    });
    return dates;
  }, [data]);

  const minDate = useMemo(
    () => new Date(Math.min(...allDates.map(Number))),
    [allDates],
  );

  const maxDate = useMemo(
    () => new Date(Math.max(...allDates.map(Number))),
    [allDates],
  );

  const dateRange = useMemo(() => {
    return { start: minDate, end: maxDate };
  }, [minDate, maxDate]);

  // Define states
  const [min, setMin] = useState<Date>(minDate);
  const [max, setMax] = useState<Date>(maxDate);

  const [pickerRange, setPickerRange] = useState<SelectionRange>(dateRange);
  const [chartRange, setChartRange] = useState<SelectionRange>(dateRange);
  const [graphSeries, setGraphSeries] = useState<PerformanceGraphData[]>(data);

  const [isStartDateValid, setIsStartDateValid] = useState<boolean>(true);
  const [isEndDateValid, setIsEndDateValid] = useState<boolean>(true);

  useEffect(() => {
    const firstDataItem = data[0];

    if (firstDataItem?.timeSeries?.length > 0) {
      const startValue =
        firstDataItem?.timeSeries?.find(item =>
          isEqualDate(item.date, chartRange.start as Date),
        )?.value || firstDataItem?.timeSeries[0]?.value; // TODO: Check if there is a better fallback

      const newSeries = data?.map(seriesItem => {
        const newSeriesItem = seriesItem.timeSeries.map(dataItem => {
          const newValue = 100 * (dataItem.value / startValue);
          return {
            ...dataItem,
            value: newValue,
          };
        });
        return {
          ...seriesItem,
          timeSeries: newSeriesItem,
        };
      });

      setGraphSeries(newSeries);
    }
  }, [chartRange, data]);

  const handleStartDateChange = (event: DatePickerChangeEvent) => {
    const startDate = event?.value;
    const endDate = pickerRange.end;
    !!startDate && setMin(startDate);
    const newPickerRange = { ...pickerRange, start: event.value };

    const isStartDateAfterInputRangeStart =
      !!startDate && !!dateRange.start
        ? startDate.getTime() >= dateRange.start.getTime()
        : false;
    const isValidDate =
      startDate && endDate
        ? startDate.getTime() < endDate.getTime() &&
          isStartDateAfterInputRangeStart
        : false;
    setIsStartDateValid(isValidDate);
    if (isValidDate) {
      setIsEndDateValid(true);
      setPickerRange(newPickerRange);
      setChartRange(newPickerRange);
    }
  };

  const handleEndDateChange = (event: DatePickerChangeEvent) => {
    const startDate = pickerRange.start;
    const endDate = event?.value;
    !!endDate && setMax(endDate);
    const newPickerRange = { ...pickerRange, end: event.value };

    const isEndDateBeforeInputRangeEnd =
      !!endDate && !!dateRange.end
        ? endDate.getTime() <= dateRange.end.getTime()
        : false;
    const isValidDate =
      startDate && endDate
        ? startDate.getTime() < endDate.getTime() &&
          isEndDateBeforeInputRangeEnd
        : false;
    setIsEndDateValid(isValidDate);
    if (isValidDate) {
      setIsStartDateValid(true);
      setPickerRange(newPickerRange);
      setChartRange(newPickerRange);
    }
  };

  const handleChartRender = (args: RenderEvent) => {
    const chart = args.target.chartInstance;

    if (!chart) {
      return;
    }

    // get the axes
    const valueAxis = chart.findAxisByName('valueAxis');
    const categoryAxis = chart.findAxisByName('categoryAxis');

    // get the coordinates of the value at which the plot band will be rendered
    const valueSlot = valueAxis.slot(100);

    // get the coordinates of the entire category axis range
    const range = categoryAxis.range();
    const categorySlot = categoryAxis.slot(range.min, range.max);

    // draw the plot band based on the found coordinates
    const line = new Path({
      stroke: {
        color: config.portfolios.performanceGraph.horizontalLineColor,
        width: 1,
      },
    })
      .moveTo(valueSlot.origin.x, valueSlot.origin.y)
      .lineTo(categorySlot.topRight().x, valueSlot.origin.y);

    // create group
    const group = new Group();
    group.append(line);

    // draw on the surface
    chart.surface.draw(group);
  };

  const seriesTooltip = ({ point }: TooltipContext) => (
    <>
      <strong>{intl.formatNumber(point.value, 'n2')}</strong>
      <br />
      {intl.formatDate(new Date(point.category), { date: 'short' })}
    </>
  );

  const chartSeriesItemList = graphSeries.map((seriesItem, index) => {
    return (
      <ChartSeriesItem
        key={seriesItem.portfolioId || index}
        name={seriesItem.title}
        color={seriesItem.color}
        data={seriesItem.timeSeries}
        type='line'
        categoryField='date'
        field='value'
        aggregate={'first'}
        markers={{ visible: false }}
      >
        <ChartSeriesItemTooltip render={seriesTooltip} />
      </ChartSeriesItem>
    );
  });

  return (
    <>
      {isLoading ? (
        <GraphSkeleton />
      ) : (
        <section className='portfolio-graph'>
          <div className='row'>
            <div className='col-xs-12 col-sm-6 col-md-3'>
              <DatePicker
                className='start-date'
                min={minDate}
                max={addDays(max, -1)}
                value={pickerRange.start}
                label={translations.graphPerformanceDatepickerStartLabel}
                onChange={handleStartDateChange}
                valid={isStartDateValid}
              />
            </div>
            <div className='col-xs-12 col-sm-6 col-md-3'>
              <DatePicker
                className='end-date'
                min={addDays(min, +1)}
                max={maxDate}
                value={pickerRange.end}
                label={translations.graphPerformanceDatepickerEndLabel}
                onChange={handleEndDateChange}
                valid={isEndDateValid}
              />
            </div>
          </div>

          <StockChart onRender={handleChartRender}>
            <ChartValueAxis>
              <ChartValueAxisItem
                name={'valueAxis'}
                labels={{ format: '{0:n0}' }}
                title={{ text: translations.graphPerformanceValueAxisTitle }}
              />
            </ChartValueAxis>
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                name={'categoryAxis'}
                baseUnit='days'
                maxDivisions={12}
                labels={{ format: '{0:d}', rotation: 'auto' }}
              />
            </ChartCategoryAxis>
            <ChartLegend
              visible={true}
              orientation={'vertical'}
              position={'bottom'}
            />
            <ChartSeries>{chartSeriesItemList}</ChartSeries>
            <ChartNavigator visible={false}>
              <ChartNavigatorSelect
                from={chartRange.start as Date}
                to={chartRange.end as Date}
              />
            </ChartNavigator>
          </StockChart>
        </section>
      )}
    </>
  );
};

export default Graph;
