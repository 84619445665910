export const daFormioMessages = {
  unsavedRowsError: '[DA] Please save all rows before proceeding.',
  invalidRowsError: '[DA] Please correct invalid rows before proceeding.',
  invalidRowError: '[DA] Invalid row. Please correct it or delete.',
  alertMessageWithLabel: '[DA] {{label}}: {{message}}',
  alertMessage: '[DA] {{message}}',
  complete: '[DA] Submission Complete',
  error: '[DA] Please fix the following errors before submitting.',
  errorListHotkey: '[DA] Press Ctrl + Alt + X to go back to the error list.',
  errorsListNavigationMessage:
    '[DA] Click to navigate to the field with following error.',
  submitError:
    '[DA] Please check the form and correct all errors before submitting.',
  required: '[DA] {{field}} is required',
  unique: '[DA] {{field}} must be unique',
  array: '[DA] {{field}} must be an array',
  array_nonempty: '[DA] {{field}} must be a non-empty array',
  nonarray: '[DA] {{field}} must not be an array',
  select: '[DA] {{field}} contains an invalid selection',
  pattern: '[DA] {{field}} does not match the pattern {{pattern}}',
  minLength: '[DA] {{field}} must have at least {{length}} characters.',
  maxLength: '[DA] {{field}} must have no more than {{length}} characters.',
  minWords: '[DA] {{field}} must have at least {{length}} words.',
  maxWords: '[DA] {{field}} must have no more than {{length}} words.',
  min: '[DA] {{field}} cannot be less than {{min}}.',
  max: '[DA] {{field}} cannot be greater than {{max}}.',
  maxDate: '[DA] {{field}} should not contain date after {{- maxDate}}',
  minDate: '[DA] {{field}} should not contain date before {{- minDate}}',
  maxYear: '[DA] {{field}} should not contain year greater than {{maxYear}}',
  minYear: '[DA] {{field}} should not contain year less than {{minYear}}',
  invalid_email: '[DA] {{field}} must be a valid email.',
  invalid_url: '[DA] {{field}} must be a valid url.',
  invalid_regex: '[DA] {{field}} does not match the pattern {{regex}}.',
  invalid_date: '[DA] {{field}} is not a valid date.',
  invalid_day: '[DA] {{field}} is not a valid day.',
  mask: '[DA] {{field}} does not match the mask.',
  valueIsNotAvailable: '[DA] {{ field }} is an invalid value.',
  stripe: '[DA] {{stripe}}',
  month: '[DA] Month',
  Month: '[DA] Month',
  day: '[DA] Day',
  Day: '[DA] Day',
  year: '[DA] Year',
  Year: '[DA] Year',
  january: '[DA] January',
  January: '[DA] January',
  february: '[DA] February',
  February: '[DA] February',
  march: '[DA] March',
  March: '[DA] March',
  april: '[DA] April',
  April: '[DA] April',
  may: '[DA] May',
  May: '[DA] May',
  june: '[DA] June',
  June: '[DA] June',
  july: '[DA] July',
  July: '[DA] July',
  august: '[DA] August',
  August: '[DA] August',
  september: '[DA] September',
  September: '[DA] September',
  october: '[DA] October',
  October: '[DA] October',
  november: '[DA] November',
  November: '[DA] November',
  december: '[DA] December',
  December: '[DA] December',
  next: '[DA] Next',
  previous: '[DA] Previous',
  cancel: '[DA] Cancel',
  submit: '[DA] Submit Form',
  submitForRequiredAuthentication: '[DA] Identify and submit',
  confirmCancel: '[DA] Are you sure you want to cancel?',
  saveDraftInstanceError:
    '[DA] Cannot save draft because there is no formio instance.',
  saveDraftAuthError: '[DA] Cannot save draft unless a user is authenticated.',
  restoreDraftInstanceError:
    '[DA] Cannot restore draft because there is no formio instance.',
  time: '[DA] Invalid time',
  'Type to search': '[DA] Type to search',
  'No results found': '[DA] No results found',
};
