import React, { useEffect, useState } from 'react';
import { NavLink, Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import Details from './Details';
import Actions, { IWorkflowTaskAction } from './Actions';
import Messages from './Messages';
import { setAlerts } from '../../../app/slices/alertSlice';
import { selectWorkflowsError, selectWorkflowsFetched } from '../workflowSlice';
import HelmetTitle from '../../common/HelmetTitle';
import Layout from '../../layout/Layout';
import { useTranslation } from '../../../hooks/useTranslation';

export interface TaskDetailsProps {
  taskDetailsFetched: boolean | null;
  messagesFetched: boolean | null;
  workflowId: string | null;
  workflowName: string | false;
  id: string;
  displayName: string;
  description: string;
  status: string | null;
  accessRightsContacts: { fullName: string }[];
  actions: IWorkflowTaskAction[];
  progress: any;
  isScrollIntoView: boolean;
  messages: any;
  conversationGuid: string | null;
  cantShowTaskDetails: boolean;
}

const TaskDetails = (taskDetails: TaskDetailsProps) => {
  const dispatch = useAppDispatch();

  const ns = 'construo.inbox';
  const translations = {
    backToInbox: useTranslation(`${ns}.backToInbox`),
    couldNotFoundContent: useTranslation(`${ns}.couldNotFoundContent`),
    taskDoesNotExist: useTranslation(`${ns}.taskDoesNotExist`),
    canSeeHistory: useTranslation(`${ns}.canSeeHistory`),
    conversationLinkLabel: useTranslation(`${ns}.conversationLinkLabel`),
    noWorkflowName: useTranslation('construo.workflows.noWorkflowName'),
  };

  const taskDetailsFetched = taskDetails?.taskDetailsFetched;
  const messagesFetched = taskDetails?.messagesFetched;
  const taskId = taskDetails?.id;
  const isActionsReady = !!taskDetailsFetched && !!messagesFetched;
  const taskTitle = taskDetails?.displayName;
  const taskDescription = taskDetails?.description;
  const taskContacts = taskDetails?.accessRightsContacts;
  const taskActions = taskDetails?.actions;
  const workflowId = taskDetails?.workflowId;
  const workflowName = taskDetails?.workflowName;
  const conversationGuid = taskDetails?.conversationGuid;
  const cantShowTaskDetails = taskDetails?.cantShowTaskDetails;
  const taskMessages = taskDetails?.messages;
  const isScrollIntoView = taskDetails?.isScrollIntoView;

  const workflowsFetched = useAppSelector(selectWorkflowsFetched);
  const workflowsError = useAppSelector(selectWorkflowsError);
  useEffect(() => {
    if (workflowsFetched && !!workflowsError) {
      dispatch(setAlerts({ message: workflowsError, type: 'error' }));
    }
  }, [dispatch, workflowsFetched, workflowsError]);

  const [showError, setShowError] = useState<boolean>(false);
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setShowError(true);
    }
  }, []);

  const workflowTitle = !!workflowName
    ? workflowName
    : translations.noWorkflowName;

  return (
    <Layout>
      {cantShowTaskDetails ? (
        !!conversationGuid ? (
          <>
            <HelmetTitle title={translations.taskDoesNotExist} />
            <section className='main-section task-details'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-12'>
                    <NavLink className='back-link' to='/inbox'>
                      {translations.backToInbox}:
                    </NavLink>
                    <h1>{translations.taskDoesNotExist}</h1>
                    <p>
                      {translations.canSeeHistory}{' '}
                      <NavLink
                        to={`/conversations/${conversationGuid}?unavailable=true`}
                      >
                        {translations.conversationLinkLabel}
                      </NavLink>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <>
            <Navigate
              replace={true}
              to={`/resource-not-found?type=task&guid=${taskId}${
                showError ? `&error=${translations.taskDoesNotExist}` : ``
              }`}
            />
          </>
        )
      ) : (
        <>
          <HelmetTitle title={`${workflowTitle}: ${taskTitle}`} />
          <Details
            isLoaded={!!taskDetailsFetched}
            workflowId={workflowId}
            workflowName={workflowTitle}
            taskTitle={taskTitle}
            taskDescription={taskDescription}
            taskContacts={taskContacts}
            taskId={taskId}
          />
          <Actions
            isLoaded={isActionsReady}
            workflowId={workflowId}
            taskId={taskId}
            actions={taskActions}
          />
          <Messages
            isLoaded={!!messagesFetched}
            workflowId={workflowId}
            taskId={taskId}
            taskTitle={taskTitle}
            messages={taskMessages}
            isScrollIntoView={isScrollIntoView}
          />
        </>
      )}
      <></>
    </Layout>
  );
};

export default TaskDetails;
