import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface LoadingMessage {
  message: string | null;
}

const initialState: LoadingMessage = {
  message: null,
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoadingMessage(state, action) {
      state.message = action.payload;
    },
  },
});

export const { setLoadingMessage } = loadingSlice.actions;

export const selectLoadingMessage = (state: RootState) =>
  state.loadingMessage.message;

export default loadingSlice.reducer;
