import React from 'react';
import Task, { TaskProps } from './Task';

export interface TaskListProps {
  tasks: TaskProps[];
}

const TaskList = ({ tasks }: TaskListProps) => {
  return (
    <>
      {tasks?.length > 0 && (
        <div className='workflow-tasks k-card-list'>
          {tasks?.map(task => {
            return <Task key={task.id} {...task} />;
          })}
        </div>
      )}
    </>
  );
};

export default TaskList;
