import React, { createRef, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { Button } from '@progress/kendo-react-buttons';

import {
  Upload,
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from '@progress/kendo-react-upload';
import envVars from '../../../resources/envVars';
import authService from '../../api-authorization/AuthorizeService';
import axios from 'axios';
import { getAxiosConfig } from '../../../helpers/utils';
import { useNavigate } from 'react-router-dom';
import { selectAlert, setAlerts } from '../../../app/slices/alertSlice';
import { setUploadsFetched } from '../../upload-requests/uploadSlice';
import { Loading, setLoading } from '../../../app/slices/loadingSlice';
import { setLoadingMessage } from '../../../app/slices/loadingMessageSlice';
import Layout from '../../layout/Layout';
import { useTranslation } from '../../../hooks/useTranslation';

interface UploadActionDetailsProps {
  actionId: string | null;
  actionDisplayName: string | null;
  actionUrl: string | null;
  title: string;
  text: string;
  note: string;
  buttonLabel: string;
}

const UploadActionDetails = ({
  actionId,
  actionDisplayName,
  title,
  text,
  note,
  buttonLabel,
}: UploadActionDetailsProps) => {
  const ns = 'construo.uploadRequests';
  const translations = {
    uploadingDoc: useTranslation(`${ns}.uploadingDoc`),
    uploadSuccessful: useTranslation(`${ns}.uploadSuccessful`),
    uploadFailed: useTranslation(`${ns}.uploadFailed`),
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const alerts = useAppSelector(selectAlert);
  /**
   * Saving alert for later use on Inbox page/component, we'll reset alert there (Inbox page) on "page-leave", once user sees it
   */
  if (alerts.length > 0) {
    sessionStorage.setItem('alerts', JSON.stringify(alerts));
  }

  const uploadRef = createRef<Upload>();
  const [files, setFiles] = useState<Array<UploadFileInfo>>([]);
  const [affectedFiles, setAffectedFiles] = useState<Array<UploadFileInfo>>([]);
  const UPLOAD_REQUESTS_URI = envVars.API_BASE_URI + '/user/document-requests/';

  const uploadDocumentRequest = async (
    url: string,
    data: string,
    options: object,
  ) => {
    const responseData = await axios
      .put(url, data, options)
      .then(response => response.data)
      .then(response => {
        return response;
      })
      .catch(error => {
        // Show error message after Upload fails
        dispatch(
          setAlerts({
            message: `${translations.uploadFailed} ${error.message}`,
            type: 'error',
          }),
        );
        // And scroll to top so that alert is visible
        window.scrollTo(0, 0);
      });
    return responseData;
  };

  useEffect(() => {
    affectedFiles
      .filter((file: any) => !file.validationErrors)
      .forEach((file: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.getRawFile());
        reader.onloadend = (e: any) => {
          // setFilePreview({
          //   [file.uid]: e.target.result,
          // });
        };
      });
  }, [affectedFiles]);

  const [hasValidationErrors, setHasValidationErrors]: any = useState(false);

  const onAdd = (event: UploadOnAddEvent) => {
    setFiles(event.newState);
    setAffectedFiles(event.affectedFiles);
    event.affectedFiles.forEach((file: UploadFileInfo) => {
      setHasValidationErrors(!!file.validationErrors ? true : false);
    });
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    // let newFilePreview: any = { ...filePreview };
    // event.affectedFiles.forEach((file: UploadFileInfo) => {
    //   delete newFilePreview[file.uid];
    // });
    setFiles(event.newState);
  };

  const uploadDocument = () => {
    const url = UPLOAD_REQUESTS_URI + actionId + '/upload';

    dispatch(setLoading(Loading.Show));
    dispatch(setLoadingMessage(translations.uploadingDoc));

    affectedFiles
      .filter((file: any) => !file.validationErrors)
      .forEach((file: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.getRawFile());
        reader.onloadend = async (e: any) => {
          const result = await e.target.result.split(';base64,');
          const mimeType = result[0].split('data:')[1];
          const imageData = result[1];
          const fileName = file.name;
          const data = {
            FileName: fileName,
            MimeType: mimeType,
            ImageData: imageData,
          };
          const dataStringify = JSON.stringify(data);
          const token = await authService.getAccessToken();
          const config = getAxiosConfig(token, 'json');
          uploadDocumentRequest(url, dataStringify, config)
            .then(response => {
              if (!!response) {
                dispatch(setUploadsFetched(null));
                // Show success message after upload resolves successfully
                dispatch(
                  setAlerts({
                    message: translations.uploadSuccessful,
                    type: 'success',
                  }),
                );
                // Redirect user back to Inbox page
                navigate('/inbox');
                // And scroll to top so that alert is visible
                window.scrollTo(0, 0);
              }
            })
            .catch(err => {
              dispatch(
                setAlerts({
                  message: `${translations.uploadFailed} ${err.message}`,
                  type: 'error',
                }),
              );
            })
            .finally(() => {
              // After Upload is done (successfully or not) hide modal, remove loading message.
              // In case of an error error message will be displayed
              dispatch(setLoading(Loading.Hide));
              dispatch(setLoadingMessage(null));
            });
        };
      });
  };

  return (
    <Layout>
      <section className='main-section inbox'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h1>{title}</h1>
              <p>{text}</p>
              <div className='upload-file'>
                <h5>{actionDisplayName}</h5>

                <Upload
                  ref={uploadRef}
                  batch={false}
                  autoUpload={false}
                  multiple={false}
                  showActionButtons={false}
                  defaultFiles={[]}
                  files={files}
                  onAdd={onAdd}
                  onRemove={onRemove}
                  restrictions={{
                    allowedExtensions: ['.jpg', '.jpeg', '.png', '.pdf'],
                    maxFileSize: 10485760, // 10MB
                  }}
                />
                <div className='note'>{note}</div>
                <Button
                  iconClass='fal fa-upload'
                  disabled={
                    hasValidationErrors || files.length === 0 ? true : false
                  }
                  themeColor='primary'
                  onClick={() => {
                    uploadDocument();
                  }}
                >
                  {buttonLabel}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default UploadActionDetails;
