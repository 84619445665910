import React from 'react';
import HelmetTitle from '../common/HelmetTitle';
import { Login } from './Login';
import { useTranslation } from '../../hooks/useTranslation';

interface LoginActionProps {
  name: string;
}

const LoginAction = ({ name }: LoginActionProps) => {
  const ns = 'construo.global';
  const translations = {
    login: useTranslation(`${ns}.loginTitle`),
  };
  return (
    <>
      <HelmetTitle title={translations.login} />
      <Login action={name}></Login>
    </>
  );
};

export default LoginAction;
