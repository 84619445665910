import React from 'react';
import { Skeleton } from '@progress/kendo-react-indicators';

const GraphSkeleton = () => {
  return (
    <>
      <Skeleton
        shape={'rectangle'}
        style={{ display: 'inline-block', width: 150, height: 30 }}
      />{' '}
      <Skeleton
        shape={'rectangle'}
        style={{ display: 'inline-block', width: 150, height: 30 }}
      />
      <Skeleton shape={'rectangle'} style={{ height: 300 }} />
    </>
  );
};

export default GraphSkeleton;
