import React, { createRef, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../hooks/reduxHooks';
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
import {
  Dialog,
  DialogActionsBar,
  DialogCloseEvent,
} from '@progress/kendo-react-dialogs';
import {
  Upload,
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from '@progress/kendo-react-upload';
import envVars from '../../../resources/envVars';
import authService from '../../api-authorization/AuthorizeService';
import { getAxiosConfig } from '../../../helpers/utils';
import { setAlerts } from '../../../app/slices/alertSlice';
import { Loading, setLoading } from '../../../app/slices/loadingSlice';
import { setLoadingMessage } from '../../../app/slices/loadingMessageSlice';
import { resetTaskDetailsData } from './taskDetailsSlice';
import { useTranslation } from '../../../hooks/useTranslation';

export interface UploadAttachmentsModalProps {
  workflowId: string;
  taskId: string;
  actionId: string;
  setModalStatus: (status: true | false) => void;
  modalTitle?: string;
  desc?: string;
  fileRestriction?: string;
  btnLabel?: string;
}

const UploadAttachmentsModal = (props: UploadAttachmentsModalProps) => {
  const API_BASE_URI = envVars.API_BASE_URI;

  const {
    workflowId,
    taskId,
    actionId,
    setModalStatus,
    modalTitle,
    desc,
    fileRestriction,
    btnLabel,
  } = props;

  const dispatch = useAppDispatch();

  const ns = 'construo.uploadRequests';
  const translations = {
    upload: useTranslation(`${ns}.upload`),
    docUpload: useTranslation(`${ns}.docUpload`),
    uploadingDoc: useTranslation(`${ns}.uploadingDoc`),
    requireAttachments: useTranslation(`${ns}.requireAttachments`),
    fileRestriction: useTranslation(`${ns}.fileRestriction`),
    uploadSuccessful: useTranslation(`${ns}.uploadSuccessful`),
    uploadFailed: useTranslation(`${ns}.uploadFailed`),
  };

  const customTitle = !!modalTitle ? modalTitle : translations.docUpload;
  const modalDesc = !!desc ? desc : translations.requireAttachments;
  const note = !!fileRestriction
    ? fileRestriction
    : translations.fileRestriction;
  const uploadBtnLabel = !!btnLabel ? btnLabel : translations.upload;

  const CustomTitleBar: any = () => {
    return <>{customTitle}</>;
  };

  const [files, setFiles] = useState<Array<UploadFileInfo>>([]);
  const [filePreview, setFilePreview]: any = useState({});
  const [affectedFiles, setAffectedFiles] = useState<Array<UploadFileInfo>>([]);

  useEffect(() => {
    affectedFiles
      .filter((file: any) => !file.validationErrors)
      .forEach((file: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.getRawFile());
        reader.onloadend = (e: any) => {
          setFilePreview({
            [file.uid]: e.target.result,
          });
        };
      });
  }, [affectedFiles]);

  const [hasValidationErrors, setHasValidationErrors]: any = useState(false);

  const onAdd = (event: UploadOnAddEvent) => {
    setFiles(event.newState);
    setAffectedFiles(event.affectedFiles);
    event.affectedFiles.forEach((file: UploadFileInfo) => {
      setHasValidationErrors(!!file.validationErrors ? true : false);
    });
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    let newFilePreview: any = { ...filePreview };
    event.affectedFiles.forEach((file: UploadFileInfo) => {
      delete newFilePreview[file.uid];
    });
    setFiles(event.newState);
    setFilePreview(newFilePreview);
  };

  const uploadDocument = () => {
    const url = `${API_BASE_URI}/user/workflows/document`;

    dispatch(setLoading(Loading.Show));
    dispatch(setLoadingMessage(translations.uploadingDoc));
    setModalStatus(false);

    affectedFiles
      .filter((file: any) => !file.validationErrors)
      .forEach((file: any, index: number) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.getRawFile());
        reader.onloadend = async (e: any) => {
          const result = await e.target.result.split(';base64,');
          const mimeType = result[0].split('data:')[1];
          const imageData = result[1];
          const fileName = file.name;
          const data = {
            WorkflowId: workflowId,
            TaskId: taskId,
            ActionId: actionId,
            File: {
              FileName: fileName,
              MimeType: mimeType,
              ImageData: imageData,
            },
          };
          const dataStringify = JSON.stringify(data);
          const token = await authService.getAccessToken();
          const config = getAxiosConfig(token, 'json');
          uploadDocumentRequest(url, dataStringify, config)
            .then(response => {
              if (!!response) {
                // Reset attachments list
                dispatch(resetTaskDetailsData());
                // And scroll to top so that alert is visible
                window.scrollTo(0, 0);
              }
            })
            .catch(err => {
              dispatch(
                setAlerts({
                  message: `${translations.uploadFailed} ${err.message}`,
                  type: 'error',
                }),
              );
            })
            .finally(() => {
              // In case of multiple files upload do it when last document is uploaded (if statement)
              // In case of an error error message will be displayed
              if (index >= affectedFiles.length - 1) {
                dispatch(setLoading(Loading.Hide));
                dispatch(setLoadingMessage(null));
              }
            });
        };
      });
  };

  const uploadDocumentRequest = async (
    url: string,
    data: string,
    options: object,
  ) => {
    const responseData = await axios
      .post(url, data, options)
      .then(response => response.data)
      .then(response => {
        return response;
      })
      .catch(error => {
        // Show error message after Upload fails
        dispatch(setAlerts({ message: error.message, type: 'error' }));
        // And scroll to top so that alert is visible
        window.scrollTo(0, 0);
      });

    return responseData;
  };

  const uploadRef = createRef<Upload>();

  return (
    <>
      <Dialog
        className='upload-attachments-modal'
        title={<CustomTitleBar />}
        onClose={(event: DialogCloseEvent) => {
          setModalStatus(false);
        }}
      >
        <p>{modalDesc}</p>

        <Upload
          ref={uploadRef}
          batch={false}
          autoUpload={false}
          multiple={true}
          showActionButtons={false}
          defaultFiles={[]}
          files={files}
          onAdd={onAdd}
          onRemove={onRemove}
          restrictions={{
            allowedExtensions: ['.jpg', '.jpeg', '.png', '.pdf'],
            maxFileSize: 10485760, // 10MB
          }}
        />

        <div className='note'>{note}</div>

        <DialogActionsBar>
          <Button
            iconClass='fal fa-upload'
            disabled={hasValidationErrors || files.length === 0 ? true : false}
            themeColor='primary'
            onClick={() => {
              uploadDocument();
            }}
          >
            {uploadBtnLabel}
          </Button>
        </DialogActionsBar>
      </Dialog>
    </>
  );
};

export default UploadAttachmentsModal;
