import React, { useEffect, useRef, useState } from 'react';
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
} from '@progress/kendo-react-charts';
import 'hammerjs';
import PieChartSkeleton from './PieChartSkeleton';
import { useInternationalization } from '@progress/kendo-react-intl';

export type PieChartDataItem = {
  title: string;
  periodEndMarketValue: number;
  color?: string;
};

export interface PieChartProps {
  data: PieChartDataItem[];
  isLoading?: boolean;
}

const PieChart = ({ data, isLoading }: PieChartProps) => {
  const ref = useRef(null);
  const intl = useInternationalization();
  const [componentWidth, setComponentWidth] = useState(0);

  const handleWindowResize = () => {
    setComponentWidth((ref.current as any)?.offsetWidth);
  };

  useEffect(() => {
    // Initially set component width
    handleWindowResize();
    // And then adjust component width on resize
    window.addEventListener('resize', handleWindowResize);
    // Clear listener when component is unloaded
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const isWide = componentWidth > 600;

  const labelContent = (props: any) => {
    const percentage = intl.formatNumber(props.percentage, {
      style: 'percent',
    });
    return `${props.dataItem.title}: ${percentage}`;
  };

  return isLoading ? (
    <div className='portfolio-pie-chart'>
      <PieChartSkeleton />
    </div>
  ) : (
    <div className='portfolio-pie-chart' ref={ref}>
      <Chart>
        <ChartLegend
          visible={!isWide}
          orientation={'vertical'}
          position={'bottom'}
          labels={{
            content: labelContent,
            padding: 3,
          }}
        />
        <ChartSeries>
          <ChartSeriesItem
            type='pie'
            data={data}
            field='periodEndMarketValue'
            categoryField='title'
            labels={{
              align: 'column', // for pie type: circle | column
              visible: isWide,
              content: labelContent,
            }}
          />
        </ChartSeries>
      </Chart>
    </div>
  );
};
export default PieChart;
