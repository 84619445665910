import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';
import { getAxiosConfig } from '../../helpers/utils';
import { IAccount } from '../pages/select-account/SelectAccount';
import authService from './AuthorizeService';

// Gateway API version - getting Representations from Gateway API
export const fetchRepresentations = createAsyncThunk(
  'fetchRepresentations',
  async (dataObject: any, thunkAPI) => {
    const token = await authService.getAccessToken();
    const url = dataObject.url;
    const config = getAxiosConfig(token, 'json');
    try {
      const response = await axios.post(url, null, config);
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export interface SelectedAccountProps {
  name: string;
  type: number;
  contactGuid: string;
  scope?: string[] | null;
}

interface RepresentationsProps {
  representationRights: IAccount[] | null;
  hasRepresentations: boolean | null;
  selectedAccount: SelectedAccountProps | null;
  representationsFetched: boolean | null;
  representationsError: string | null;
}

const selectedAccountStringified = sessionStorage.getItem('selectedAccount');
const selectedAccount =
  !!selectedAccountStringified && JSON.parse(selectedAccountStringified);

const initialState: RepresentationsProps = {
  representationRights: null,
  hasRepresentations: null,
  selectedAccount: !!selectedAccount ? selectedAccount : null,
  representationsFetched: null,
  representationsError: null,
};

export const representationsSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setSelectedRepresentation(state, action) {
      state.selectedAccount = action.payload;
    },
    removeSelectedRepresentation(state) {
      state.selectedAccount = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRepresentations.pending, (state, action) => {
        // console.log('REPRESENTATIONS LOADING');
        state.representationsFetched = false;
      })
      .addCase(fetchRepresentations.fulfilled, (state, action) => {
        // console.log('REPRESENTATIONS FULFILLED');
        state.representationRights = action.payload;
        if (action.payload.length > 1) {
          state.hasRepresentations = true;
        } else {
          state.hasRepresentations = false;
        }
        state.representationsFetched = true;
      })
      .addCase(fetchRepresentations.rejected, (state, action: any) => {
        // console.log('REPRESENTATIONS REJECTED', action);
        state.representationsFetched = true;
        state.representationsError = action?.payload?.message;
      });
  },
});

export const { setSelectedRepresentation, removeSelectedRepresentation } =
  representationsSlice.actions;

export const selectRepresentationRights = (state: RootState) =>
  state.representations.representationRights;
export const selectSelectedAccount = (state: RootState) =>
  state.representations.selectedAccount;
export const selectHasRepresentations = (state: RootState) =>
  state.representations.hasRepresentations;
export const selectRepresentationsError = (state: RootState) =>
  state.representations.representationsError;
export const selectRepresentationsFetched = (state: RootState) =>
  state.representations.representationsFetched;

export default representationsSlice.reducer;
