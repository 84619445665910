import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { getAxiosConfig } from '../../../helpers/utils';
import authService from '../../api-authorization/AuthorizeService';

// Gateway API version - getting public Form Submission from Gateway API
export const fetchPublicSubmission = createAsyncThunk(
  'fetchPublicSubmission',
  async (formSubmissionData: any, thunkAPI) => {
    const token = formSubmissionData.token;
    const tokenDecoded: any = jwtDecode(token);
    const FORM_SUBMISSIONS_URI =
      formSubmissionData.API_BASE_URI + '/kyc/FormSubmissions';
    let guid: string = '';
    let url = `${FORM_SUBMISSIONS_URI}/${guid}`;
    let config = {};
    guid = tokenDecoded.FormSubmissionGuid;
    url = `${FORM_SUBMISSIONS_URI}/${guid}`;
    const response = await axios.get(url, config);
    const data = response.data;
    return data;
  },
);

// Gateway API version - getting SMS Form Submission from Gateway API
export const fetchSMSSubmission = createAsyncThunk(
  'fetchSMSSubmission',
  async (submissionData: any, thunkAPI) => {
    const token = submissionData.token;
    const FORM_SUBMISSIONS_URI = submissionData.url;
    const receivedCode = submissionData.smsCode;
    const url = `${FORM_SUBMISSIONS_URI}/SMSCode?token=${token}&code=${receivedCode}`;
    let config = {};
    try {
      const response = await axios.get(url, config);
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

// Gateway API version - getting Form Submission using BankID from Gateway API
export const fetchBankIdSubmission = createAsyncThunk(
  'fetchBankIdSubmission',
  async (submissionData: any, thunkAPI) => {
    const token = submissionData.token;
    const accessToken = await authService.getAccessToken();
    const url = submissionData.url + token;
    const config = getAxiosConfig(accessToken, 'json');
    const response = await axios.get(url, config);
    const data = response.data;
    return data;
  },
);

// Gateway API version - getting user specific Form Submission using FormSubmissionGuid from Gateway API
export const fetchUserPrivateSubmission = createAsyncThunk(
  'fetchUserPrivateSubmission',
  async (dataObject: any, thunkAPI) => {
    const accessToken = await authService.getAccessToken();
    const url = dataObject.url;
    const config = getAxiosConfig(accessToken, 'json');
    const response = await axios.get(url, config);
    const data = response.data;
    return data;
  },
);

interface SubmissionState {
  data: any;
  submissionFetched: boolean | null;
  submissionError?: string | null;
}

const initialState: SubmissionState = {
  data: [],
  submissionFetched: null,
};

export const submissionSlice = createSlice({
  name: 'submission',
  initialState,
  reducers: {
    setSubmissionFetched: (state, action) => {
      state.submissionFetched = action.payload;
    },
    updateSubmissionData: (state, action) => {
      state.data.CustomerResponse = action.payload;
    },
    resetSubmissionData: state => {
      state.data = [];
    },
    resetSubmissionError: state => {
      state.submissionError = null;
    },
    updateTempResponse: (state, action) => {
      state.data.TempResponse.Response = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPublicSubmission.pending, (state, action) => {
        // console.log('PUBLIC SUBMISSION LOADING');
        state.submissionFetched = false;
      })
      .addCase(fetchPublicSubmission.fulfilled, (state, action) => {
        // console.log('PUBLIC SUBMISSION FULFILLED');
        state.data = action.payload;
        state.submissionFetched = true;
      })
      .addCase(fetchPublicSubmission.rejected, (state, action) => {
        // console.log('PUBLIC SUBMISSION REJECTED', action);
        state.submissionFetched = true;
        state.submissionError = action.error.message;
      })
      .addCase(fetchSMSSubmission.pending, (state, action) => {
        // console.log('SMS SUBMISSION LOADING');
        state.submissionFetched = false;
      })
      .addCase(fetchSMSSubmission.fulfilled, (state, action) => {
        // console.log('SMS SUBMISSION FULFILLED');
        state.data = action.payload;
        state.submissionFetched = true;
      })
      .addCase(fetchSMSSubmission.rejected, (state, action: any) => {
        // console.log('SMS SUBMISSION REJECTED', action);
        state.submissionFetched = true;
        state.submissionError = action?.payload?.response?.data?.Message;
      })
      .addCase(fetchBankIdSubmission.pending, (state, action) => {
        // console.log('BANKID SUBMISSION LOADING');
        state.submissionFetched = false;
      })
      .addCase(fetchBankIdSubmission.fulfilled, (state, action) => {
        // console.log('BANKID SUBMISSION FULFILLED');
        state.data = action.payload;
        state.submissionFetched = true;
      })
      .addCase(fetchBankIdSubmission.rejected, (state, action) => {
        // console.log('BANKID SUBMISSION REJECTED', action);
        state.submissionFetched = true;
        state.submissionError = action.error.message;
      })
      .addCase(fetchUserPrivateSubmission.pending, (state, action) => {
        // console.log('USER SUBMISSION LOADING');
        state.submissionFetched = false;
      })
      .addCase(fetchUserPrivateSubmission.fulfilled, (state, action) => {
        // console.log('USER SUBMISSION FULFILLED');
        state.data = action.payload;
        state.submissionFetched = true;
      })
      .addCase(fetchUserPrivateSubmission.rejected, (state, action) => {
        // console.log('USER SUBMISSION REJECTED', action);
        state.submissionFetched = true;
        state.submissionError = action.error.message;
      });
  },
});

export const {
  setSubmissionFetched,
  updateSubmissionData,
  resetSubmissionData,
  resetSubmissionError,
  updateTempResponse,
} = submissionSlice.actions;

export const selectSubmission = (state: RootState) => state.submission.data;

export const selectSubmissionJSON = (state: RootState) =>
  state.submission.data.FormTemplateJsonValue;

export const selectSubmissionFetched = (state: RootState) =>
  state.submission.submissionFetched;

export const selectSubmissionError = (state: RootState) =>
  state.submission.submissionError;

export default submissionSlice.reducer;
