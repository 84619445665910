import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface UserState {
  username: string | null;
  userSub: string | null;
  contactGuid: string | null;
  message: string | null;
  isLoggedIn: boolean | null;
  isPopulated: boolean | null;
}

const initialState: UserState = {
  username: null,
  userSub: null,
  contactGuid: null,
  message: null,
  isLoggedIn: null,
  isPopulated: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser(state, action) {
      state.username = action.payload.username;
      state.userSub = action.payload.userSub;
      state.contactGuid = action.payload.contactGuid;
      state.isLoggedIn = true;
      state.isPopulated = true;
    },
    removeUser(state) {
      state.username = null;
      state.userSub = null;
      state.contactGuid = null;
      state.isLoggedIn = null;
      state.isPopulated = false;
    },
    showMessage: (state, action) => {
      state.message = action.payload;
    },
    populateUser: state => {
      state.isPopulated = true;
    },
    setIsAuthenticated: (state, action) => {},
  },
});

export const {
  setUser,
  removeUser,
  showMessage,
  populateUser,
  setIsAuthenticated,
} = userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
