import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import HelmetTitle from '../../common/HelmetTitle';
import envVars from '../../../resources/envVars';
import {
  orderBy,
  filterBy,
  CompositeFilterDescriptor,
} from '@progress/kendo-data-query';
import {
  selectDocumentsData,
  populateDocumentsData,
  selectDocumentsFilter,
  setFiltersStatus,
  selectFiltersStatus,
  selectDocumentsSort,
  selectDocFetched,
  selectDocError,
  setBulkDownloadItems,
  resetFilters,
  clearAllDocumentsError,
} from './documentsSlice';
import { Loading, selectLoading } from '../../../app/slices/loadingSlice';
import { resetAlerts, setAlerts } from '../../../app/slices/alertSlice';
import { Modal, selectModal } from '../../../app/slices/modalSlice';
import DocumentModal from './DocumentModal';
import QuickSearch from './QuickSearch';
import Filters from './Filters';
import DocumentList from './DocumentList';
import { Button } from '@progress/kendo-react-buttons';
import PublicationList from './PublicationList';
import Layout from '../../layout/Layout';
import { useTranslation } from '../../../hooks/useTranslation';

interface DocumentsProps {
  isPublicationsOnly?: boolean;
}

const Documents = ({ isPublicationsOnly }: DocumentsProps) => {
  const dispatch = useAppDispatch();
  // Translations
  const ns = 'construo.documents';
  const translations = {
    title: useTranslation(`${ns}.title`),
    publications: useTranslation(`${ns}.publications`),
    filters: useTranslation(`${ns}.filters`),
  };

  const [inputValue, setInputValue] = useState<string | undefined>();

  const [docErrorSet, setDocErrorSet] = useState(false);
  const documents = useAppSelector(selectDocumentsData);

  const docFetched = useAppSelector(selectDocFetched);
  const docError = useAppSelector(selectDocError);

  useEffect(() => {
    if (!!docError && !docErrorSet) {
      setDocErrorSet(true);
      dispatch(setAlerts({ message: docError, type: 'error' }));
      dispatch(clearAllDocumentsError()); // Clear documents -> error prop immediately when doc alert is added - setAlerts for docs
    }
  }, [dispatch, docError, docErrorSet]);

  useEffect(() => {
    return () => {
      dispatch(resetFilters()); // Clear filters
      dispatch(setFiltersStatus(false)); // Hide filters
      dispatch(clearAllDocumentsError());
      dispatch(resetAlerts());
      dispatch(setBulkDownloadItems([]));
    };
  }, [dispatch]);

  const filtersStatus = useAppSelector(selectFiltersStatus);
  const [filteredDocuments, setFilteredDocuments] = useState(documents);
  const selectedFilter = useAppSelector(
    selectDocumentsFilter,
  ) as CompositeFilterDescriptor;
  const filter = selectedFilter;
  const sort = useAppSelector(selectDocumentsSort);

  const API_BASE_URI = envVars.API_BASE_URI;

  useEffect(() => {
    /**
     * The tricky behavior of useEffect hook in React 18
     * https://medium.com/geekculture/the-tricky-behavior-of-useeffect-hook-in-react-18-282ef4fb570a
     * let ignore
     */
    let ignore = false;
    const dataObject = {
      baseUrl: API_BASE_URI,
    };
    if (!!docFetched) {
      setFilteredDocuments(documents);
    } else if (docFetched === null) {
      setTimeout(() => {
        if (!ignore) {
          dispatch(populateDocumentsData(dataObject));
        }
      }, 100);
    }
    return () => {
      ignore = true;
    };
  }, [dispatch, docFetched, documents, API_BASE_URI]);

  useEffect(() => {
    const filtered = filterBy(documents, filter);
    setFilteredDocuments(orderBy(filtered, sort));
  }, [documents, filter, sort]);

  const documentsLength: number = !!documents ? documents.length : 0;

  const modalShow = useAppSelector(selectModal);

  const docLoading = useAppSelector(selectLoading) === Loading.Show;

  return (
    <>
      <HelmetTitle
        title={
          !isPublicationsOnly ? translations.title : translations.publications
        }
      />

      {filteredDocuments.length > 0 &&
        modalShow === Modal.Show &&
        !docLoading && <DocumentModal />}

      <Layout>
        <div className='main-section documents'>
          <div className='container'>
            <header>
              <h1>
                {!isPublicationsOnly
                  ? translations.title
                  : translations.publications}
              </h1>

              <Button
                onClick={() => dispatch(setFiltersStatus(!filtersStatus))}
                className='filters-btn'
                iconClass='order-2 ml-1 fal fa-filter'
              >
                {translations.filters}
              </Button>
            </header>

            <Filters
              documents={filteredDocuments}
              isPublicationsOnly={isPublicationsOnly}
              quickSearchKeyword={inputValue}
            />

            <QuickSearch
              inputValue={inputValue}
              setInputValue={setInputValue}
            />

            {!isPublicationsOnly ? (
              <DocumentList
                documents={filteredDocuments}
                documentsLength={documentsLength}
                filter={filter}
                sort={sort}
                docFetched={docFetched}
              />
            ) : (
              <PublicationList
                documents={filteredDocuments}
                filter={filter}
                sort={sort}
                docFetched={docFetched}
              />
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Documents;
